import { LockClosedIcon } from "@heroicons/react/outline";
import { useEthers } from "@usedapp/core";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { createSiweMessage } from "./logic/createSIWEMessage";
import { postJsonToBackend } from "../../utils/postJsonToBackend";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import { setActiveAccount } from "../../state/slices/account";
import { useSearchParams } from "react-router-dom";
import Spinner from "../../components/Spinner";
import Bugsnag from "@bugsnag/js";

interface LoginPageProps {
  id?: string | undefined;
}

const LoginPage: React.FC<LoginPageProps> = (props) => {
  const { id } = props;
  const isMobile = window.innerWidth < 992;
  const { account, library, activate } = useEthers();
  const [isSignInButtonClicked, setIsSignInButtonClicked] = useState(false);
  const [activateError, setActivateError] = useState("");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isSignupIntent, setIsSignupIntent] = useState(false);
  const [isWaitingOnMessageSignature, setIsWaitingOnMessageSignature] =
    useState(false);

  useEffect(() => {
    if (localStorage.getItem("activeAccount")) {
      navigate("/app");
    }
  });

  useEffect(() => {
    const intent = searchParams.get("intent");
    setIsSignupIntent(intent === "signup");
  }, [searchParams]);

  useEffect(() => {
    async function signInWithEthereum() {
      const message = await createSiweMessage(
        (await library?.getSigner().getAddress()) || "",
        "Sign in with Ethereum to AddressForm."
      );
      const signature = await library?.getSigner().signMessage(message);

      const promise = postJsonToBackend(
        { message, signature },
        "auth/api/v1/verify"
      );
      promise.then(async (response) => {
        if (!response.ok) {
          Bugsnag.notify(
            JSON.stringify({
              status: response.status,
              statusText: response.statusText,
              body: response.body,
            })
          );
        }
        if (id !== undefined) {
          await fetch(
            `${process.env.REACT_APP_API_URL}/forms/api/v1/claim-form?form_id_encid=${id}`,
            {
              credentials: "include",
            }
          )
            .then((response) => {
              if (!response.ok) {
                Bugsnag.notify(
                  JSON.stringify({
                    status: response.status,
                    statusText: response.statusText,
                    body: response.body,
                  })
                );
              }

              return response.json();
            })
            .then((data) => {
              // not sure what do to in case of failure again
              if (!data.success) {
                throw Error("session not valid");
              } else {
                //navigate to a page to say form claimed, with a button that says go to account page?
                navigate("/form/claimed");
              }
            })
            .catch((err) => {
              console.log(err);
              Bugsnag.notify(err);
            });
        } else {
          navigate("/app?intent=signup");
        }
      });
    }

    if (isSignInButtonClicked && account !== undefined && !isMobile) {
      setIsWaitingOnMessageSignature(true);
      signInWithEthereum();
    }
  }, [isSignInButtonClicked, library, navigate, account, id, isMobile]);

  const activateProvider = async () => {
    const providerOptions = {
      injected: {
        display: {
          name: "Metamask",
          description: "Connect with the provider in your Browser",
        },
        package: null,
      },
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          bridge: "https://bridge.walletconnect.org",
          infuraId: "f74ad8aacd6a44a7a52f4db811276bcd",
        },
      },
    };

    const web3Modal = new Web3Modal({
      providerOptions,
    });
    try {
      const provider = await web3Modal.connect();
      await activate(provider);
      setActivateError("");
      setActiveAccount(account);
    } catch (error: any) {
      setActivateError(error.message);
    }
  };

  async function signInWithEthereum() {
    const message = await createSiweMessage(
      (await library?.getSigner().getAddress()) || "",
      "Sign in with Ethereum to AddressForm."
    );
    const signature = await library?.getSigner().signMessage(message);

    const promise = postJsonToBackend(
      { message, signature },
      "auth/api/v1/verify"
    );
    promise.then(async (response) => {
      if (!response.ok) {
        Bugsnag.notify(
          JSON.stringify({
            status: response.status,
            statusText: response.statusText,
            body: response.body,
          })
        );
      }
      if (id !== undefined) {
        await fetch(
          `${process.env.REACT_APP_API_URL}/forms/api/v1/claim-form?form_id_encid=${id}`,
          {
            credentials: "include",
          }
        )
          .then((response) => {
            if (!response.ok) {
              Bugsnag.notify(
                JSON.stringify({
                  status: response.status,
                  statusText: response.statusText,
                  body: response.body,
                })
              );
            }

            return response.json();
          })
          .then((data) => {
            // not sure what do to in case of failure again
            if (!data.success) {
              throw Error("session not valid");
            } else {
              //navigate to a page to say form claimed, with a button that says go to account page?
              navigate("/form/claimed");
            }
          })
          .catch((err) => {
            console.log(err);
            Bugsnag.notify(err);
          });
      } else {
        navigate("/app?intent=signup");
      }
    });
  }

  const formContentSignIn = (
    <div className="flex flex-col justify-center">
      <div className="border-b-2 border-gray-100 pb-3 mb-6">
        <Spinner />
        <div className="mt-2 mx-auto w-fit text-md opacity-50">
          Waiting for wallet to sign message
        </div>
      </div>

      <p className="mt-1 mb-3 opacity-50 font-medium text-center">
        Sign a message to sign {isSignupIntent ? "up" : "in"} with your Ethereum
        wallet
      </p>
      <button
        className="transition ease-in-out delay-125 max-w-sm mx-auto group relative w-full flex justify-center py-3 px-3 border border-transparent text-md font-bold rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        onClick={signInWithEthereum}
      >
        Sign Message
      </button>
    </div>
  );

  return (
    <>
      <div className="h-screen flex flex-col py-12 px-4 sm:px-6 lg:px-8 bg-slate-100">
        <div className="max-w-md w-full p-6 rounded-lg shadow-lg bg-white mx-auto lg:mb-auto">
          <div>
            <div className="mx-auto text-6xl text-center">📝</div>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              AddressForm
            </h2>
            {activateError && activateError.length > 0 && (
              <p className="text-sm text-red-500 mx-auto">{activateError}</p>
            )}
          </div>
          <div className="mt-8 space-y-6">
            <div>
              {!isWaitingOnMessageSignature && (
                <>
                  {!isSignInButtonClicked && (
                    <button
                      onClick={() => {
                        if (account === undefined) {
                          activateProvider();
                        }
                        setIsSignInButtonClicked(true);
                      }}
                      className="transition ease-in-out delay-125 max-w-sm mx-auto group relative w-full flex justify-center py-3 px-3 border border-transparent text-md font-bold rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                        <LockClosedIcon
                          className="h-6 w-5 text-white"
                          aria-hidden="true"
                        />
                      </span>
                      {isMobile &&
                        account === undefined &&
                        "Connect wallet to sign in"}
                      {!isMobile &&
                        `Sign ${isSignupIntent ? "up" : "in"} with ETH wallet`}
                    </button>
                  )}
                  {isMobile &&
                    isSignInButtonClicked &&
                    account !== undefined &&
                    formContentSignIn}
                </>
              )}

              <div className="flex opacity-50 text-sm px-0 sm:px-3 mt-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="hidden sm:flex h-6 w-6 mr-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                We use cookies as part of our sign in process. By signing in,
                you agree to our cookie policy.
              </div>

              {isWaitingOnMessageSignature && (
                <>
                  <Spinner />
                  <div className="mx-auto w-fit text-md text-gray-500">
                    Waiting for wallet to sign message
                  </div>
                </>
              )}
            </div>
          </div>

          {isSignupIntent && (
            <div className="mt-8 space-y-6 border-t border-gray-300 mb-6">
              <p className="mt-6 text-center text-md font-semibold text-black opacity-50">
                Sign a message (gas free) to get access to our generous free
                tier (30 responses / month).
              </p>
            </div>
          )}
        </div>

        {/* Footer */}
        <div className="mt-9 lg:mt-0">
          <p className="text-base text-gray-400 xl:text-center mb-1">
            Made with <span className="mr-1">🤍</span> in the Metaverse.
          </p>
          <p className="text-base text-gray-400 xl:text-center">
            &copy; 2022 e⁴ Labs, LLC. All rights reserved.
          </p>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
