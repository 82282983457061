import { Popover } from "@headlessui/react";
import React from "react";
import { useNavigate } from "react-router";
import Footer from "../LandingPage/components/Footer";

const NotFoundPage = () => {
  const nav = (
    <div className="relative bg-white overflow-hidden">
      <div className="relative pt-6 pb-16 sm:pb-24">
        <Popover>
          <div className="max-w-7xl mx-auto px-4 sm:px-6">
            <nav
              className="relative flex items-center justify-between sm:h-10 md:justify-center"
              aria-label="Global"
            >
              <div className="flex lg:items-center flex-1 md:absolute md:inset-y-0 md:left-0">
                <div className="flex items-center justify-start sm:justify-between w-fit sm:w-full ">
                  <div className="mx-auto text-3xl text-center">📝</div>
                  <h2
                    className="ml-1 mt-2 text-center text-2xl font-bold text-gray-900 hover:cursor-pointer"
                    onClick={() => navigate("/")}
                  >
                    AddressForm
                  </h2>
                  {/* <div className="-mr-2 flex items-center md:hidden">
                        <Popover.Button className="bg-gray-50 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                          <span className="sr-only">Open main menu</span>
                          <MenuIcon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                      </div> */}
                </div>
              </div>
              {localStorage.getItem("activeAccount") === undefined && (
                <div className="hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0">
                  <span className="inline-flex rounded-md shadow">
                    <div
                      onClick={() => navigate("/login")}
                      className="inline-flex items-center px-4 py-2 border border-transparent text-base font-semibold rounded-md  bg-indigo-500 text-white bg-white hover:bg-indigo-600 hover:cursor-pointer"
                    >
                      Log in
                    </div>
                  </span>
                </div>
              )}
            </nav>
          </div>
          {/* 
              <Transition
                as={Fragment}
                enter="duration-150 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Popover.Panel
                  focus
                  className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
                >
                  <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div className="px-5 pt-4 flex items-center justify-between">
                      <div>
                        <div className="mx-auto text-3xl text-center">📝</div>
                        <h2 className="ml-1 mt-2 text-center text-2xl font-bold text-gray-900">
                          AddressForm
                        </h2>
                      </div>
                      <div className="-mr-2">
                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                          <span className="sr-only">Close menu</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                      </div>
                    </div>
                    <div
                      onClick={() => navigate("/login")}
                      className="block w-full px-5 py-3 text-center font-medium text-indigo-400 bg-gray-50 hover:bg-gray-100"
                    >
                      Log in
                    </div>
                  </div>
                </Popover.Panel>
              </Transition> */}
        </Popover>
      </div>
    </div>
  );

  const navigate = useNavigate();
  return (
    <div className="h-screen flex flex-col justify-between">
      {nav}
      <div className="w-fit mx-auto text-center">
        <h1 className="text-4xl font-bold opacity-50">Whoops!</h1>
        <h2 className="text-2xl font-bold">
          404: The page you're looking for doesn't exist.
        </h2>
        <h3
          className="underline text-lg text-indigo-700 cursor-pointer"
          onClick={() => navigate("/")}
        >
          Go home
        </h3>
      </div>
      <Footer />
    </div>
  );
};

export default NotFoundPage;
