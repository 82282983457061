import { Switch, Transition } from "@headlessui/react";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import produce from "immer";
import Bugsnag from "@bugsnag/js";
import Spinner from "../../components/Spinner";
import { useNavigate } from "react-router";
import { postJsonToBackend } from "../../utils/postJsonToBackend";
import FormCheckboxSelector, { Form } from "../FormCheckboxSelector";
import { isValidURL } from "../../utils/isValidUrl";
import { arrayEquals } from "../../utils/arrayEquals";

interface ApiKeyPermissionEditorProps {
  isEdit?: boolean;
  apiKeyToEdit?: string;
}

const ApiKeyPermissionEditor: React.FC<ApiKeyPermissionEditorProps> = (
  props
) => {
  const { isEdit, apiKeyToEdit } = props;

  const [isLoadingCurrentPermState, setIsLoadingCurrentPermState] = useState(
    isEdit || false
  );
  const [defaultAllowedHostsStr, setDefaultAllowedHostsStr] = useState("");
  const [defaultCheckedFormEncIds, setDefaultCheckedFormEncIds] = useState<
    Array<string>
  >([]);

  useEffect(() => {
    if (!isEdit) {
      return;
    }

    const fetchData = async () => {
      await fetch(
        `${process.env.REACT_APP_API_URL}/api-key/api/v1/get-permissions-by-api-key?api_key=${apiKeyToEdit}`,
        {
          credentials: "include",
        }
      )
        .then((response) => {
          if (!response.ok) {
            Bugsnag.notify(
              JSON.stringify({
                status: response.status,
                statusText: response.statusText,
                body: response.body,
              })
            );
          }

          return response.json();
        })
        .then((data) => {
          const parsedData = JSON.parse(data[0].permissions);
          if (parsedData.allowedHosts !== "ALL") {
            setAllowedHosts(parsedData.allowedHosts);
            setDefaultAllowedHostsStr(parsedData.allowedHosts.join(", "));
          }

          if (parsedData.forms === "ALL") {
            setGiveKeyAllPermissions(true);
          } else {
            setDefaultCheckedFormEncIds(parsedData.forms);
            setPermissionedForms(parsedData.forms);
          }

          setIsLoadingCurrentPermState(false);
        })
        .catch((err) => {
          Bugsnag.notify(err);
          console.log(err);
        });
    };

    if (isLoadingCurrentPermState) {
      console.log("fetching");
      fetchData();
    }
  }, [apiKeyToEdit, isEdit, isLoadingCurrentPermState]);

  const [userForms, setUserForms] = useState<Array<Form>>([]);
  const [account, setAccount] = useState("");
  const [allowedHostsRaw, setAllowedHostsRaw] = useState("");
  const [allowedHosts, setAllowedHosts] = useState<Array<string>>([]);
  const [allowedHostsInvalid, setAllowedHostsInvalid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFailure, setIsFailure] = useState(false);
  const [giveKeyAllPermissions, setGiveKeyAllPermissions] = useState(false);
  const [permissionedForms, setPermissionedForms] = useState<Array<string>>([]);
  const [failureMessage, setFailureMessage] = useState("");
  const [userFormsHaveLoaded, setUserFormHaveLoaded] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (allowedHostsRaw.length === 0) {
      setAllowedHostsInvalid(false);
      if (allowedHosts.length) {
        setAllowedHosts([]);
      }
      return;
    }

    const commaSplitText = allowedHostsRaw
      .split(",")
      .map((s: string) => s.trim());
    const isValid = commaSplitText
      .map((maybeUrl: string) => {
        return isValidURL(maybeUrl);
      })
      .reduce((a: boolean, b: boolean) => a && b);

    if (!isValid !== allowedHostsInvalid) {
      setAllowedHostsInvalid(!isValid);
    }

    if (!allowedHostsInvalid && !arrayEquals(allowedHosts, commaSplitText)) {
      setAllowedHosts(commaSplitText);
    }
  }, [allowedHosts, allowedHostsInvalid, allowedHostsRaw]);

  useEffect(() => {
    if (account === "") {
      setAccount(localStorage.getItem("activeAccount") || "");
    }
  }, [account]);

  useEffect(() => {
    async function postData() {
      const filteredForms = permissionedForms;
      postJsonToBackend(
        {
          permissions_json: JSON.stringify({
            allowedHosts:
              allowedHosts.length === 0 || [""] === allowedHosts
                ? "ALL"
                : allowedHosts,
            forms: giveKeyAllPermissions ? "ALL" : filteredForms,
            permissions: giveKeyAllPermissions
              ? [{ resource: "FORM_RESPONSE", action: "READ" }]
              : filteredForms.map((formName: string) => {
                  return {
                    resource: "FORM_RESPONSE",
                    action: "READ",
                  };
                }),
          }),
        },
        isEdit
          ? `api-key/api/v1/edit-api-key?api_key=${apiKeyToEdit}`
          : `api-key/api/v1/generate-api-key`
      )
        .then(
          (response: {
            ok: any;
            status: any;
            statusText: any;
            body: any;
            text: () => any;
          }) => {
            if (!response.ok) {
              Bugsnag.notify(
                JSON.stringify({
                  status: response.status,
                  statusText: response.statusText,
                  body: response.body,
                })
              );
              throw new Error("Error");
            }

            return response.text();
          }
        )
        .then(() => {
          setIsSubmitting(true);
          setTimeout(() => {
            navigate(`/app/api-keys`);
          }, 2000);
        })
        .catch(() => {
          setIsFailure(true);
          setFailureMessage("Error. Please try again.");
        });
    }

    if (isSubmitting) {
      postData();
    }
  }, [
    allowedHosts,
    apiKeyToEdit,
    giveKeyAllPermissions,
    isEdit,
    isSubmitting,
    navigate,
    permissionedForms,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      await fetch(
        `${process.env.REACT_APP_API_URL}/forms/api/v1/get-forms-by-creator?creator_address=${account}`,
        {
          credentials: "include",
        }
      )
        .then((response) => {
          if (!response.ok) {
            Bugsnag.notify(
              JSON.stringify({
                status: response.status,
                statusText: response.statusText,
                body: response.body,
              })
            );
          }

          return response.json();
        })
        .then((data) => {
          setUserForms(
            data.map((value: any) => {
              const formData = JSON.parse(value.form_json);
              return {
                name: formData.title,
                formIdEncId: value.form_id_encid,
              };
            })
          );
          setUserFormHaveLoaded(true);
        })
        .catch((err) => {
          Bugsnag.notify(err);
          console.log(err);
        });
    };

    if (account.length > 0) {
      fetchData();
    }
  }, [account]);

  if (!userFormsHaveLoaded || (isEdit && isLoadingCurrentPermState)) {
    return <Spinner />;
  }

  return (
    <div>
      <Transition
        appear={false}
        show={isFailure}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="border-2 border-red-500 bg-red-100 text-medium p-3 rounded-lg font-medium text-md my-3">
          {failureMessage}
        </div>
      </Transition>

      <h1 className="text-xl font-medium text-gray-900 mt-3 mb-3 border-b-2 pb-2 border-gray-200 w-full lg:w-1/2">
        <span className="font-bold mr-1">Step 1: </span> Set key permissions
      </h1>

      <div className="w-full xl:w-1/2 flex justify-between">
        Grant read permission for all forms
        <div>
          <Switch
            checked={giveKeyAllPermissions}
            onChange={() => setGiveKeyAllPermissions(!giveKeyAllPermissions)}
            className={clsx(
              giveKeyAllPermissions ? "bg-indigo-600" : "bg-gray-200",
              "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            )}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={clsx(
                giveKeyAllPermissions ? "translate-x-5" : "translate-x-0",
                "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
              )}
            />
          </Switch>
        </div>
      </div>

      <div
        className={clsx(
          "mt-4 w-full xl:w-1/2 transition ease-in-out 125",
          giveKeyAllPermissions ? "opacity-50" : ""
        )}
      >
        Grant read permission for specific form(s)
        <FormCheckboxSelector
          forms={userForms}
          defaultCheckedFormEncIds={defaultCheckedFormEncIds}
          onChange={(state: string, formIdEncId: string) => {
            setPermissionedForms(
              produce(permissionedForms, (draftPermissionedForms) => {
                if (
                  state === "on" &&
                  !draftPermissionedForms.includes(formIdEncId)
                ) {
                  draftPermissionedForms.push(formIdEncId);
                } else {
                  draftPermissionedForms.splice(
                    permissionedForms.indexOf(formIdEncId),
                    1
                  );
                }
              })
            );
          }}
        />
      </div>

      <h1 className="text-xl font-medium text-gray-900 mt-3 mb-3 border-b-2 pb-2 border-gray-200 w-full xl:w-1/2">
        <span className="font-bold mr-1">Step 2: </span> Set allowed origins
        (Optional)
      </h1>

      <p className="font-normal text-gray-400">
        Provide comma seperated list of origins allowed to use this key (e.g.
        https://nouns.wtf, https://google.com, etc.)
      </p>
      <div className="mb-2 mt-1 w-full xl:w-1/2">
        <textarea
          rows={4}
          className={clsx(
            "shadow-sm block w-full sm:text-sm border-gray-300 rounded-md",
            allowedHostsInvalid
              ? "border-2 border-red-500 focus:ring-red-500 focus:border-red-500"
              : "focus:ring-indigo-500 focus:border-indigo-500"
          )}
          defaultValue={defaultAllowedHostsStr}
          onChange={(e: any) => setAllowedHostsRaw(e.target.value)}
        />
        {allowedHostsInvalid && (
          <p className="mt-2 text-sm font-medium text-red-500">
            <span className="font-bold">Invalid input: </span>please provide a
            comma seperated list of approved URLs
          </p>
        )}
      </div>
      <p>
        <span className="font-semibold">Note: </span>If left blank, all websites
        with the API key will be able to read your form data.
      </p>

      <button
        onClick={() => {
          if (giveKeyAllPermissions || permissionedForms.length > 0) {
            setIsSubmitting(true);
          } else {
            setIsFailure(true);
            setFailureMessage(
              "You must either approve this key for all forms or select which forms it can read."
            );
          }
        }}
        disabled={allowedHostsInvalid}
        className="disabled:opacity-75 disabled:hover:bg-indigo-500 disabled:hover:scale-100  disabled:cursor-not-allowed	 px-4 py-2 rounded-lg bg-indigo-500 text-white font-bold max-w-md  mt-3 flex justify-center cursor-pointer hover:bg-indigo-600 ease-in-out duration-125"
      >
        {isSubmitting && (
          <svg
            role="status"
            className="inline mr-2 w-5 h-5 mt-0.5 text-gray-200 animate-spin fill-gray-400 transition ease-in-out 125"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
        )}{" "}
        {isEdit ? "Update" : "Generate New"} API Key
      </button>
    </div>
  );
};

export default ApiKeyPermissionEditor;
