import Bugsnag from "@bugsnag/js";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import AppWrapper from "../../components/AppWrapper";
import DeleteFormModal from "../../components/DeleteFormModal";
import MinimalDropdown from "../../components/MinimalDropdown";
import Spinner from "../../components/Spinner";
import dayjs from "dayjs";

/**
 * Page served at /app
 *
 *
 * @returns Grid of cards with all forms viewable by current user
 */
const AppHomePage = () => {
  const [data, setData] = useState<Array<any>>([]);
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [idToDelete, setIdToDelete] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [account, setAccount] = useState("");
  const [isDeleteDropDownClicked, setIsDeleteDropDownClicked] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (account === "") {
      setAccount(localStorage.getItem("activeAccount") || "");
    }
  }, [account]);

  useEffect(() => {
    const fetchData = async () => {
      await fetch(
        `${process.env.REACT_APP_API_URL}/forms/api/v1/get-forms-by-creator?creator_address=${account}`,
        {
          credentials: "include",
        }
      )
        .then((response) => {
          if (!response.ok) {
            Bugsnag.notify(
              JSON.stringify({
                status: response.status,
                statusText: response.statusText,
                body: response.body,
              })
            );
          }

          return response.json();
        })
        .then((data) => {
          setData(data);
          setIsLoading(false);
        })
        .catch((err) => {
          Bugsnag.notify(err);
          console.log(err);
        });
    };

    if (account.length > 0) {
      fetchData();
    }
  }, [account]);

  useEffect(() => {
    if (!deleteConfirmed || idToDelete.length === 0) {
      return;
    }
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/forms/api/v1/${idToDelete}`, {
      credentials: "include",
      method: "DELETE",
    })
      .then((response) => {
        if (!response.ok) {
          Bugsnag.notify(
            JSON.stringify({
              status: response.status,
              statusText: response.statusText,
              body: response.body,
            })
          );
        }
        setDeleteConfirmed(false);
        // Remove from frontend state
        setData(data.filter((form: any) => form.form_id_encid !== idToDelete));

        setIsLoading(false);
        setIdToDelete("");
      })
      .catch((err) => {
        console.log(err);
        Bugsnag.notify(err);
      });
  }, [deleteConfirmed, idToDelete, data]);

  if (isLoading) {
    return (
      <AppWrapper>
        <Spinner />
      </AppWrapper>
    );
  }

  console.log(isDeleteDropDownClicked);
  return (
    <>
      <DeleteFormModal
        open={showDeleteModal}
        onClickHandler={() => setDeleteConfirmed(true)}
        onHide={() => setShowDeleteModal(false)}
      />
      <AppWrapper>
        {data && data.length > 0 ? (
          <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 grid-flow-cols gap-4 pb-56 lg:pb-16">
            {data
              .sort(
                (form1: any, form2: any) =>
                  -1 * (form1.created_at_timestamp - form2.created_at_timestamp)
              )
              .map((form: any, index: number) => {
                const formData = JSON.parse(form.form_json);
                console.log("FORM INFO: ", form);
                return (
                  <div
                    className="max-w-sm mx-auto rounded-lg shadow-lg hover:cursor-pointer hover:shadow-2xl ease-in-out duration-125 bg-gradient-to-r from-indigo-400 to-indigo-500"
                    style={{
                      width: "500px",
                    }}
                    onClick={() =>
                      !isDeleteDropDownClicked &&
                      navigate(`/app/${false ? 'board' : 'form'}?id=${form.form_id_encid}`)
                    }
                    key={index}
                  >
                    <div className="h-56 w-full max-w-full text-left font-bold text-2xl text-white p-5 break-words">
                      {formData.title}
                      <div className="mt-3 border-t border-slate-50 w-full py-3 text-3xl text-left break-works">
                        {form.responseCount}{" "}
                        {form.responseCount === "1" ? (
                          <>Response</>
                        ) : (
                          <>Responses</>
                        )}
                      </div>
                    </div>

                    <div className="p-5 rounded-b-lg bg-white">
                      <div className="w-full flex justify-between">
                        <div>
                          <span className="text-sm text-gray-700">
                            Created on:
                          </span>
                          <div className="block text-2xl font-bold text-gray-800">
                            {dayjs
                              // Use this time so that if "seems" ~ correct for when CDT made his forms. This is basically a UI headfake for this edge case. Forms created after this date (more or less)
                              // all should have date timestamp
                              .unix(form.created_at_timestamp || 1650972738)
                              .format("MMMM D, YYYY")}
                          </div>
                        </div>
                        <div
                          className="mt-4"
                          onClick={() => {
                            setIsDeleteDropDownClicked(true);
                          }}
                        >
                          <MinimalDropdown
                            isBoard={false}
                            deleteClickHandler={() => {
                              setIdToDelete(form.form_id_encid);
                              setShowDeleteModal(true);
                            }}
                            onMouseOver={() => {
                              console.log("on mouse over");
                              setIsDeleteDropDownClicked(true);
                            }}
                            onMouseLeave={() => {
                              console.log("on mouse leave");
                              setIsDeleteDropDownClicked(false);
                            }}
                            formIdEncId={form.form_id_encid}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        ) : (
          <h1 className="font-bold">
            You have no forms. Forms you create will appear here.
          </h1>
        )}
      </AppWrapper>
    </>
  );
};

export default AppHomePage;
