import { useEthers } from "@usedapp/core";
import Davatar from "@davatar/react";
import classes from "./ShortAddress.module.css";
import { useReverseENSLookUp } from "../../utils/ensLookup";

export const useShortAddress = (address: string) => {
  return address && [address.substr(0, 4), address.substr(38, 4)].join("...");
};

const ShortAddress: React.FC<{
  address: string;
  avatar?: boolean;
  size?: number;
}> = (props) => {
  const { address, avatar, size = 24 } = props;
  const { library: provider } = useEthers();

  const ens = useReverseENSLookUp(address);
  const shortAddress = useShortAddress(address);

  if (avatar) {
    return (
      <div className={classes.shortAddress}>
        {avatar && (
          <div key={address}>
            <Davatar size={size} address={address} provider={provider} />
          </div>
        )}
        <a
          className="hover:underline"
          target="_blank"
          rel="noopener noreferrer"
          href={`https://etherscan.io/address/${ens ? ens : address}`}
        >
          <span>{ens ? ens : shortAddress}</span>
        </a>
      </div>
    );
  }

  return (
    <>
      <a
        className="hover:underline"
        target="_blank"
        rel="noopener noreferrer"
        href={`https://etherscan.io/address/${ens ? ens : address}`}
      >
        <span>{ens ? ens : shortAddress}</span>
      </a>
    </>
  );
};

export default ShortAddress;
