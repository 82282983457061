import React from "react";
import { useNavigate } from "react-router-dom";
import Spinner from "../Spinner";

interface Props {
  onDisplayFormEncId: string;
  formTitle: string;
  formCustomUrl: string;
  createdAtTimestamp?: number;
  type?: string;
}
const LEGACY_FORM_CUTOFF_TIMESTAMP = 1659296485;

const FormDetailsNavBar: React.FC<Props> = (props) => {
  const { onDisplayFormEncId, formTitle, formCustomUrl, createdAtTimestamp = 0, type } =
    props;

  let url: string;

  if (createdAtTimestamp >= LEGACY_FORM_CUTOFF_TIMESTAMP) {
    if (formCustomUrl) {
      url = "/f/" + encodeURIComponent(formCustomUrl);
    } else {
      url = "/f/" + onDisplayFormEncId;
    }
  } else {
    if (formCustomUrl) {
      url = "/f/" + encodeURIComponent(formCustomUrl);
    } else {
      url = "/form/" + onDisplayFormEncId;
    }
  }

  const navigate = useNavigate();

  if (onDisplayFormEncId === "") {
    return <Spinner />;
  }

  return (
    <div className="flex mt-3 bg-white px-4 py-2 rounded-lg w-fit shadow-md overflow-x-scroll lg:overflow-x-hidden max-w-screen h-fit">
      <button
        onClick={() => navigate(`/app/${type === 'board' ? 'board' : 'form'}/edit?id=${onDisplayFormEncId}`)}
        className="flex items-center h-10 px-2 py-2 -mb-px text-center text-gray-700 bg-transparent border-r-2 sm:px-4 -px-1  whitespace-nowrap cursor-base focus:outline-none hover:bg-slate-100 ease-in-out duration-125 "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-4 h-4 mx-1 sm:w-6 sm:h-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
          />
        </svg>
        <span className="mx-1 text-sm sm:text-base">Edit {type === 'board' ? 'board' : 'form'}</span>
      </button>

      {/* There is no use for this button until we launch email notifs */}

      {/* <button
            onClick={() =>
              navigate(
                `/app/form/settings?id=${onDisplayFormEncId}&title=${formTitle}`
              )
            }
            className="flex items-center h-10 px-2 py-2 -mb-px text-center text-gray-700 bg-transparent border-r-2 sm:px-4 -px-1  whitespace-nowrap cursor-base focus:outline-none hover:bg-slate-100 ease-in-out duration-125 "
          >
            <svg
              className="w-4 h-4 mx-1 sm:w-6 sm:h-6"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.3246 4.31731C10.751 2.5609 13.249 2.5609 13.6754 4.31731C13.9508 5.45193 15.2507 5.99038 16.2478 5.38285C17.7913 4.44239 19.5576 6.2087 18.6172 7.75218C18.0096 8.74925 18.5481 10.0492 19.6827 10.3246C21.4391 10.751 21.4391 13.249 19.6827 13.6754C18.5481 13.9508 18.0096 15.2507 18.6172 16.2478C19.5576 17.7913 17.7913 19.5576 16.2478 18.6172C15.2507 18.0096 13.9508 18.5481 13.6754 19.6827C13.249 21.4391 10.751 21.4391 10.3246 19.6827C10.0492 18.5481 8.74926 18.0096 7.75219 18.6172C6.2087 19.5576 4.44239 17.7913 5.38285 16.2478C5.99038 15.2507 5.45193 13.9508 4.31731 13.6754C2.5609 13.249 2.5609 10.751 4.31731 10.3246C5.45193 10.0492 5.99037 8.74926 5.38285 7.75218C4.44239 6.2087 6.2087 4.44239 7.75219 5.38285C8.74926 5.99037 10.0492 5.45193 10.3246 4.31731Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <span className="mx-1 text-sm sm:text-base">Settings</span>
          </button> */}

      <button
        onClick={() =>
          window.open(`https://addressform.io${url}`, "_blank")?.focus()
        }
        className="flex items-center h-10 px-2 py-2 -mb-px text-center text-gray-700 bg-transparent border-0 border-r-2 sm:px-4 -px-1  whitespace-nowrap cursor-base focus:outline-none hover:bg-slate-100 ease-in-out duration-125 "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-4 h-4 mx-1 sm:w-6 sm:h-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
          />
        </svg>{" "}
        <span className="mx-1 text-sm sm:text-base">{type === 'board' ? 'Board' : 'Form'} link</span>
      </button>

      {/* <button className="hidden xl:flex items-center h-10 px-2 py-2 -mb-px text-center text-gray-700 bg-transparent border-r-2 sm:px-4 -px-1  whitespace-nowrap cursor-base focus:outline-none hover:bg-slate-100 ease-in-out duration-125 ">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-4 h-4 mx-1 sm:w-6 sm:h-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3 15a4 4 0 004 4h9a5 5 0 10-.1-9.999 5.002 5.002 0 10-9.78 2.096A4.001 4.001 0 003 15z"
          />
        </svg>

        <span className="mx-1 text-sm sm:text-base">
          Integrations
          <span className="ml-1 text-xs">coming soon</span>
        </span>
      </button> */}

{type !== 'board' && (<button
        onClick={() =>
          navigate(
            `/app/form/analytics?id=${onDisplayFormEncId}&title=${formTitle}`
          )
        }
        className="flex items-center h-10 px-2 py-2 -mb-px text-center text-gray-700 bg-transparent  sm:px-4 -px-1  whitespace-nowrap cursor-base focus:outline-none hover:bg-slate-100 ease-in-out duration-125"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-4 h-4 mx-1 sm:w-6 sm:h-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"
          />
        </svg>

        <span className="mx-1 text-sm sm:text-base">Analytics</span>
      </button>)}
    </div>
  );
};

export default FormDetailsNavBar;
