import Bugsnag from "@bugsnag/js";
import React, { ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks";
import Spinner from "../Spinner";
import TailwindResponiveSidebar from "../TailwindResponsiveSideBar";

interface AppWrapperProps {
  children: ReactNode;
}

const AppWrapper: React.FC<AppWrapperProps> = (props) => {
  const { children } = props;

  let activeAccountFromRedux = useAppSelector((state) => state.activeAccount);
  const [activeAccount, setActiveAccount] = useState(activeAccountFromRedux);

  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasSetAccountInLocalStorage, setHasSetAccountInLocalStorage] =
    useState(false);

  useEffect(() => {
    if (
      activeAccount !== undefined &&
      activeAccount.length > 0 &&
      !hasSetAccountInLocalStorage
    ) {
      localStorage.setItem("activeAccount", activeAccount);
      setHasSetAccountInLocalStorage(true);
    } else {
      setActiveAccount(localStorage.getItem("activeAccount") || "");
    }
  }, [activeAccount, hasSetAccountInLocalStorage]);

  useEffect(() => {
    if (
      user === null &&
      !isLoading &&
      activeAccount &&
      activeAccount?.length > 0
    ) {
      setIsLoading(true);
      fetch(
        `${process.env.REACT_APP_API_URL}/users/api/v1?address=${activeAccount}`,
        {
          credentials: "include",
          method: "POST",
        }
      )
        .then((response) => {
          if (!response.ok) {
            Bugsnag.notify(
              JSON.stringify({
                status: response.status,
                statusText: response.statusText,
                body: response.body,
              })
            );
          }

          return response.json();
        })
        .then((user) => {
          setUser(user);
          setIsLoading(false);
        })
        .catch((err) => {
          Bugsnag.notify(err);
          console.log(err);
        });
    }
  }, [activeAccount, isLoading, user]);

  useEffect(() => {
    if (localStorage.getItem("activeAccount") === null) {
      navigate("/login");
    }
  }, [activeAccount, navigate]);

  if (isLoading || !user) {
    return (
      <TailwindResponiveSidebar>
        <div className="lg:px-10 lg:py-10 px-3 py-10 w-full h-screen overflow-y-scroll overflow-x-hidden bg-slate-50">
          <Spinner />
        </div>
      </TailwindResponiveSidebar>
    );
  }

  return (
    <TailwindResponiveSidebar>
      <div className="lg:px-10 lg:py-10 px-3 py-10 w-full h-screen overflow-y-scroll overflow-x-hidden bg-slate-50">
        {children}
      </div>
    </TailwindResponiveSidebar>
  );
};

export default AppWrapper;
