import Bugsnag from "@bugsnag/js";
import { useEffect, useState } from "react";

export const useBillingPlan = () => {
  const [billingPlan, setBillingPlan] = useState("LOADING");

  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      fetch(
        `${process.env.REACT_APP_API_URL}/users/api/v1/billing?address=${
          localStorage.getItem("activeAccount") || ""
        }`,
        {
          credentials: "include",
        }
      )
        .then((response) => {
          if (!response.ok) {
            Bugsnag.notify(
              JSON.stringify({
                status: response.status,
                statusText: response.statusText,
                body: response.body,
              })
            );
          }
          return response.json();
        })
        .then((data) => {
          if (mounted) {
            setBillingPlan(data.subcription_plan);
          }
        });
    };

    fetchData();

    return () => {
      setBillingPlan("");
      mounted = false;
    };
  }, []);

  return billingPlan;
};
