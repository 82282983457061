import Bugsnag from "@bugsnag/js";
import dayjs from "dayjs";
import produce from "immer";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import AppWrapper from "../../components/AppWrapper";
import Spinner from "../../components/Spinner";
import { useBillingPlan } from "../../hooks/useBillingPlan";
import DeleteKeyModal from "./DeleteKeyModal";
import { ClipboardCopyIcon } from "@heroicons/react/outline";
import ConfiguredAlert, {
  ConfiguredAlertVariant,
} from "../../components/ConfiguredAlert";
import { SubcriptionPlan } from "../../components/SubscriptionPurchaseModal";

interface ApiKeyInfo {
  api_key: string;
  creation_timestamp: number;
}

const AppApiKeysPage = () => {
  const billingPlan = useBillingPlan();
  const isBillingPlanLoading = billingPlan === "LOADING";
  const hasValidPlan =
    !isBillingPlanLoading &&
    billingPlan &&
    (billingPlan === SubcriptionPlan.DEGEN ||
      billingPlan === SubcriptionPlan.GIGA_BRAIN);
  const [showDeleteKeyModal, setShowDeleteKeyModal] = useState(false);
  const [apiKeyToDelete, setApiKeyToDelete] = useState("");
  const [apiKeyIndexToDelete, setApiKeyIndexToDelete] = useState(0);
  const [account, setAccount] = useState("");
  const [clipboard, setClipboard] = useState("");
  const [showClipboardAlert, setShowClipboardAlert] = useState(false);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [apiKeys, setApiKeys] = useState<Array<ApiKeyInfo>>([]);

  useEffect(() => {
    if (showClipboardAlert) {
      setTimeout(() => {
        setShowClipboardAlert(false);
      }, 3000);
    }
  }, [setShowClipboardAlert, showClipboardAlert]);

  useEffect(() => {
    const writeToClipboard = async () => {
      if (!navigator.clipboard) {
        return;
      }
      navigator.clipboard
        .writeText(clipboard)
        .then(() => {
          setClipboard("");
        })
        .catch((err) => console.log(err));
    };

    if (clipboard.length > 0) {
      writeToClipboard();
    }
  }, [clipboard]);

  useEffect(() => {
    if (account === "") {
      setAccount(localStorage.getItem("activeAccount") || "");
    }
  }, [account]);

  useEffect(() => {
    const fetchData = async () => {
      await fetch(
        `${process.env.REACT_APP_API_URL}/api-key/api/v1/get-by-user-id`,
        {
          credentials: "include",
        }
      )
        .then((response) => {
          if (!response.ok) {
            Bugsnag.notify(
              JSON.stringify({
                status: response.status,
                statusText: response.statusText,
                body: response.body,
              })
            );
          }

          return response.json();
        })
        .then((data) => {
          setApiKeys(data);
          setIsLoading(false);
        })
        .catch((err) => {
          Bugsnag.notify(err);
          console.log(err);
        });
    };
    if (account.length > 0) {
      fetchData();
    }
  }, [account]);

  const mainContentMobile = (
    <div className="flex lg:flex-row flex-col w-full">
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {apiKeys.length === 0 && (
            <p className="font-medium mt-6 lg:mt-1">
              Api keys you create will appear here
            </p>
          )}
          {apiKeys.map((apiKey: ApiKeyInfo, i: number) => {
            return (
              <>
                <div className="mt-3 lg:mt-8 flex flex-col w-full">
                  <div className="-my-2 mx-0 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle lg:px-8">
                      <div className="px-3 py-6 overflow-hidden shadow-md ring-1 ring-black ring-opacity-5 rounded-lg border border-black border-opacity-10">
                        <div
                          className="w-full flex justify-between"
                          onClick={() => {
                            setClipboard(apiKey.api_key);
                            setShowClipboardAlert(true);
                          }}
                        >
                          <span className="font-semibold text-md">Key: </span>{" "}
                          <span className="flex flex-end">
                            {apiKey.api_key
                              .substring(0, 8)
                              .concat("...")
                              .concat(
                                apiKey.api_key.substring(
                                  apiKey.api_key.length - 8
                                )
                              )}{" "}
                            <ClipboardCopyIcon className="ml-1 h-5 w-5 opacity-50" />
                          </span>
                        </div>

                        <div className="w-full flex justify-between">
                          <span className="font-semibold text-md">
                            Creation date:{" "}
                          </span>{" "}
                          {dayjs
                            .unix(apiKey.creation_timestamp)
                            .format("MMM D YYYY")}
                        </div>

                        <div className="border-t-2 border-gray-300 mb-3 mt-1"></div>

                        <div
                          onClick={() =>
                            navigate(`/app/api-keys/edit?key=${apiKey.api_key}`)
                          }
                        >
                          View / Edit permissions
                        </div>

                        <div
                          onClick={() => {
                            setApiKeyToDelete(apiKey.api_key);
                            setApiKeyIndexToDelete(i);
                            setShowDeleteKeyModal(true);
                          }}
                        >
                          Delete key
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </>
      )}
    </div>
  );

  const mainContentDesktop = (
    <>
      {apiKeys.length === 0 ? (
        <p className="my-2 font-medium">Api keys you create will appear here</p>
      ) : (
        <div className="flex lg:flex-row flex-col w-full">
          <div className="mt-3 lg:mt-8 flex flex-col w-full">
            <div className="-my-2 mx-0 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden shadow-md ring-1 ring-black ring-opacity-5 rounded-lg">
                  <div className="flex px-5 py-3 text-lg font-semibold text-xl bg-white text-gray-700">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 mr-3"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                        />
                      </svg>
                    </svg>{" "}
                    API Keys
                  </div>
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-white">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-normal text-gray-500 sm:pl-6 hidden lg:block"
                        >
                          Key
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-normal text-gray-500"
                        >
                          Creation date
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-normal text-gray-500"
                        >
                          <span className="sr-only">
                            View Associated permissions
                          </span>
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">Delete</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {apiKeys.map((apiKey: ApiKeyInfo, i: number) => {
                        return (
                          <tr className="hover:cursor-pointer hover:bg-gray-100">
                            <td
                              className="flex whitespace-nowrap px-6 py-4 text-sm text-gray-900"
                              onClick={() => {
                                setClipboard(apiKey.api_key);
                                setShowClipboardAlert(true);
                              }}
                            >
                              {apiKey.api_key}
                              <ClipboardCopyIcon className="h-5 w-5 ml-2 opacity-50" />
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                              {dayjs
                                .unix(apiKey.creation_timestamp)
                                .format("MMM D YYYY")}
                            </td>
                            <td
                              onClick={() =>
                                navigate(
                                  `/app/api-keys/edit?key=${apiKey.api_key}`
                                )
                              }
                              className="hover:underline transition ease-in-out 125"
                            >
                              View/edit permissions
                            </td>
                            <td
                              onClick={() => {
                                setApiKeyToDelete(apiKey.api_key);
                                setApiKeyIndexToDelete(i);
                                setShowDeleteKeyModal(true);
                              }}
                              className="hover:underline transition ease-in-out 125"
                            >
                              Delete key
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );

  return (
    <>
      <ConfiguredAlert
        show={showClipboardAlert}
        setShow={(e: boolean) => setShowClipboardAlert(false)}
        variant={ConfiguredAlertVariant.SUCCESS}
        title={"API key copied to clipboard "}
        message={""}
      />

      <DeleteKeyModal
        apiKeyToDelete={apiKeyToDelete}
        onDeleteSuccess={() => {
          setApiKeys(
            produce(apiKeys, (draftApiKeys) => {
              draftApiKeys.splice(apiKeyIndexToDelete, 1);
            })
          );
          setApiKeyToDelete("");
          setApiKeyIndexToDelete(0);
        }}
        onHide={() => setShowDeleteKeyModal(false)}
        open={showDeleteKeyModal}
      />
      <AppWrapper>
        <div className="flex xl:flex-row flex-col justify-between lg:mr-12 mb-1">
          <div className="sm:flex sm:items-center border-gray-200 w-full">
            <div className="sm:flex-auto ml-3 lg:ml-8">
              <div className="flex justify-between w-full">
                <h1 className="text-3xl font-semibold text-gray-900 mt-3">
                  API Keys
                </h1>

                <h1 className="text-xl font-semibold text-gray-500 mt-3">
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    className="hover:bg-gray-100 rounded-lg  transition ease-in-out 125 px-2 py-4 cursor-pointer"
                    href="/api-docs"
                  >
                    API Docs
                  </a>
                </h1>

                {hasValidPlan && (
                  <button
                    onClick={() => navigate("/app/api-keys/create")}
                    className="px-4 py-2 rounded-lg bg-indigo-500 text-white font-bold max-w-md  mt-3 flex justify-center hover:cursor-pointer hover:bg-indigo-600 ease-in-out duration-125"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 mr-1 mb-1"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
                      />
                    </svg>{" "}
                    Create New API Key
                  </button>
                )}
              </div>
              {isBillingPlanLoading ? (
                <div>
                  <Spinner />
                </div>
              ) : (
                <>
                  {hasValidPlan ? (
                    <>
                      <div className="hidden lg:flex">{mainContentDesktop}</div>
                      <div className="flex lg:hidden">{mainContentMobile}</div>
                    </>
                  ) : (
                    <p className="mt-3">
                      This is a premium feature. To generate API keys to read
                      your form responses please upgrade to the Degen or Giga
                      Brain packages.
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </AppWrapper>
    </>
  );
};

export default AppApiKeysPage;
