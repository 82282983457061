import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LinearScale,
  BarElement,
  BarController,
  CategoryScale,
} from "chart.js";
import Spinner from "../../../../../../components/Spinner";

ChartJS.register(LinearScale, BarElement, BarController, CategoryScale);

interface Props {
  data: any;
}

const CheckboxBarChart: React.FC<Props> = (props) => {
  const { data } = props;

  if (!data) {
    return <Spinner />;
  }

  const responses = Object.entries(data.responses).map((entry: Array<any>) => {
    return {
      response: entry[0],
      count: entry[1].count,
    };
  });

  const options = {
    responsive: true,
    scales: {
      y: {
        ticks: {
          stepSize: 1,
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context: any) {
            // Note: this rounds to 2 decimal places because the js stl doesn't have a proper round function
            // because it's a shit language that should be burned with fire
            return `${context.raw} (${
              Math.round(100.0 * (context.raw / data.total) * 100) / 100
            }%)`;
          },
        },
      },
      scales: {
        y: {
          ticks: {
            stepsize: 1,
          },
        },
      },
      legend: {
        position: "bottom" as const,
      },
    },
  };

  console.log(responses);
  console.log(responses.map((d: any) => d.response));

  const backgroundColors = [
    "#648fff80",
    "#785ef080",
    "#dc267f80",
    "#fe610080",
    "#ffb00080",
    "#00000080",
    "white",
  ];

  const borderColor = [
    "#648fff80",
    "#785ef080",
    "#dc267f80",
    "#fe610080",
    "#ffb00080",
    "#00000080",
    "white",
  ];

  const config = {
    labels: [""],
    datasets: responses.map((response: any, index: number) => {
      return {
        label: response.response,
        data: [parseInt(response.count)],
        backgroundColor: [backgroundColors[index]],
        borderColor: [borderColor[index]],
        borderWidth: 1,
      };
    }),
  };

  return (
    <div className="h-fit lg:h-96 max-w-xs lg:max-w-2xl mt-6">
      <h1>{data.title}</h1>
      <h2 className="text-sm font-medium mb-3">
        {data.total} {data.total === 1 ? "response" : "responses"}
      </h2>
      <Bar options={options} data={config} />
    </div>
  );
};

export default CheckboxBarChart;
