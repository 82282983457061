import React, { Fragment, ReactNode, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import Davatar from "@davatar/react";
import ShortAddress from "../ShortAddress";
import { useEthers } from "@usedapp/core";
import { useNavigate } from "react-router-dom";
import Bugsnag from "@bugsnag/js";
import clsx from "clsx";
import { BeakerIcon } from "@heroicons/react/outline";
import { isBoardView } from "../../utils/isBoard";

interface TailwindResponiveSidebarProps {
  children: ReactNode;
}

const getStyleFromPage = (route: string) => {
  // eslint-disable-next-line no-restricted-globals
  console.log(location.pathname);

  // eslint-disable-next-line no-restricted-globals
  if (location.pathname === route) {
    return "bg-gray-700 text-gray-200";
  }

  return "bg-gray-800 text-gray-400 hover:bg-gray-700 hover:text-gray-200";
};

const TailwindResponiveSidebar: React.FC<TailwindResponiveSidebarProps> = (
  props
) => {
  const { children } = props;

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const address = localStorage.getItem("activeAccount") || "";
  const { library: provider, deactivate, account } = useEthers();
  const navigate = useNavigate();
  const [isLogoutClicked, setIsLogoutClicked] = useState(false);

  const isBoard = isBoardView(address);

  useEffect(() => {
    if (isLogoutClicked) {
      fetch(`${process.env.REACT_APP_API_URL}/auth/api/v1/logout`, {
        credentials: "include",
      }).then((response) => {
        if (!response.ok) {
          Bugsnag.notify(
            JSON.stringify({
              status: response.status,
              statusText: response.statusText,
              body: response.body,
            })
          );
        }

        setIsLogoutClicked(false);
        navigate("/login");
        if (account) {
          deactivate();
        }

        localStorage.removeItem("activeAccount");
      });
      setIsLogoutClicked(false);
    }
  }, [isLogoutClicked, navigate, deactivate, account]);

  const [billingPlan, setBillingPlan] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [planExpirationTimestamp, setPlanExpirationTimestamp] = useState(0);
  const [isLoadingPlan, setIsLoadingPlan] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      fetch(
        `${process.env.REACT_APP_API_URL}/users/api/v1/billing?address=${
          localStorage.getItem("activeAccount") || ""
        }`,
        {
          credentials: "include",
        }
      )
        .then((response) => {
          if (!response.ok) {
            Bugsnag.notify(
              JSON.stringify({
                status: response.status,
                statusText: response.statusText,
                body: response.body,
              })
            );
          }
          return response.json();
        })
        .then((data) => {
          setBillingPlan(data.subcription_plan);
          setPlanExpirationTimestamp(
            data.current_subscription_expiration_timestamp
          );
          setTimeout(() => {
            setIsLoadingPlan(false);
          }, 500);
        });
    };

    if (isLoadingPlan) {
      fetchData();
    }
  }, [isLoadingPlan]);

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-100">
        <body class="h-full">
        ```
      */}
      <div className="w-screen">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 flex z-40 md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-xs w-full bg-gray-800">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>

                <h2
                  className="text-3xl font-semibold text-center text-white mt-3"
                  onClick={() => navigate("/app")}
                >
                  <div className="flex w-fit mx-auto">
                    <div className="flex-shrink-0 flex items-center hover:cursor-pointer">
                      <div className="mx-auto text-3xl text-center">📝</div>
                      <h2 className="ml-1 mt-2 text-center text-2xl font-bold">
                        {isBoard ? "AddressBoard" : "AddressForm"}
                      </h2>
                    </div>
                  </div>
                </h2>

                {address && address.length > 0 && (
                  <div className="flex flex-col items-center mt-6 -mx-2">
                    <div
                      key={address}
                      className="object-cover w-24 h-fit flex justify-center"
                    >
                      <Davatar
                        size={72}
                        address={address}
                        provider={provider}
                      />
                    </div>
                    <h4 className="mx-auto w-fit mt-2 font-semibold text-gray-200">
                      <ShortAddress address={address} />
                    </h4>
                  </div>
                )}


                <div hidden={isBoard}>
                <button
                  onClick={() => navigate("/app/form/create")}
                  className="px-4 py-2 rounded-lg bg-indigo-500 text-white font-bold w-2/3 mx-auto mt-4 flex justify-center hover:cursor-pointer hover:bg-indigo-600 ease-in-out duration-125 hover:scale-110"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 mr-1 mt-0.5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V8z"
                      clipRule="evenodd"
                    />
                  </svg>{" "}
                  New Form
                </button>
                </div>

                {/* <button
                  onClick={() => navigate("/app/board/create")}
                  className="px-4 py-2 rounded-lg bg-indigo-500 text-white font-bold w-2/3 mx-auto mt-4 flex justify-center hover:cursor-pointer hover:bg-indigo-600 ease-in-out duration-125 hover:scale-110"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 mr-1 mt-0.5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V8z"
                      clipRule="evenodd"
                    />
                  </svg>{" "}
                  New Board
                </button> */}

                {/* <nav className="mt-5 flex-1 px-2 space-y-1">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className={classNames(
                      item.current
                        ? "bg-gray-900 text-white"
                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                      "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? "text-gray-300"
                          : "text-gray-400 group-hover:text-gray-300",
                        "mr-3 flex-shrink-0 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </a>
                ))}
              </nav> */}
                <div className="flex flex-col justify-between flex-1 mt-6">
                  <nav>
                    <div
                      className={clsx(
                        "flex items-center px-4 py-2 hover:cursor-pointer",
                        getStyleFromPage("/app")
                      )}
                      onClick={() => navigate("/app")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                        />
                      </svg>

                      <span className="mx-4 font-semibold">Home</span>
                    </div>

                    <div
                      className={clsx(
                        "hover:cursor-pointer flex items-center px-4 py-2 mt-5 transition-colors duration-200 transform",
                        getStyleFromPage("/app/settings")
                      )}
                      onClick={() => navigate("/app/settings")}
                    >
                      <svg
                        className="w-5 h-5"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.3246 4.31731C10.751 2.5609 13.249 2.5609 13.6754 4.31731C13.9508 5.45193 15.2507 5.99038 16.2478 5.38285C17.7913 4.44239 19.5576 6.2087 18.6172 7.75218C18.0096 8.74925 18.5481 10.0492 19.6827 10.3246C21.4391 10.751 21.4391 13.249 19.6827 13.6754C18.5481 13.9508 18.0096 15.2507 18.6172 16.2478C19.5576 17.7913 17.7913 19.5576 16.2478 18.6172C15.2507 18.0096 13.9508 18.5481 13.6754 19.6827C13.249 21.4391 10.751 21.4391 10.3246 19.6827C10.0492 18.5481 8.74926 18.0096 7.75219 18.6172C6.2087 19.5576 4.44239 17.7913 5.38285 16.2478C5.99038 15.2507 5.45193 13.9508 4.31731 13.6754C2.5609 13.249 2.5609 10.751 4.31731 10.3246C5.45193 10.0492 5.99037 8.74926 5.38285 7.75218C4.44239 6.2087 6.2087 4.44239 7.75219 5.38285C8.74926 5.99037 10.0492 5.45193 10.3246 4.31731Z"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>

                      <span className="mx-4 font-medium">Settings</span>
                    </div>

                    <div
                      className="flex items-center px-4 py-2 mt-5 transition-colors duration-200 transform text-gray-400 hover:bg-gray-700 hover:text-gray-200 hover:cursor-pointer"
                      onClick={() => navigate("/app/api-keys")}
                    >
                      <BeakerIcon className="h-5 w-5" />
                      <span className="mx-4 font-medium">API Keys</span>
                    </div>

                    <div
                      className="flex items-center px-4 py-2 mt-5 transition-colors duration-200 transform text-gray-400 hover:bg-gray-700 hover:text-gray-200  hover:cursor-pointer"
                      onClick={() => setIsLogoutClicked(true)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                        />
                      </svg>
                      <span className="mx-4 font-medium">Log out</span>
                    </div>
                  </nav>
                </div>

                {/* Upgrade subcription btn */}
                <div className="mx-auto text-white font-medium">
                  <span className="opacity-50">Your current plan:</span>{" "}
                  {!isLoadingPlan && (
                    <strong>
                      {billingPlan && billingPlan.length > 0
                        ? billingPlan
                        : "Free Tier"}
                    </strong>
                  )}
                </div>
                <div className="mx-auto mb-6">
                  {!isLoadingPlan &&
                    (!billingPlan || billingPlan.length === 0) && (
                      <button
                        onClick={() => navigate("/pricing?intent=upgrade")}
                        className="w-fit px-4 py-2 rounded-lg bg-indigo-500 text-white font-bold mt-4 flex justify-center hover:cursor-pointer hover:bg-indigo-600 ease-in-out duration-125 hover:scale-110"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6 mr-1"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"
                          />
                        </svg>
                        Upgrade subscription
                      </button>
                    )}
                </div>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex-1 flex flex-col min-h-0 bg-gray-800">
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto overflow-x-hidden">
              <h2
                className="text-3xl font-semibold text-center text-white"
                onClick={() => navigate("/app")}
              >
                <div className="flex w-fit mx-auto">
                  <div className="flex-shrink-0 flex items-center hover:cursor-pointer">
                    <div className="mx-auto text-3xl text-center">📝</div>
                    <h2 className="ml-1 mt-2 text-center text-2xl font-bold">
                      {isBoard ? "AddressBoard" : "AddressForm"}
                    </h2>
                  </div>
                </div>
              </h2>

              {address && address.length > 0 && (
                <div className="flex flex-col items-center mt-6 -mx-2">
                  <div
                    key={address}
                    className="object-cover w-24 h-fit flex justify-center"
                  >
                    <Davatar size={72} address={address} provider={provider} />
                  </div>
                  <h4 className="mx-auto w-fit mt-2 font-semibold text-gray-200">
                    <ShortAddress address={address} />
                  </h4>
                </div>
              )}

              <div hidden={isBoard}>
              <button             
                onClick={() => navigate("/app/form/create")}
                className="px-4 py-2 rounded-lg bg-indigo-500 text-white font-bold w-2/3 mx-auto mt-4 flex justify-center hover:cursor-pointer hover:bg-indigo-600 ease-in-out duration-125 hover:scale-110"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 mr-1 mt-0.5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V8z"
                    clipRule="evenodd"
                  />
                </svg>{" "}
                New Form
              </button>
              </div>

              {/* <button
                  onClick={() => navigate("/app/board/create")}
                  className="px-4 py-2 rounded-lg bg-indigo-500 text-white font-bold w-2/3 mx-auto mt-4 flex justify-center hover:cursor-pointer hover:bg-indigo-600 ease-in-out duration-125 hover:scale-110"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 mr-1 mt-0.5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V8z"
                      clipRule="evenodd"
                    />
                  </svg>{" "}
                  New Board
                </button> */}

              <div className="flex flex-col justify-between flex-1 mt-6">
                <nav>
                  <div
                    className={clsx(
                      "flex items-center px-4 py-2 hover:cursor-pointer",
                      getStyleFromPage("/app")
                    )}
                    onClick={() => navigate("/app")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                      />
                    </svg>

                    <span className="mx-4 font-semibold">Home</span>
                  </div>

                  <div
                    className={clsx(
                      "hover:cursor-pointer flex items-center px-4 py-2 mt-5 transition-colors duration-200 transform",
                      getStyleFromPage("/app/settings")
                    )}
                    onClick={() => navigate("/app/settings")}
                  >
                    <svg
                      className="w-5 h-5"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.3246 4.31731C10.751 2.5609 13.249 2.5609 13.6754 4.31731C13.9508 5.45193 15.2507 5.99038 16.2478 5.38285C17.7913 4.44239 19.5576 6.2087 18.6172 7.75218C18.0096 8.74925 18.5481 10.0492 19.6827 10.3246C21.4391 10.751 21.4391 13.249 19.6827 13.6754C18.5481 13.9508 18.0096 15.2507 18.6172 16.2478C19.5576 17.7913 17.7913 19.5576 16.2478 18.6172C15.2507 18.0096 13.9508 18.5481 13.6754 19.6827C13.249 21.4391 10.751 21.4391 10.3246 19.6827C10.0492 18.5481 8.74926 18.0096 7.75219 18.6172C6.2087 19.5576 4.44239 17.7913 5.38285 16.2478C5.99038 15.2507 5.45193 13.9508 4.31731 13.6754C2.5609 13.249 2.5609 10.751 4.31731 10.3246C5.45193 10.0492 5.99037 8.74926 5.38285 7.75218C4.44239 6.2087 6.2087 4.44239 7.75219 5.38285C8.74926 5.99037 10.0492 5.45193 10.3246 4.31731Z"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    <span className="mx-4 font-medium">Settings</span>
                  </div>
                  <div
                    className="flex items-center px-4 py-2 mt-5 transition-colors duration-200 transform text-gray-400 hover:bg-gray-700 hover:text-gray-200 hover:cursor-pointer"
                    onClick={() => navigate("/app/api-keys")}
                  >
                    <BeakerIcon className="h-5 w-5" />
                    <span className="mx-4 font-medium">API Keys</span>
                  </div>

                  <div
                    className="flex items-center px-4 py-2 mt-5 transition-colors duration-200 transform text-gray-400 hover:bg-gray-700 hover:text-gray-200 hover:cursor-pointer"
                    onClick={() => setIsLogoutClicked(true)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                      />
                    </svg>
                    <span className="mx-4 font-medium">Log out</span>
                  </div>
                </nav>
              </div>
            </div>

            {/* Upgrade subcription btn */}
            <div className="mx-auto text-white font-medium">
              <span className="opacity-50">Your current plan:</span>{" "}
              {!isLoadingPlan && (
                <strong>
                  {billingPlan && billingPlan.length > 0
                    ? billingPlan
                    : "Free Tier"}
                </strong>
              )}
            </div>
            {!isLoadingPlan && (!billingPlan || billingPlan.length === 0) ? (
              <div className="mx-auto mb-6">
                <button
                  onClick={() => navigate("/pricing?intent=upgrade")}
                  className="w-fit px-4 py-2 rounded-lg bg-indigo-500 text-white font-bold mt-4 flex justify-center hover:cursor-pointer hover:bg-indigo-600 ease-in-out duration-125 hover:scale-110"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 mr-1"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"
                    />
                  </svg>
                  Upgrade subscription
                </button>
              </div>
            ) : (
              <div className="mt-6" />
            )}
          </div>
        </div>
        <div className="md:pl-64 flex flex-col flex-1">
          <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-700 flex justify-between">
            <h2
              className="text-3xl font-semibold text-center text-white mt-1 ml-1"
              onClick={() => navigate("/app")}
            >
              <div className="flex w-fit mx-auto">
                <div className="flex-shrink-0 flex items-center hover:cursor-pointer">
                  <div className="mx-auto text-3xl text-center">📝</div>
                  <h2 className="ml-1 mt-2 text-center text-2xl font-bold">
                    {isBoard ? "AddressForm" : "AddressBoard"}
                  </h2>
                </div>
              </div>
            </h2>

            <button
              type="button"
              className="-ml-0.5 -mt-0.5 h-14 w-14 inline-flex items-center justify-center rounded-md text-white hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuIcon className="h-8 w-8" aria-hidden="true" />
            </button>
          </div>
          <main className="flex-1">{children}</main>
        </div>
      </div>
    </>
  );
};
export default TailwindResponiveSidebar;
