import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import AppWrapper from "../../components/AppWrapper";
import FormAnalyicsPageTabs from "../../components/FormAnayticsPageTabs";
import RespondantAnalytics from "./components/RespondantAnalytics";
import ResponseAnalytics from "./components/ResponseAnalytics";

const FormDetailsAnalyticsPage = () => {
  const [searchParams] = useSearchParams();
  const [onDisplayFormEncId, setOnDisplayFormEncId] = useState("");
  const [formTitle, setFormTitle] = useState("");
  const navigate = useNavigate();
  const [isCurrentTabResponseAnalytics, setIsCurrentTabResponseAnalytics] =
    useState(true);

  useEffect(() => {
    if (onDisplayFormEncId !== searchParams.get("id")) {
      setOnDisplayFormEncId(searchParams.get("id") || "");
    }
  }, [onDisplayFormEncId, searchParams]);

  useEffect(() => {
    if (formTitle !== searchParams.get("title")) {
      setFormTitle(searchParams.get("title") || "");
    }
  }, [formTitle, searchParams]);

  if (onDisplayFormEncId === "") {
    return (
      <AppWrapper>
        <h1 className="font-bold text-center">No form id provided in URL</h1>
      </AppWrapper>
    );
  }

  const content = (
    <div className="w-full max-w-screen-sm lg:max-w-none">
      <FormAnalyicsPageTabs
        currentFromParent={isCurrentTabResponseAnalytics}
        onChange={(e: boolean) => setIsCurrentTabResponseAnalytics(e)}
      />
      {isCurrentTabResponseAnalytics ? (
        <ResponseAnalytics formIdEncid={onDisplayFormEncId} />
      ) : (
        <RespondantAnalytics formIdEncid={onDisplayFormEncId} />
      )}
    </div>
  );

  return (
    <AppWrapper>
      <div className="flex flex-col lg:mr-12 mb-1">
        <div className="sm:flex sm:items-center border-gray-200">
          <div className="sm:flex-auto">
            <h1 className="text-3xl font-semibold text-gray-900 mt-1 lg:mt-3 lg:ml-8">
              Analytics
            </h1>
          </div>
        </div>

        <div className="flex items-center py-4 overflow-y-auto whitespace-nowrap lg:ml-8">
          <div
            onClick={() => navigate("/app")}
            className="text-gray-600  hover:underline hover:cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
            </svg>
          </div>

          <span className="mx-5 text-gray-500 ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </span>

          <div
            onClick={() => navigate(`/app/form?id=${onDisplayFormEncId}`)}
            className="text-gray-600 font-medium hover:underline hover:cursor-pointer"
          >
            {formTitle}
          </div>

          <span className="mx-5 text-gray-500 ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </span>
          <div className="text-gray-600 font-medium hover:cursor-normal">
            Analytics
          </div>
        </div>

        <div className="flex lg:flex-row flex-col-reverse lg:ml-6">
          <div className="mt-3 lg:mt-8 flex flex-col w-full lg:pr-4">
            <div className="-my-2 mx-0 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden shadow-md ring-1 ring-black ring-opacity-5 rounded-lg">
                  <div className="flex px-5 py-3 text-lg font-semibold text-xl text-gray-700 bg-white">
                    {content}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppWrapper>
  );
};

export default FormDetailsAnalyticsPage;
