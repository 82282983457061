import { useEffect, useState } from "react";

interface Props {
  onFormSchemaChange: (e: any) => void;
  onUiSchemaChange: (e: any) => void;
}

// TODO -- where does the form schema for this live??
const MultiImageUploadQuestionBuilder: React.FC<Props> = (props) => {
  const { onFormSchemaChange, onUiSchemaChange } = props;

  // TODO should these be state for consistency purposes or just hardcoded for KISS?
  const [formSchemaFragment] = useState({
    type: "array",
    default: [{}],
    items: {
      properties: {
        file: {
          type: "string",
          format: "data-url",
        },
      },
    },
  });
  const [uiSchemaFragment] = useState({
    items: {
      file: {
        "ui:options": {
          accept: [".png", ".jpeg", ".jpg", ".gif"],
        },
      },
    },
  });

  useEffect(() => {
    onFormSchemaChange(formSchemaFragment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSchemaFragment]);

  useEffect(() => {
    onUiSchemaChange(uiSchemaFragment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onUiSchemaChange]);

  return (
    <p className="text-xl font-semibold ml-1">
      Upload 1 or more .png / .jpeg / .jpg / .gif files
    </p>
  );
};

export default MultiImageUploadQuestionBuilder;
