export interface Form {
  name: string;
  formIdEncId: string;
  creationTimestamp: string;
}

interface FormCheckboxSelectorProps {
  forms: Form[];
  defaultCheckedFormEncIds: string[];
  onChange: (state: string, formIdEncId: string) => void;
}

const FormCheckboxSelector: React.FC<FormCheckboxSelectorProps> = (props) => {
  const { forms, defaultCheckedFormEncIds, onChange } = props;

  return (
    <fieldset className="space-y-5 bg-white px-4 py-4 rounded-lg border border-gray-200 shaddow-sm mt-3 h-36 overflow-y-scroll">
      {forms &&
        forms.map((form: Form, i: number) => {
          const checkedByDefault = defaultCheckedFormEncIds.includes(
            form.formIdEncId
          );

          return (
            <div className="relative flex items-start">
              <div className="flex items-center h-5">
                <input
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onChange(e.target.value, form.formIdEncId)
                  }
                  id="form-checkbox-selector"
                  aria-describedby="form-checkbox-selector-description"
                  name="form-checkbox-selector"
                  type="checkbox"
                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                  defaultChecked={checkedByDefault}
                />
              </div>
              <div className="ml-3 text-sm">
                <label
                  htmlFor="form-checkbox-selector"
                  className="font-medium text-gray-700"
                >
                  {form.name}
                </label>
              </div>
            </div>
          );
        })}
    </fieldset>
  );
};

export default FormCheckboxSelector;
