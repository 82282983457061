import Bugsnag from "@bugsnag/js";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import AppWrapper from "../../components/AppWrapper";
import DeleteResponseModal from "../../components/DeleteResponseModal";
import FormDetailsNavBar from "../../components/FormDetailsNavBar";
import ShortAddress from "../../components/ShortAddress";
import Spinner from "../../components/Spinner";
import { CheckIcon, XIcon, SwitchVerticalIcon } from "@heroicons/react/outline";
import FormResponseApprovalStatusFilterTabs from "../../components/FormResponseApprovalStateFilterTabs";
import { postJsonToBackend } from "../../utils/postJsonToBackend";
import { Form } from "@rjsf/bootstrap-4";
import { Button } from "@material-ui/core";
import StableStringify from "json-stable-stringify";
import clsx from "clsx";
import { isBoardView } from "../../utils/isBoard";

enum FormResponseSort {
  SORT_ASCENDING_SUBMIT_TIME,
  SORT_DESCENDING_SUBMIT_TIME,
  SORT_WAITING_APPROVE_REJECT,
  SORT_APPROVE_REJECT_WAITING,
}

const mapApprovalStateToSortNumber = (approvalState?: string) => {
  if (!approvalState) {
    return 0;
  }
  if (approvalState === "REJECTED") {
    return 1;
  }
  return 2;
};

const getSortFunctionForSort = (sort: FormResponseSort) => {
  switch (sort) {
    case FormResponseSort.SORT_ASCENDING_SUBMIT_TIME:
      return (p1: any, p2: any) => {
        return -1 * (p1.timestamp - p2.timestamp);
      };
    case FormResponseSort.SORT_DESCENDING_SUBMIT_TIME:
      return (p1: any, p2: any) => {
        return p1.timestamp - p2.timestamp;
      };
    case FormResponseSort.SORT_WAITING_APPROVE_REJECT:
      return (p1: any, p2: any) => {
        return (
          mapApprovalStateToSortNumber(p1.is_approved_flags) -
          mapApprovalStateToSortNumber(p2.is_approved_flags)
        );
      };
    case FormResponseSort.SORT_APPROVE_REJECT_WAITING:
      return (p1: any, p2: any) => {
        return (
          -1 *
          (mapApprovalStateToSortNumber(p1.is_approved_flags) -
            mapApprovalStateToSortNumber(p2.is_approved_flags))
        );
      };
    default:
      return (p1: any, p2: any) => {
        return 1;
      };
  }
};

const indexOfFormResponseId = (arr: Array<any>, formResponseId: number) => {
  const filterd = arr
    .map((d: any, i: number) => [d, i])
    .filter((pair: any) => {
      return pair[0].form_response_id === formResponseId;
    });

  if (filterd.length === 0) {
    return -1;
  }

  return arr.indexOf(filterd[0][0]);
};

const renderFormResponseFromParsedJson = (
  parsedFormResponse: any,
  orderingArray: Array<string>,
  formIdentifierToTitleMap: Map<string, string>,
  formSchema: any,
  uiSchema: any,
  showEdit: any,
  setShowEdit: any, //(showEdit: any)=> void
  onSubmit: any,
  id: string
) => {
  console.log("showEdit", showEdit);
  return Object.entries(parsedFormResponse[0])
    .sort((entryA, entryB) => {
      return (
        orderingArray.indexOf(entryA[0]) - orderingArray.indexOf(entryB[0])
      );
    })
    .map((entry: any) => {
      console.log("ENTRY: ", entry);
      let formDataLocal = "";
      return (
        <div className="py-2" key={entry[0]}>
          <h1 className="text-lg font-normal">
            {formIdentifierToTitleMap.get(entry[0]) || entry[0]}
          </h1>
          <h2 className="text-lg font-bold">
            <>
              {parsedFormResponse[1] !== null &&
              JSON.parse(parsedFormResponse[1]).image?.[entry[0]] ? (
                <>
                  {JSON.parse(parsedFormResponse[1]).image?.[entry[0]].map(
                    (data: any) => {
                      return (
                        <>
                          <button
                            className="px-4 py-2 rounded-lg bg-indigo-500 text-white font-bold w-fit mt-4 flex justify-center hover:cursor-pointer hover:bg-indigo-600 ease-in-out duration-125"
                            disabled={true}
                          >
                            Edit
                          </button>
                          {"Image response edit coming soon"}
                          <img src={data[1]} alt={data[0]}></img>
                        </>
                      );
                    }
                  )}
                </>
              ) : (
                <>
                  {Array.isArray(entry[1]) ? entry[1].join(", ") : entry[1]}
                  {showEdit[entry[0]] && (
                    <>
                      {id === "eb53c0af-4900-424c-ab45-ce72d94efaaf" ? (
                        <>
                          <input
                            type="text"
                            className={clsx(
                              "shadow-sm block w-full sm:text-sm rounded-md",
                              "border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                            )}
                            defaultValue={entry[1]}
                            onChange={(e) => {
                              formDataLocal = e.target.value;
                              console.log("formDataLocal", formDataLocal);
                            }}
                          />
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={() => {
                              console.log("submit data", formDataLocal);
                              onSubmit(formDataLocal, entry[0]);
                            }}
                          >
                            Submit
                          </Button>
                        </>
                      ) : (
                        <Form
                          schema={formSchema?.properties[entry[0]]}
                          children={
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                            >
                              Submit
                            </Button>
                          }
                          formData={entry[1]}
                          uiSchema={
                            uiSchema && uiSchema.length > 0
                              ? JSON.parse(uiSchema)[entry[0]]
                              : {}
                          }
                          onChange={(e) => {
                            formDataLocal = e.formData;
                            console.log("formDataLocal", formDataLocal);
                          }}
                          onSubmit={() => {
                            console.log("submit data", formDataLocal);
                            onSubmit(formDataLocal, entry[0]);
                          }}
                        />
                      )}
                    </>
                  )}
                  {!showEdit[entry[0]] && (
                    <button
                      className="px-4 py-2 rounded-lg bg-indigo-500 text-white font-bold w-fit mt-4 flex justify-center hover:cursor-pointer hover:bg-indigo-600 ease-in-out duration-125"
                      onClick={() => {
                        const newShowEdit = {} as any;
                        newShowEdit[entry[0]] = !newShowEdit[entry[0]];
                        setShowEdit(newShowEdit);
                      }}
                    >
                      Edit
                    </button>
                  )}

                  {showEdit[entry[0]] && (
                    <button
                      className="px-4 py-2 rounded-lg bg-indigo-500 text-white font-bold w-fit mt-4 flex justify-center hover:cursor-pointer hover:bg-indigo-600 ease-in-out duration-125"
                      onClick={() => {
                        setShowEdit({});
                      }}
                    >
                      Cancel
                    </button>
                  )}
                </>
              )}
            </>
          </h2>
        </div>
      );
    });
};
export enum ApprovalWorkflowFilter {
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  PENDING = "PENDING",
  ALL = "ALL",
}

const getApprovalStatusPillByState = (isApprovedFlags: string | null) => {
  if (!isApprovedFlags) {
    return (
      <div className="px-4 py-2 bg-blue-100 rounded-lg text-blue-500 w-24 font-medium flex justify-center">
        Waiting
      </div>
    );
  }

  if (isApprovedFlags === ApprovalWorkflowFilter.APPROVED) {
    return (
      <div className="px-4 py-2 bg-green-100 rounded-lg text-green-500 w-24 font-medium flex justify-center">
        Approved
      </div>
    );
  }

  return (
    <div className="px-4 py-2 bg-red-100 rounded-lg text-red-500 w-24 font-medium flex justify-center">
      Rejected
    </div>
  );
};

const FormDetailsPage = () => {
  const [searchParams] = useSearchParams();
  const [onDisplayFormEncId, setOnDisplayFormEncId] = useState("");

  const [formCustomUrl, setFormCustomUrl] = useState("");
  const [showEdit, setShowEdit] = useState({});
  const [formSchema, setFormSchema] = useState("");
  const [uiSchema, setUiSchema] = useState("");
  const [data, setData] = useState<Array<any>>([]);
  const [idToDelete, setIdToDelete] = useState(-1);
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [activeResponse, setActiveResponse] = useState(0);
  const [responseData, setResponseData] = useState<Array<any>>([]);
  const [isLoadingFormResponses, setIsLoading] = useState(true);
  const [isLoadingFormSchema, setIsLoadingFormSchema] = useState(true);
  const [fieldTitleOrder, setFieldTitleOrder] = useState<Array<any>>([]);
  const [formTitle, setFormTitle] = useState("");
  const [boardTags, setBoardTags] = useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [newTag, setNewTag] = useState("");
  const [formIdentifierToTitleMap, setFormIdentifierToTitleMap] = useState<
    Map<string, string>
  >(new Map<string, string>());
  const [isApproveBtnClicked, setIsApproveBtnClicked] = useState(false);
  const [isRejectBtnClicked, setIsRejectBtnClicked] = useState(false);
  const [isClearApprovalStateBtnClicked, setIsClearApprovalStateBtnClicked] =
    useState(false);
  const [responseToUpdateApprovalState, setResponseToUpdateApprovalState] =
    useState(0);

  const [formUsesApprovalWorkflow, setFormUsesApprovalWorkflow] =
    useState(false);
  const [initalFilterSetByParam, setInitalFilterSetByParam] = useState(false);

  const [formApprovalStateFilter, setFormApprovlaStateFilter] =
    useState<ApprovalWorkflowFilter>(ApprovalWorkflowFilter.ALL);

  const [timestampSortAsc, setTimestampSortAsc] = useState(false);
  const [approvalStateSortAsx, setApprovalStateSortAsc] = useState(false);
  const [currentSort, setCurrentSort] = useState<FormResponseSort>(
    FormResponseSort.SORT_WAITING_APPROVE_REJECT
  );
  const [responseHeroResponseId, setResponseHeroResponseId] = useState(-1);
  const [isResponseHeroSet, setIsResponseHeroSet] = useState(false);
  const [createdAtTimestamp, setCreatedAtTimestamp] = useState(0);

  useEffect(() => {
    if (searchParams.get("response_hero") && !isResponseHeroSet) {
      setResponseHeroResponseId(
        parseInt(searchParams.get("response_hero") || "-1")
      );
      setCurrentSort(currentSort);
      setIsResponseHeroSet(true);
      setTimeout(() => {
        setResponseHeroResponseId(-1);
      }, 1000);
    }
  }, [currentSort, isResponseHeroSet, searchParams]);

  useEffect(() => {
    if (
      searchParams.get("filter") === "WAITING" &&
      !initalFilterSetByParam &&
      formApprovalStateFilter === ApprovalWorkflowFilter.ALL
    ) {
      setFormApprovlaStateFilter(ApprovalWorkflowFilter.PENDING);
      setInitalFilterSetByParam(true);
    }
  }, [formApprovalStateFilter, initalFilterSetByParam, searchParams]);

  useEffect(() => {
    if (onDisplayFormEncId !== searchParams.get("id")) {
      setOnDisplayFormEncId(searchParams.get("id") || "");
    }
  }, [onDisplayFormEncId, searchParams]);

  const [forceReload, setForceReload] = useState(false);

  // Approve / reject
  useEffect(() => {
    const fetchData = async () => {
      postJsonToBackend(
        {
          is_approved_flags: isApproveBtnClicked
            ? ApprovalWorkflowFilter.APPROVED
            : ApprovalWorkflowFilter.REJECTED,
          form_response_id: responseToUpdateApprovalState,
          form_id_encid: onDisplayFormEncId,
        },
        `form-responses/api/v1/approval`
      )
        .then((response) => {
          if (!response.ok) {
            Bugsnag.notify(
              JSON.stringify({
                status: response.status,
                statusText: response.statusText,
                body: response.body,
              })
            );
          }

          // setIsApproveBtnClicked(false);
          // setIsRejectBtnClicked(false);

          const filteredResponses =
            data &&
            data.length > 0 &&
            data
              .sort((person1: any, person2: any) => {
                const sortFunction = getSortFunctionForSort(currentSort);
                return sortFunction(person1, person2);
              })
              .filter((person: any) => {
                if (person.form_response_id === activeResponse) {
                  return false;
                }

                if (
                  !formUsesApprovalWorkflow ||
                  formApprovalStateFilter === ApprovalWorkflowFilter.ALL
                ) {
                  return true;
                }
                if (
                  formApprovalStateFilter === ApprovalWorkflowFilter.PENDING
                ) {
                  return person.is_approved_flags === null;
                }

                return person.is_approved_flags === formApprovalStateFilter;
              });

          // setForceReload(true);
          if (filteredResponses && filteredResponses.length > 0) {
            // TODO is this asking for concurrency issues???
            setActiveResponse(
              indexOfFormResponseId(data, filteredResponses[0].form_response_id)
            );
          }
          setIsApproveBtnClicked(false);
          setIsRejectBtnClicked(false);

          setForceReload(true);

          // TODO this is terrible
        })
        .catch((err) => {
          Bugsnag.notify(err);
          console.log(err);
        });
    };

    if (isApproveBtnClicked || isRejectBtnClicked) {
      fetchData();
    }
  }, [
    activeResponse,
    currentSort,
    data,
    formApprovalStateFilter,
    formUsesApprovalWorkflow,
    isApproveBtnClicked,
    isRejectBtnClicked,
    onDisplayFormEncId,
    responseToUpdateApprovalState,
  ]);

  //reset approval status
  useEffect(() => {
    const fetchData = async () => {
      postJsonToBackend(
        {
          form_response_id: responseToUpdateApprovalState,
        },
        `form-responses/api/v1/approval-clear`
      )
        .then((response) => {
          if (!response.ok) {
            Bugsnag.notify(
              JSON.stringify({
                status: response.status,
                statusText: response.statusText,
                body: response.body,
              })
            );
          }
          setIsClearApprovalStateBtnClicked(false);
          setForceReload(true);
        })
        .catch((err) => {
          Bugsnag.notify(err);
          console.log(err);
        });
    };

    if (isClearApprovalStateBtnClicked) {
      fetchData();
    }
  }, [isClearApprovalStateBtnClicked, responseToUpdateApprovalState]);

  useEffect(() => {
    const fetchData = async () => {
      await fetch(
        `${process.env.REACT_APP_API_URL}/form-responses/api/v1/get-by-form-enc-id?form_id_encid=${onDisplayFormEncId}`,
        {
          credentials: "include",
        }
      )
        .then((response) => {
          if (!response.ok) {
            Bugsnag.notify(
              JSON.stringify({
                status: response.status,
                statusText: response.statusText,
                body: response.body,
              })
            );
          }
          return response.json();
        })
        .then((data) => {
          console.log("FORM RESPONSES: ", data);
          setData(data);
          setIsLoading(false);
          // setParsedResponseData(JSON.parse(data.form_data))
          setResponseData(
            data.map((data: any) => {
              return [
                JSON.parse(data.form_data),
                data.meta_data,
                data.form_response_id,
              ];
            })
          );
        })
        .catch((err) => {
          Bugsnag.notify(err);
          console.log(err);
        });
    };

    if (
      onDisplayFormEncId &&
      onDisplayFormEncId !== undefined &&
      onDisplayFormEncId.length > 0
    ) {
      fetchData();
      setForceReload(false);
    }
  }, [onDisplayFormEncId, forceReload]);

  useEffect(() => {
    const fetchData = async () => {
      await fetch(
        `${process.env.REACT_APP_API_URL}/forms/api/v1/get-form-by-enc-id?form_id_encid=${onDisplayFormEncId}`,
        {
          credentials: "include",
        }
      )
        .then((response) => {
          if (!response.ok) {
            Bugsnag.notify(
              JSON.stringify({
                status: response.status,
                statusText: response.statusText,
                body: response.body,
              })
            );
          }
          return response.json();
        })
        .then((data) => {
          setCreatedAtTimestamp(data.created_at_timestamp);
          const formSchemaLocal = JSON.parse(data.form_json);
          setFormSchema(formSchemaLocal);
          setUiSchema(data.form_ui_schema);
          setFormUsesApprovalWorkflow(data.use_approval_workflow_flags);
          setFormCustomUrl(data.form_vanity_url);
          setFormTitle(formSchemaLocal.title);
          const tags = data?.board_tags && JSON.parse(data?.board_tags);
          setBoardTags(tags);

          // This is kind of a hack. It ensures that the fields are ordered according to how they appear in the seriaized json string representing the form
          const titles = Object.entries(formSchemaLocal.properties)
            .map((pair: any[]) => {
              return {
                identifier: pair[0],
                title: pair[1].title,
              };
            })
            .map((info: any) => {
              return {
                ...info,
                index: data.form_json.indexOf(info.title),
              };
            })
            .sort(
              (indexedFieldTitleA, indexedFieldTitleB) =>
                indexedFieldTitleA.index - indexedFieldTitleB.index
            )
            .map((entry: any) => entry.identifier);

          setFieldTitleOrder(titles);
          setIsLoadingFormSchema(false);
        })
        .catch((err) => {
          Bugsnag.notify(err);
          console.log(err);
        });
    };
    if (
      onDisplayFormEncId &&
      onDisplayFormEncId !== undefined &&
      onDisplayFormEncId.length > 0
    ) {
      fetchData();
    }
  }, [onDisplayFormEncId, isApproveBtnClicked, isRejectBtnClicked]);

  useEffect(() => {
    const fetchData = async () => {
      await fetch(
        `${process.env.REACT_APP_API_URL}/forms/api/v1/get-schema-to-semantic-title-map?form_id_encid=${onDisplayFormEncId}`,
        {
          credentials: "include",
        }
      )
        .then((response) => {
          if (!response.ok) {
            Bugsnag.notify(
              JSON.stringify({
                status: response.status,
                statusText: response.statusText,
                body: response.body,
              })
            );
          }
          return response.json();
        })
        .then((data) => {
          const identifierToTitleMap = new Map<string, string>();

          if (data) {
            Object.entries(data).forEach((entry: any[]) => {
              identifierToTitleMap.set(entry[0], entry[1]);
            });
          }

          setFormIdentifierToTitleMap(identifierToTitleMap);
        })
        .catch((err) => {
          Bugsnag.notify(err);
          console.log(err);
        });
    };

    if (
      onDisplayFormEncId &&
      onDisplayFormEncId !== undefined &&
      onDisplayFormEncId.length > 0
    ) {
      fetchData();
    }
  }, [onDisplayFormEncId]);

  useEffect(() => {
    if (!deleteConfirmed || idToDelete < 0) {
      return;
    }

    setIsLoading(true);
    fetch(
      `${process.env.REACT_APP_API_URL}/form-responses/api/v1/${idToDelete}`,
      {
        credentials: "include",
        method: "DELETE",
      }
    )
      .then((response) => {
        if (!response.ok) {
          Bugsnag.notify(
            JSON.stringify({
              status: response.status,
              statusText: response.statusText,
              body: response.body,
            })
          );
        }
        setIdToDelete(-1);
        setDeleteConfirmed(false);
        // Remove from frontend state so UI updates without an additional backend call
        setData(
          data.filter(
            (formResponse: any) => formResponse.form_response_id !== idToDelete
          )
        );
        setResponseData(
          data
            .filter(
              (formResponse: any) =>
                formResponse.form_response_id !== idToDelete
            )
            .map((data: any) => {
              return [
                JSON.parse(data.form_data),
                data.meta_data,
                data.form_response_id,
              ];
            })
        );

        setIsLoading(false);
        // Redirect to list view on mobile
        setActiveResponse(0);
      })
      .catch((err) => {
        console.log(err);
        Bugsnag.notify(err);
      });
  }, [deleteConfirmed, idToDelete, responseData, data]);

  useEffect(() => {
    setResponseData(
      data.map((data: any) => {
        return [
          JSON.parse(data.form_data),
          data.meta_data,
          data.form_response_id,
        ];
      })
    );
  }, [data, currentSort]);

  const numFilteredResponses =
    data &&
    data.length > 0 &&
    data
      .sort((person1: any, person2: any) => {
        const sortFunction = getSortFunctionForSort(currentSort);
        return sortFunction(person1, person2);
      })
      .filter((person: any) => {
        if (
          !formUsesApprovalWorkflow ||
          formApprovalStateFilter === ApprovalWorkflowFilter.ALL
        ) {
          return true;
        }
        if (formApprovalStateFilter === ApprovalWorkflowFilter.PENDING) {
          return person.is_approved_flags === null;
        }

        return person.is_approved_flags === formApprovalStateFilter;
      }).length;

  const address = localStorage.getItem("activeAccount") || "";
  const isBoard = isBoardView(address);
  const content = (
    <div className="px-0 sm:px-6 lg:px-8">
      <div className="mx-0 sm:-mx-6 md:mx-0">
        {!isBoard && (
          <FormDetailsNavBar
            formTitle={formTitle}
            onDisplayFormEncId={onDisplayFormEncId}
            formCustomUrl={formCustomUrl}
            createdAtTimestamp={createdAtTimestamp}
          />
        )}
      </div>
      <div className="flex lg:flex-row flex-col-reverse">
        <div className="mt-3 lg:mt-8 flex flex-col lg:w-1/2 lg:pr-4">
          <div className="-my-2 mx-0 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow-md ring-1 ring-black ring-opacity-5 rounded-lg">
                <div className="flex px-5 py-3 text-lg font-semibold text-xl bg-white text-gray-700">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 mr-3"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                    />
                  </svg>{" "}
                  Submissions
                </div>
                {formUsesApprovalWorkflow && (
                  <FormResponseApprovalStatusFilterTabs
                    onChange={(e: ApprovalWorkflowFilter) =>
                      setFormApprovlaStateFilter(e)
                    }
                    currentFromParent={formApprovalStateFilter}
                  />
                )}

                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-white">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-normal text-gray-500 sm:pl-6 hidden lg:block"
                      ></th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-normal text-gray-500 cursor-pointer"
                      >
                        <div
                          className="flex hover:text-gray-700 transition ease-in-out 125"
                          onClick={() => {
                            setCurrentSort(
                              timestampSortAsc
                                ? FormResponseSort.SORT_ASCENDING_SUBMIT_TIME
                                : FormResponseSort.SORT_DESCENDING_SUBMIT_TIME
                            );
                            setTimestampSortAsc(!timestampSortAsc);
                          }}
                        >
                          Submission date{" "}
                          {formUsesApprovalWorkflow && (
                            <SwitchVerticalIcon className="w-4 h-4 ml-1 mt-0.5" />
                          )}
                        </div>
                      </th>
                      {formUsesApprovalWorkflow && (
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-normal text-gray-500 cursor-pointer"
                        >
                          <div
                            className="flex hover:text-gray-700 transition ease-in-out 125"
                            onClick={() => {
                              setCurrentSort(
                                approvalStateSortAsx
                                  ? FormResponseSort.SORT_WAITING_APPROVE_REJECT
                                  : FormResponseSort.SORT_APPROVE_REJECT_WAITING
                              );
                              setApprovalStateSortAsc(!approvalStateSortAsx);
                            }}
                          >
                            Approval status{" "}
                            {formUsesApprovalWorkflow && (
                              <SwitchVerticalIcon className="w-4 h-4 ml-1 mt-0.5" />
                            )}
                          </div>
                        </th>
                      )}
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                      >
                        <span className="sr-only">Delete</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {/* Filter out hidden results -- TODO we should not do this on the frontend at scale */}
                    {data &&
                      data.length > 0 &&
                      data
                        .sort((person1: any, person2: any) => {
                          const sortFunction =
                            getSortFunctionForSort(currentSort);

                          if (
                            person1.form_response_id === responseHeroResponseId
                          ) {
                            return -1;
                          }

                          return sortFunction(person1, person2);
                        })
                        .filter((person: any) => {
                          if (
                            !formUsesApprovalWorkflow ||
                            formApprovalStateFilter ===
                              ApprovalWorkflowFilter.ALL
                          ) {
                            return true;
                          }
                          if (
                            formApprovalStateFilter ===
                            ApprovalWorkflowFilter.PENDING
                          ) {
                            return person.is_approved_flags === null;
                          }

                          return (
                            person.is_approved_flags === formApprovalStateFilter
                          );
                        })
                        .map((person: any, id: number) => (
                          <tr
                            key={person.form_response_id}
                            onClick={() => {
                              setShowEdit({});
                              setActiveResponse(
                                indexOfFormResponseId(
                                  data,
                                  person.form_response_id
                                )
                              );
                            }}
                            className="hover:cursor-pointer hover:bg-gray-100"
                          >
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6  hidden lg:block">
                              {id + 1}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                              {window.innerWidth > 992
                                ? dayjs
                                    .unix(person.timestamp)
                                    .format("MMMM D, YYYY h:mm A")
                                : dayjs
                                    .unix(person.timestamp)
                                    .format("MM/D/YYYY h:mm A")}
                            </td>
                            {formUsesApprovalWorkflow && (
                              <td className="whitespace-nowrap px-3 text-sm text-gray-900">
                                {getApprovalStatusPillByState(
                                  person.is_approved_flags
                                )}
                              </td>
                            )}
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                              <div
                                onClick={() => {
                                  setIdToDelete(person.form_response_id);
                                  setShowDeleteModal(true);
                                }}
                                className="text-gray-900 hover:text-gray-600 hover:underline hover:cursor-pointer"
                              >
                                Delete
                              </div>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {numFilteredResponses > 0 && (
          <div className="mt-8 flex flex-col lg:w-1/2 lg:pr-4">
            <div className="-my-2 mx-0 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden shadow-md ring-1 ring-black ring-opacity-5 rounded-lg">
                  <div className="flex justify-between px-5 py-3 text-lg font-semibold text-xl bg-white text-gray-700">
                    <div className="flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 mr-3"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                        />
                      </svg>
                      Response
                    </div>
                  </div>
                  <div className="px-5 pt-1 pb-5 bg-white text-gray-700 flex justify-between flex-col lg:flex-row">
                    {!isLoadingFormResponses &&
                      data &&
                      data.length > activeResponse &&
                      data[activeResponse].submitter_address && (
                        <div className="mt-1.5">
                          <ShortAddress
                            address={
                              !isLoadingFormResponses &&
                              data &&
                              data.length > activeResponse &&
                              data[activeResponse].submitter_address
                            }
                            avatar={true}
                          />
                        </div>
                      )}
                    {formUsesApprovalWorkflow && (
                      <div className="flex mt-3 lg:mt-0">
                        {data &&
                        data.length > activeResponse &&
                        data[activeResponse].is_approved_flags === null ? (
                          <>
                            {isBoard && (
                              <div className="border-r border-gray-300 pr-6 mr-6 flex flex-col flex-end">
                                <span className="text-sm">
                                  Set proposal status
                                </span>
                                <select
                                  className="rounded-lg border-gray-300"
                                  value={data[activeResponse].board_tag}
                                  onChange={async (e) => {
                                    console.log(e.target.value);
                                    data[activeResponse].board_tag =
                                      e.target.value;
                                    setNewTag(
                                      e.target.value +
                                        Math.floor(Date.now() / 1000)
                                    );
                                    const requestOptions = {
                                      method: "POST",
                                      headers: {
                                        "Content-Type": "application/json",
                                      },
                                      body: JSON.stringify({
                                        formIdEncid: onDisplayFormEncId,
                                        board_tag: e.target.value,
                                        form_response_id:
                                          responseData[activeResponse][2],
                                      }),
                                      credentials:
                                        "include" as RequestCredentials,
                                    };

                                    await fetch(
                                      `${process.env.REACT_APP_API_URL}/form-responses/api/v1/admin-board-tag-edit`,
                                      requestOptions
                                    )
                                      .then((response) => {
                                        if (!response.ok) {
                                          Bugsnag.notify(
                                            JSON.stringify({
                                              status: response.status,
                                              statusText: response.statusText,
                                              body: response.body,
                                            })
                                          );
                                        }
                                        return response.json();
                                      })
                                      .then(() => {})
                                      .catch((err) => {
                                        console.log(err);
                                        Bugsnag.notify(err);
                                      });
                                  }}
                                >
                                  {boardTags.map((tag: any) => {
                                    return <option>{tag}</option>;
                                  })}
                                </select>
                              </div>
                            )}
                            <div className="flex flex-row items-center">
                              <button
                                onClick={() => {
                                  setIsApproveBtnClicked(true);
                                  if (data && data.length > activeResponse) {
                                    setResponseToUpdateApprovalState(
                                      data[activeResponse].form_response_id
                                    );
                                  }
                                }}
                                className="w-28 text-sm px-4 py-2 rounded-lg bg-indigo-500 text-white font-medium mr-2 flex justify-center hover:cursor-pointer hover:bg-indigo-600 ease-in-out duration-125"
                              >
                                {isApproveBtnClicked ? (
                                  <div className="h-5 w-5">
                                    <svg
                                      role="status"
                                      className="inline mr-2 w-5 h-5 text-gray-200 animate-spin fill-indigo-600"
                                      viewBox="0 0 100 101"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                        fill="currentColor"
                                      />
                                      <path
                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                        fill="currentFill"
                                      />
                                    </svg>
                                  </div>
                                ) : (
                                  <>
                                    <CheckIcon className="h-5 w-5 mr-1" />
                                    Approve
                                  </>
                                )}
                              </button>
                              <button
                                onClick={() => {
                                  setIsRejectBtnClicked(true);
                                  if (data && data.length > activeResponse) {
                                    setResponseToUpdateApprovalState(
                                      data[activeResponse].form_response_id
                                    );
                                  }
                                }}
                                className="w-28 text-sm px-4 py-2 rounded-lg bg-indigo-500 text-white font-medium lw-2/3  flex justify-center hover:cursor-pointer hover:bg-indigo-600 ease-in-out duration-125"
                              >
                                {isRejectBtnClicked ? (
                                  <div className="h-5 w-5">
                                    <svg
                                      role="status"
                                      className="inline mr-2 w-5 h-5 text-gray-200 animate-spin fill-indigo-600"
                                      viewBox="0 0 100 101"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                        fill="currentColor"
                                      />
                                      <path
                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                        fill="currentFill"
                                      />
                                    </svg>
                                  </div>
                                ) : (
                                  <>
                                    <XIcon className="h-5 w-5 mr-1.5" />
                                    Reject
                                  </>
                                )}
                              </button>
                            </div>
                          </>
                        ) : (
                          <div className="flex flex-col">
                            <div className="flex flex-end flex-row">
                              {data &&
                                data.length > activeResponse &&
                                getApprovalStatusPillByState(
                                  data[activeResponse].is_approved_flags
                                )}
                            </div>
                            <span
                              onClick={() => {
                                setIsClearApprovalStateBtnClicked(true);
                                if (data && data.length > activeResponse) {
                                  setResponseToUpdateApprovalState(
                                    data[activeResponse].form_response_id
                                  );
                                }
                              }}
                              className="opacity-50 text-sm mt-2 hover:underline cursor-pointer"
                            >
                              Reset approval status
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="border-t border-gray-300 divide-y divide-gray-200 bg-white py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 max-w-2xl break-all">
                    {/* One div == 1 q + a pairing */}
                    {responseData.length > activeResponse &&
                      renderFormResponseFromParsedJson(
                        responseData[activeResponse],
                        fieldTitleOrder,
                        formIdentifierToTitleMap,
                        formSchema,
                        uiSchema,
                        showEdit,
                        function (value: any) {
                          setShowEdit(value);
                        },
                        async function (formDataUpdate: any, questionId: any) {
                          let newFormData = {
                            ...responseData[activeResponse][0],
                          } as any;
                          newFormData[questionId] = formDataUpdate;
                          const requestOptions = {
                            method: "POST",
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify({
                              formIdEncid: onDisplayFormEncId,
                              formData:
                                onDisplayFormEncId ===
                                "eb53c0af-4900-424c-ab45-ce72d94efaaf"
                                  ? JSON.stringify(newFormData)
                                  : StableStringify(newFormData),
                              form_response_id: responseData[activeResponse][2],
                            }),
                            credentials: "include" as RequestCredentials,
                          };

                          await fetch(
                            `${process.env.REACT_APP_API_URL}/form-responses/api/v1/admin-edit`,
                            requestOptions
                          )
                            .then((response) => {
                              if (!response.ok) {
                                Bugsnag.notify(
                                  JSON.stringify({
                                    status: response.status,
                                    statusText: response.statusText,
                                    body: response.body,
                                  })
                                );
                              }
                              return response.json();
                            })
                            .then(() => {
                              setShowEdit({});
                              const newResponseData = [...responseData];
                              newResponseData[activeResponse][0] = newFormData;
                              setResponseData(newResponseData);
                            })
                            .catch((err) => {
                              console.log(err);
                              Bugsnag.notify(err);
                            });
                        },
                        onDisplayFormEncId
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );

  if (onDisplayFormEncId === "") {
    return (
      <AppWrapper>
        <h1 className="font-bold text-center">No form id provided in URL</h1>
      </AppWrapper>
    );
  }

  if (isLoadingFormResponses || isLoadingFormSchema) {
    return (
      <AppWrapper>
        <Spinner />
      </AppWrapper>
    );
  }

  return (
    <AppWrapper>
      <DeleteResponseModal
        open={showDeleteModal}
        onClickHandler={() => setDeleteConfirmed(true)}
        onHide={() => setShowDeleteModal(false)}
      />
      <div className="flex xl:flex-row flex-col justify-between lg:mr-12 mb-1 sm: ml-0 md:ml-6 lg:ml-0">
        <div className="sm:flex sm:items-center border-gray-200">
          <div className="sm:flex-auto">
            <h1 className="text-3xl font-semibold text-gray-900 mt-1 lg:mt-3 lg:ml-8 w-full break-words">
              {formTitle}
            </h1>
            <p className="lg:ml-8 font-medium text-gray-900">
              {data && data.length}
              {data && data.length !== 1 ? " responses" : " response"}
            </p>
          </div>
        </div>
      </div>

      {data && data.length > 0 ? (
        content
      ) : (
        <>
          <div className="px-0 sm:px-6 lg:px-8">
            <div className="mx-0 sm:-mx-6 md:mx-0">
              {!isBoard && (
                <FormDetailsNavBar
                  formTitle={formTitle}
                  onDisplayFormEncId={onDisplayFormEncId}
                  formCustomUrl={formCustomUrl}
                  createdAtTimestamp={createdAtTimestamp}
                />
              )}
            </div>
          </div>
          <div className="w-fit mx-auto font-medium text-md text-gray-500 mt-11">
            Your form doesn't have any responses yet. When it does, they will
            appear here.
          </div>
        </>
      )}
    </AppWrapper>
  );
};

export default FormDetailsPage;
