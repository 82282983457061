import Bugsnag from "@bugsnag/js";
import produce from "immer";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import AppWrapper from "../../components/AppWrapper";
import FormBuilder from "../../components/form/formBuilder/FormBuilder";
import {
  FormConfig,
  POAPType,
  WorkflowName,
} from "../../components/FormBuilderDisclosure";
import Spinner from "../../components/Spinner";

/**
 * Transforms json string into json with properties as an array vs a json object
 * @param formJsonSchema
 */
const transformFormJSONSchemaIntoInternalRepresentation = (
  formJsonSchema: string
) => {
  const formJson = JSON.parse(formJsonSchema);
  return {
    title: formJson.title,
    description: formJson.description,
    type: "object",
    required: formJson.required,
    properties: Object.entries(formJson.properties).map((entry: Array<any>) => {
      console.log("EDIT FORM: ", entry);
      const value = entry[1];
      console.log("EDIT FORM SPREAD: ", { ...value, scema_title: "pizza" });
      return { ...value, schema_title: entry[0] };
      // const baseData = {
      //   title: value.title,
      //   schema_title: entry[0],
      //   type: value.type,
      // };

      // if (value.enum !== undefined) {
      //   return {
      //     ...baseData,
      //     uniqueItems: value.uniqueItems,
      //     enum: value.enum,
      //     enumNames: value.enumNames,
      //   };
      // }

      // if (value.items === undefined) {
      //   return baseData;
      // }

      // return {
      //   ...baseData,
      //   items: value.items,
      //   uniqueItems: value.uniqueItems,
      // };
    }),
  };
};

/**
 * Transforms json string ui schema into array of json objects
 * @param uiSchema
 */
const transformUiSchemaIntoInternalRepresentation = (
  uiSchemaString: string
) => {
  const uiSchema = JSON.parse(uiSchemaString);
  return Object.entries(uiSchema).map((entry: Array<any>) => {
    return {
      schema_title: entry[0],
      ...entry[1],
    };
  });
};

const EditFormPage = () => {
  const [searchParams] = useSearchParams();
  const [onDisplayFormEncId, setOnDisplayFormEncId] = useState("");
  const [uiSchema, setUiSchema] = useState("");
  const [formSchema, setFormSchema] = useState("");
  const [hasFetchedEmailInfo, setHasFetchedEmailInfo] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [emailPref, setEmailPref] = useState("");
  const [shouldUseApprovalWorkflow, setShouldUseApprovalWorkflow] =
    useState(false);
  const [shouldUseAddressGatingWorkflow, setShouldUseAddressGatingWorkflow] =
    useState(false);
  const [shouldUseTokenGatingWorkflow, setShouldUseTokenGatingWorkflow] =
    useState(false);
  // TODO passing around this null config everywhere seems like anti pattern city
  const [formConfig, setFormConfig] = useState<FormConfig>({
    workflows: [],
    emailConfig: {
      email: null,
      shouldEmailOnFormSubmit: false,
    },
    poapConfig: {
      poapType: null,
      mintInfo: "",
    },
    vanity_url: null,
    addressGatingConfig: {
      addressList: "",
    },
    tokenGatingConfig: {
      token: "",
    },
  });
  const [hasConstructedFormConfig, setHasConstructedFormConfig] =
    useState(false);

  const [hasFetchedPOAPInfo, setHasFetchedPOAPInfo] = useState(false);
  const [poapType, setPOAPType] = useState<POAPType | null>(null);
  const [poapMintInfo, setPOAPMintInfo] = useState("");
  const [fetchingPOAPInfo, setFetchingPOAPInfo] = useState(false);
  const [vanityURL, setVanityURL] = useState<string | null>(null);

  const [addressGatingList, setAddressGatingList] = useState("");
  const [tokenGatingList, setTokenGatingList] = useState("");

  useEffect(() => {
    if (onDisplayFormEncId !== searchParams.get("id")) {
      setOnDisplayFormEncId(searchParams.get("id") || "");
    }
  }, [onDisplayFormEncId, searchParams]);

  useEffect(() => {
    const fetchData = async () => {
      await fetch(
        `${process.env.REACT_APP_API_URL}/forms/api/v1/get-form-by-enc-id?form_id_encid=${onDisplayFormEncId}`,
        {
          credentials: "include",
        }
      )
        .then((response) => {
          if (!response.ok) {
            Bugsnag.notify(
              JSON.stringify({
                status: response.status,
                statusText: response.statusText,
                body: response.body,
              })
            );
          }

          return response.json();
        })
        .then((data) => {
          setFormSchema(data.form_json);
          setUiSchema(data.form_ui_schema);
          setShouldUseApprovalWorkflow(data.use_approval_workflow_flags);
          if (data.form_vanity_url) {
            setVanityURL(data.form_vanity_url);
          }
          setShouldUseAddressGatingWorkflow(data.is_address_gating_enabled);
          setShouldUseTokenGatingWorkflow(data.is_token_gating_enabled);
          setAddressGatingList(data.gated_addresses);
          setTokenGatingList(data.gated_token);
        })
        .catch((err) => {
          console.log(err);
          Bugsnag.notify(err);
        });
    };

    if (onDisplayFormEncId.length > 0) {
      fetchData();
    }
  }, [onDisplayFormEncId]);

  // Fetch email info for form config
  useEffect(() => {
    const fetchData = async () => {
      await fetch(
        `${process.env.REACT_APP_API_URL}/email/api/v1/get-prefs-by-form?form_id_encid=${onDisplayFormEncId}`,
        {
          credentials: "include",
        }
      )
        .then((response) => {
          if (!response.ok) {
            Bugsnag.notify(
              JSON.stringify({
                status: response.status,
                statusText: response.statusText,
                body: response.body,
              })
            );
          }

          return response.json();
        })
        .then((data) => {
          setUserEmail(data.email_address ?? "");
          setEmailPref(data.email_policy ?? "");
          setHasFetchedEmailInfo(true);
        })
        .catch((err) => {
          console.log(err);
          Bugsnag.notify(err);
        });
    };
    if (onDisplayFormEncId.length > 0) {
      fetchData();
    }
  }, [onDisplayFormEncId]);

  useEffect(() => {
    const fetchData = async () => {
      await fetch(
        `${process.env.REACT_APP_API_URL}/poap/api/v1?form_id_encid=${onDisplayFormEncId}`,
        {
          credentials: "include",
        }
      )
        .then((response) => {
          if (!response.ok) {
            Bugsnag.notify(
              JSON.stringify({
                status: response.status,
                statusText: response.statusText,
                body: response.body,
              })
            );
          }

          return response.json();
        })
        .then((data) => {
          if (data.poap_type === POAPType.WEBSITE) {
            setPOAPType(POAPType.WEBSITE);
          }
          if (data.poap_type === POAPType.SECRETS) {
            setPOAPType(POAPType.SECRETS);
          }

          if (data.poap_type === POAPType.MINT_LINK) {
            setPOAPType(POAPType.MINT_LINK);
          }

          const mintInfo =
            Object.keys(data).length > 0
              ? JSON.parse(data.mint_information).data
              : "";
          setPOAPMintInfo(mintInfo);

          setHasFetchedPOAPInfo(true);
        })
        .catch((err) => {
          console.log(err);
          Bugsnag.notify(err);
        });
    };

    if (
      !hasFetchedPOAPInfo &&
      !fetchingPOAPInfo &&
      onDisplayFormEncId.length > 0
    ) {
      setFetchingPOAPInfo(true);
      fetchData();
    }
  }, [hasFetchedPOAPInfo, fetchingPOAPInfo, onDisplayFormEncId]);

  // Once everything has loaded ... construct the updated FormConfig
  useEffect(() => {
    const buildFormConfig = () => {
      setFormConfig(
        produce(formConfig, (draftFormConfig) => {
          draftFormConfig.emailConfig.email = userEmail;
          draftFormConfig.emailConfig.shouldEmailOnFormSubmit =
            shouldUseApprovalWorkflow || emailPref === "EVERY_RESPONSE";

          draftFormConfig.workflows = [];

          if (shouldUseAddressGatingWorkflow) {
            draftFormConfig.workflows.push({
              workflowName: WorkflowName.ADDRESS_GATING,
            });
          }

          if (shouldUseTokenGatingWorkflow) {
            draftFormConfig.workflows.push({
              workflowName: WorkflowName.TOKEN_GATING,
            });
          }

          draftFormConfig.addressGatingConfig.addressList = addressGatingList;
          draftFormConfig.tokenGatingConfig.token = tokenGatingList;

          if (shouldUseApprovalWorkflow) {
            draftFormConfig.workflows.push({
              workflowName: WorkflowName.PER_FORM_RESPONSE_APPROVAL,
            });
          }

          if (poapType === POAPType.SECRETS) {
            draftFormConfig.workflows.push({
              workflowName: WorkflowName.POAP_SECRET,
            });
          }

          if (poapType === POAPType.WEBSITE) {
            draftFormConfig.workflows.push({
              workflowName: WorkflowName.POAP_URL,
            });
          }

          if (poapType === POAPType.MINT_LINK) {
            draftFormConfig.workflows.push({
              workflowName: WorkflowName.POAP_MINT_LINKS,
            });
          }

          draftFormConfig.poapConfig.mintInfo = poapMintInfo;
          draftFormConfig.poapConfig.poapType = poapType;

          if (vanityURL) {
            draftFormConfig.vanity_url = vanityURL;
          }
        })
      );

      setTimeout(() => setHasConstructedFormConfig(true), 500); // WOW this is literally so bad I am crying
    };

    if (
      !hasConstructedFormConfig &&
      formSchema.length > 0 &&
      hasFetchedEmailInfo &&
      hasFetchedPOAPInfo
    ) {
      buildFormConfig();
    }
  }, [
    hasConstructedFormConfig,
    formSchema,
    hasFetchedEmailInfo,
    formConfig,
    userEmail,
    shouldUseApprovalWorkflow,
    emailPref,
    hasFetchedPOAPInfo,
    poapType,
    poapMintInfo,
    vanityURL,
    shouldUseAddressGatingWorkflow,
    shouldUseTokenGatingWorkflow,
    addressGatingList,
    tokenGatingList,
  ]);

  if (
    formSchema.length === 0 ||
    uiSchema.length === 0 ||
    !hasConstructedFormConfig ||
    !hasFetchedPOAPInfo
  ) {
    return (
      <AppWrapper>
        <Spinner />
      </AppWrapper>
    );
  }

  console.log(
    "INTERNAL REPRESENTIATON: ",
    transformFormJSONSchemaIntoInternalRepresentation(formSchema)
  );
  console.log(
    "INTERAL REP UI SCHEMA",
    uiSchema,
    transformUiSchemaIntoInternalRepresentation(uiSchema)
  );
  return (
    <AppWrapper>
      <div className="flex w-full xl:w-2/3 max-w-4xl mx-auto">
        <h1 className="text-3xl font-semibold text-gray-900 mt-1 lg:mt-3 w-full break-words">
          Edit Form
        </h1>
      </div>
      <FormBuilder
        formEncId={onDisplayFormEncId}
        formJsonSchema={transformFormJSONSchemaIntoInternalRepresentation(
          formSchema
        )}
        formUiSchema={transformUiSchemaIntoInternalRepresentation(uiSchema)}
        formConfigInit={formConfig}
      />
    </AppWrapper>
  );
};

export default EditFormPage;
