/* eslint-disable no-restricted-globals */
import { useEthers } from "@usedapp/core";
import WalletConnectProvider from "@walletconnect/web3-provider";
import React, { useEffect, useState, useCallback } from "react";
import Spinner from "../../components/Spinner";
import Web3Modal from "web3modal";
import type { JSONSchema7 } from "json-schema";
import { useNavigate, useParams } from "react-router";
import StableStringify from "json-stable-stringify";
import Bugsnag from "@bugsnag/js";
import Form from "@rjsf/material-ui";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { AjvError } from "@rjsf/core";
import { withTheme, utils } from "@rjsf/core";
import { Theme } from "@rjsf/bootstrap-4";

const registry = utils.getDefaultRegistry();
const defaultFileWidget = registry.widgets["FileWidget"];
(Theme as any).widgets["FileWidget"] = defaultFileWidget;
const BootstrapForm = withTheme(Theme);

type PoapMintInformation = {
  mint_information?: string;
  poap_type?: string;
};

const FormResponsePage = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailure, setIsFailure] = useState(false);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [uploadingImages, setUploadingImages] = useState(false);
  const [showImageLoadingIndicator, setShowImageLoadingIndicator] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPoapView, setIsPoapView] = useState(false);
  const [isPoap, setIsPoap] = useState(false);
  const [signedData, setSignedData] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState<any>("");
  const [poapMintInformation, setPoapMintInformation] =
    useState<PoapMintInformation>({});
  const [data, setData] = useState("");
  const { account, library, activate } = useEthers();
  const { id: idFromURL, vanityURL } = useParams();
  const [formExist, setFormExist] = useState(false);

  const [signedMessage, setSignedMessage] = useState<undefined | string>(
    undefined
  );
  const [messageSignatureTimestamp, setMessageSignatureTimestamp] = useState(0);
  const [activateError, setActivateError] = useState("");
  const [uiSchema, setUiSchema] = useState("");

  const schema: JSONSchema7 = data.length > 0 ? JSON.parse(data) : {};
  const isMobile = window.innerWidth < 992;
  const navigate = useNavigate();

  const [isValidationFailure, setIsValidationFailure] = useState(false);
  const [validationFailureReasons, setValidationFailureReasons] = useState<
    string[]
  >([]);
  const [id, setFormId] = useState("");
  const [isResolvingVanityURL, setIsResolvingVanityURL] = useState(
    !(vanityURL === null || vanityURL === undefined || vanityURL.length === 0)
  );

  useEffect(() => {
    if (
      idFromURL !== undefined &&
      (vanityURL === null || vanityURL === undefined || vanityURL.length === 0)
    ) {
      setFormId(idFromURL);
    }
  }, [idFromURL, vanityURL]);

  const [key, setKey] = useState(Date.now());
  const [hasLoadedLocalStorage, setHasLoadedLocalStorage] = useState(false);

  useEffect(() => {
    if (id !== "" && hasLoadedLocalStorage) {
      let formDataWithoutLargeFileData = {...formData}
      Object.keys(formData).forEach((key: string) => {
        if(formDataWithoutLargeFileData[key] && formDataWithoutLargeFileData[key][0]?.file !== undefined){ 
          delete formDataWithoutLargeFileData[key];
        }
      })
      localStorage.setItem(id, JSON.stringify(formDataWithoutLargeFileData));
    }
  }, [formData, hasLoadedLocalStorage, id]);

  useEffect(() => {
    if (id !== "") {
      if (localStorage.getItem(id) !== null) {
        setFormData(JSON.parse(localStorage.getItem(id) || ""));
      }
      setHasLoadedLocalStorage(true);
    }
  }, [id]);

  // Map custom form name to form_id_encid
  useEffect(() => {
    const fetchData = async () => {
      await fetch(
        `${
          process.env.REACT_APP_API_URL
        }/forms/api/v1/resolve-vanity-url?url=${encodeURIComponent(
          vanityURL as string
        )}`,
        {
          credentials: "include",
        }
      )
        .then((response) => {
          if (!response.ok) {
            Bugsnag.notify(
              JSON.stringify({
                status: response.status,
                statusText: response.statusText,
                body: response.body,
              })
            );
          }
          console.log(response);
          return response.json();
        })
        .then((data) => {
          setFormId(data.form_id_encid);
          setIsResolvingVanityURL(false);
        })
        .catch((err) => {
          Bugsnag.notify(err);
          console.log(err);
        });
    };

    if (vanityURL) {
      fetchData();
    }
  }, [vanityURL]);

  useEffect(() => {
    const fetchData = async () => {
      await fetch(
        `${process.env.REACT_APP_API_URL}/forms/api/v1/get-form-by-enc-id?form_id_encid=${id}`,
        {
          credentials: "include",
        }
      )
        .then((response) => {
          if (!response.ok) {
            Bugsnag.notify(
              JSON.stringify({
                status: response.status,
                statusText: response.statusText,
                body: response.body,
              })
            );
          }

          return response.json();
        })
        .then((data) => {
          setIsPoap(data.is_poap_enabled);
          setData(data.form_json);
          setUiSchema(data.form_ui_schema);
        })
        .catch((err) => {
          console.log(err);
          Bugsnag.notify(err);
          navigate("/404");
        });
    };
    if (id && id.length > 0) {
      fetchData();
    }
  }, [id, navigate]);

  const addPoapDataToLocalStorage = useCallback(
    (data: any, signedData, signature) => {
      const localMintInformation = localStorage.getItem("mintInformation");
      if (localMintInformation != null) {
        const parsedMintInformation = JSON.parse(localMintInformation);
        parsedMintInformation.push({
          form_id_encid: id,
          mint_information: data.mint_information,
          poap_type: data.poap_type,
          submitter_address: account,
          signedData: signedData,
          signature: signature,
        });
        localStorage.setItem(
          "mintInformation",
          JSON.stringify(parsedMintInformation)
        );
      } else {
        localStorage.setItem(
          "mintInformation",
          JSON.stringify([
            {
              form_id_encid: id,
              mint_information: data.mint_information,
              poap_type: data.poap_type,
              submitter_address: account,
              signedData: signedData,
              signature: signature,
            },
          ])
        );
      }
    },
    [account, id]
  );

  useEffect(() => {
    async function signData() {
      const unixtime = Math.floor(Date.now() / 1000);
      setMessageSignatureTimestamp(unixtime);
      let formDataToSign = {...formData}
      Object.keys(formData).forEach((key: string) => {
        if(formData[key] && formData[key][0]?.file !== undefined){ 
          formDataToSign[key] = formData[key].map((data: any) => {
            console.log(data?.file.split('=')[1].split(';')[0])
            setUploadingImages(true);
            return data?.file.split(';')[1].split('=')[1]
          })
        }
      })
      let signedData = StableStringify(formDataToSign) + unixtime.toString();

      const signature = await library?.getSigner().signMessage(signedData);
      setSignedData(signedData);
      setSignedMessage(signature);
    }

    if (isLoading && account !== undefined && !isMobile) {
      signData();
    }
  }, [isLoading, account, library, formData, id, isMobile, uiSchema]);

  const mintInfo = JSON.parse(localStorage.getItem("mintInformation") ?? "[]");
  const filteredMintInfo = mintInfo.filter(
    (element: { form_id_encid: string | undefined }) =>
      element.form_id_encid === id
  );

  useEffect(() => {
    if (isLoading && signedMessage && signedData) {
      if(uploadingImages){
        setShowImageLoadingIndicator(true);
      }
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          submitterAddress: account,
          formIdEncid: id,
          formData: StableStringify(formData),
          timestamp: messageSignatureTimestamp,
          signature: signedMessage,
          signedData: signedData,
        }),
      };

      fetch(
        `${process.env.REACT_APP_API_URL}/form-responses/api/v1`,
        requestOptions
      )
        .then((response) => {
          if (!response.ok) {
            setIsFailure(true);
            Bugsnag.notify(
              JSON.stringify({
                status: response.status,
                statusText: response.statusText,
                body: response.body,
              })
            );
          }
          return response.json();
        })
        .then((data) => {
          setSignedMessage("");

          if (!data.success) {
            setIsValidationFailure(true);
            setValidationFailureReasons(data.failure);
          }

          if (data.success) {
            localStorage.removeItem(id);
            setIsSuccess(true);
            if (isPoap) {
              setPoapMintInformation(data.poapInfo);
              addPoapDataToLocalStorage(
                data.poapInfo,
                signedData,
                signedMessage
              );
              setIsPoapView(true);
            }
          }
          if (isPoap && data.poapInfo?.alreadySubmitted) {
            setPoapMintInformation(data.poapInfo);
            addPoapDataToLocalStorage(data.poapInfo, signedData, signedMessage);
            setIsPoapView(true);
          }
          setIsLoading(false);
          setShowImageLoadingIndicator(false);
        })
        .catch((err) => {
          console.log(err);
          Bugsnag.notify(err);
        });
    }
  }, [signedMessage, account, isLoading, messageSignatureTimestamp, formData, id, signedData, isPoap, addPoapDataToLocalStorage, uploadingImages]);

  const signIn = async () => {
    const unixtime = Math.floor(Date.now() / 1000);
    setMessageSignatureTimestamp(unixtime);
    let formDataToSign = {...formData}
      Object.keys(formData).forEach((key: string) => {
        if(formData[key] && formData[key][0]?.file !== undefined){ 
          formDataToSign[key] = formData[key].map((data: any) => {
            console.log(data?.file.split('=')[1].split(';')[0])
            setUploadingImages(true);
            return data?.file.split(';')[1].split('=')[1]
          })
        }
      })
      let signedData = StableStringify(formDataToSign) + unixtime.toString();

    const signature = await library?.getSigner().signMessage(signedData);
    setSignedData(signedData);
    setSignedMessage(signature);
  };

  const activateProvider = async () => {
    const providerOptions = {
      injected: {
        display: {
          name: "Metamask",
          description: "Connect with the provider in your Browser",
        },
        package: null,
      },
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          bridge: "https://bridge.walletconnect.org",
          infuraId: "f74ad8aacd6a44a7a52f4db811276bcd",
        },
      },
    };

    const web3Modal = new Web3Modal({
      providerOptions,
    });
    try {
      const provider = await web3Modal.connect();
      await activate(provider);
      setActivateError("");
    } catch (error: any) {
      setActivateError(error.message);
    }
  };

  const formContentSignIn = (
    <div className="flex justify-center pt-3 mt-3 border-t-2 border-100">
      <Button variant="contained" color="primary" onClick={signIn}>
        Sign Message
      </Button>
    </div>
  );

  const clearForm = (
    <>
      <div className="flex justify-left pt-3 mt-3 border-t-2 border-100">
        <Button
          variant="contained"
          style={{
            background: "transparent",
            boxShadow: "none",
            padding: 0,
            border: 0,
          }}
          onClick={handleClickOpen}
        >
          Clear form
        </Button>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Clear form?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This will clear all data from this form. Any unsubmitted information
            will be lost forever.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={clearCurrentSelections}>Clear form</Button>
        </DialogActions>
      </Dialog>
    </>
  );

  const formContent = (
    <>
      {location.pathname.includes("roko-training-data-v1") ||
      location.pathname.includes("11013d4a-7611-4693-8ab1-7a27634457d6") ||
      (window.innerWidth < 450 &&
        !location.pathname.includes("9e6bc6c2-0f0d-4420-b66e-0d416a5fe73a")) ? (
        <div className="bootstrap">
          <BootstrapForm
            key={key}
            schema={schema}
            children={
              <Button variant="contained" color="primary" type="submit">
                Submit
              </Button>
            }
            formData={formData}
            uiSchema={
              uiSchema && uiSchema.length > 0 ? JSON.parse(uiSchema) : {}
            }
            onChange={(e) => {
              let validationFailure = false;
              let validationReason:string[] = []
              Object.keys(e.formData).forEach((key: string) => {
                if(e.formData[key] && e.formData[key][0]?.file !== undefined){ 
                  e.formData[key].forEach((data: any) => {
                    const fileData = data?.file
                    if(fileData === undefined){
                      return
                    }
                    const fileType = fileData.split(';')[0].split('/')[1];
                    if (!['png', 'jpeg'].includes(fileType)) {
                      validationFailure = true;
                      validationReason.push('File rejected, '+ fileType + ', is not a supported file upload type. Please only upload .png or .jpeg files')
                    }
                  else if(fileData.length >= 30000000){ // Is this right
                      validationFailure = true;
                      validationReason.push('File rejected, file size must be smaller than 30 mb')
                  }
                  })
                }
              })
              if(validationFailure){
                setIsValidationFailure(validationFailure);
                setValidationFailureReasons(validationReason)
              }
              else{
                setValidationFailureReasons([])
              }
              
              setFormData(e.formData)
            }}
            // TODO -- this is fake -- see onClick in submit button
            onSubmit={async () => {
              if (account === undefined) {
                await activateProvider();
              }
              setIsSubmitting(true);
              setIsLoading(true);
            }}
            transformErrors={(errors: AjvError[]) => {
              return errors.map((error: AjvError) => {
                if (error.message === "should be object") {
                  error.message = "Required";
                  error.stack = "1 or more required fields were not specified";
                }

                if (error.message.indexOf("is a required property") >= 0) {
                  error.message = "Required";
                  if (schema !== undefined) {
                    const properties = JSON.parse(data).properties;
                    error.stack = `${
                      properties !== undefined &&
                      properties[error.params.missingProperty].title
                    } is a required property`;
                  }
                }

                return error;
              });
            }}
          />
        </div>
      ) : (

        <Form
          key={key}
          schema={schema}
          formData={formData}
          uiSchema={
            uiSchema && uiSchema.length > 0 ? JSON.parse(uiSchema) : {}
          }
          onChange={(e) => {
            let validationFailure = false;
            let validationReason:string[] = []
            Object.keys(e.formData).forEach((key: string) => {
              if(e.formData[key] && e.formData[key][0]?.file !== undefined){ 
                e.formData[key].forEach((data: any) => {
                  const fileData = data?.file
                  if(fileData === undefined){
                    return
                  }
                  const fileType = fileData.split(';')[0].split('/')[1];
                  if (!['png', 'jpeg'].includes(fileType)) {
                    validationFailure = true;
                    validationReason.push('File rejected, '+ fileType + ', is not a supported file upload type. Please only upload .png or .jpeg files')
                  }
                else if(fileData.length >= 30000000){ // Is this right
                    validationFailure = true;
                    validationReason.push('File rejected, file size must be smaller than 30 mb')
                }
                })
              }
            })
            if(validationFailure){
              setIsValidationFailure(validationFailure);
              setValidationFailureReasons(validationReason)
            }
            else{
              setValidationFailureReasons([])
            }
            setFormData(e.formData)
          }}
          // TODO -- this is fake -- see onClick in submit button
          onSubmit={async () => {
            if (account === undefined) {
              await activateProvider();
            }
            setIsSubmitting(true);
            setIsLoading(true);
            setIsValidationFailure(false);
            setValidationFailureReasons([]);
          }}
          transformErrors={(errors: AjvError[]) => {
            return errors.map((error: AjvError) => {
              if (error.message === "should be object") {
                error.message = "Required";
                error.stack = "1 or more required fields were not specified";
              }

              if (error.message.indexOf("is a required property") >= 0) {
                error.message = "Required";
                if (schema !== undefined) {
                  const properties = JSON.parse(data).properties;
                  error.stack = `${
                    properties !== undefined &&
                    properties[error.params.missingProperty].title
                  } is a required property`;
                }
              }

              return error;
            });
          }}
        />
      )}
    </>
  );

  const poapType =
    filteredMintInfo.length > 0 ? filteredMintInfo[0].poap_type : "";
  const successContent = (
    <>
      <div className="font-medium text-xl text-center mb-4">
        Thank you for your submission!
      </div>
      {isPoap && (
        <div className="border-b border-gray-300 py-4">
          {isValidationFailure &&
            validationFailureReasons.map((d: string) => {
              return (
                <div className="my-1 rounded-lg px-4 py-2 bg-red-100 border border-red-300 text-red-500 font-semibold">
                  {d}
                </div>
              );
            })}
          <div className="font-medium text-md text-center">
            🚀 Claim your POAP! 🚀
          </div>
          <div className="font-bold text-md text-center pt-4">
            Your {poapType === "SECRETS" ? "secret phrase" : "website link"} is{" "}
            {poapType === "SECRETS" ? (
              poapMintInformation.mint_information
            ) : (
              <a
                className="underline text-blue-600 hover:text-blue-700 hover:cursor-pointer"
                href={`${
                  poapMintInformation.mint_information?.startsWith("http")
                    ? ""
                    : "https://"
                }${poapMintInformation.mint_information}`}
                target="_blank"
                rel="noreferrer"
              >
                {poapMintInformation.mint_information}
              </a>
            )}
          </div>
        </div>
      )}
      <div className="font-medium text-md text-center pt-4">
        Want to create your own crypto native form?
        <div
          className="underline text-blue-600 hover:text-blue-700 hover:cursor-pointer"
          onClick={() => {
            window.open(
              "https://app.addressform.io/login?intent=signup",
              "_blank"
            );
            window.location.reload();
          }}
        >
          Get started for free
        </div>
      </div>
    </>
  );

  const failureContent = (
    <>
      <div className="font-semibold text-xl text-center mb-4">
        😰 It's not you, it's us
      </div>
      <div className="font-medium text-md text-center pt-4">
        Don't worry, your response is still saved locally. Please try submitting
        the form again.
      </div>
      <div className="font-normal text-md text-center pt-4 opacity-50">
        Our devs have been notified of this error. Still, if the issue persits
        feel free to contact us via the chat in the lower right hand corner.
      </div>

      <div className="text-center mt-6">
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setIsFailure(false);
            setSignedMessage("");
          }}
        >
          Try again
        </Button>
      </div>
    </>
  );

  useEffect(() => {
    async function doesFormExist(
      form_id_encid: string,
      submitter_address: string,
      signedData: string,
      signature: string | undefined
    ) {
      await fetch(
        `${
          process.env.REACT_APP_API_URL
        }/form-responses/api/v1/does-form-exist?form_id_encid=${form_id_encid}&submitter_address=${submitter_address}&signedData=${encodeURIComponent(
          signedData
        )}&signature=${encodeURIComponent(signature as string)}`,
        {
          credentials: "include",
        }
      )
        .then((response) => {
          if (!response.ok) {
            Bugsnag.notify(
              JSON.stringify({
                status: response.status,
                statusText: response.statusText,
                body: response.body,
              })
            );
          }
          return response.json();
        })
        .then((data) => {
          setFormExist(data);
          if (!data) {
            const localMintInformation = localStorage.getItem(
              "mintInformation"
            ) as string;
            const parsedMintInformation = JSON.parse(localMintInformation);
            const filteredMintInformation = parsedMintInformation.filter(
              (element: any) =>
                !(
                  element.form_id_encid === form_id_encid &&
                  element.submitter_address === submitter_address
                )
            );
            if (filteredMintInformation.length === 0) {
              localStorage.removeItem("mintInformation");
            } else {
              localStorage.setItem(
                "mintInformation",
                JSON.stringify(filteredMintInformation)
              );
            }
          }
        })
        .catch((err) => {
          Bugsnag.notify(err);
          console.log(err);
        });
    }
    async function checkLocalStorage() {
      const localMintInformation = localStorage.getItem("mintInformation");
      const parsedMintInformation = localMintInformation
        ? JSON.parse(localMintInformation)
        : "";
      if (parsedMintInformation) {
        parsedMintInformation.forEach(async (data: any) => {
          if (id === data.form_id_encid && isPoap) {
            if (!formExist) {
              await doesFormExist(
                data.form_id_encid,
                data.submitter_address,
                data.signedData,
                data.signature
              );
            } else {
              setIsPoapView(true);
              setPoapMintInformation(data);
            }
          }
        });
      }
    }
    checkLocalStorage();
  }, [formExist, id, isPoap]);

  function clearCurrentSelections() {
    setFormData("");
    setKey(Date.now());
    setOpen(false);
  }

  return (
    <div className="bg-slate-100 h-fit min-h-screen w-screen lg:px-10 lg:py-10 px-3 py-10">
      {isPoapView ? (
        <>
          {isValidationFailure &&
            validationFailureReasons.map((d: string) => {
              return (
                <div className="my-1 rounded-lg px-4 py-2 bg-red-100 border border-red-300 text-red-500 font-semibold">
                  {d}
                </div>
              );
            })}
          <div className="bg-white p-6 mx-auto w-11/12 lg:w-1/2  max-w-5xl h-fit rounded-lg border border-gray-200">
            <div className="font-semibold text-xl text-center mb-1">
              Claim your POAP! 🚀
            </div>

            <div className="font-bold text-md text-center pt-4">
              {poapType === "MINT_LINKS" &&
              poapMintInformation.mint_information ===
                "All mint links distributed" ? (
                poapMintInformation.mint_information
              ) : (
                <>
                  Your{" "}
                  {poapType === "SECRETS"
                    ? "secret phrase"
                    : "website mint link"}{" "}
                  is{" "}
                  {poapType === "SECRETS" ? (
                    poapMintInformation.mint_information
                  ) : (
                    <a
                      className="underline text-blue-600 hover:text-blue-700 hover:cursor-pointer"
                      href={`${
                        poapMintInformation.mint_information?.startsWith("http")
                          ? ""
                          : "https://"
                      }${poapMintInformation.mint_information}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {poapMintInformation.mint_information}
                    </a>
                  )}
                </>
              )}
            </div>

            <div className="font-medium text-md text-center pt-4 border-t border-gray-300 mt-3 pt-3">
              Want to create your own crypto native form?
              <div
                className="underline text-blue-600 hover:text-blue-700 hover:cursor-pointer"
                onClick={() =>
                  window.open(
                    "https://app.addressform.io/login?intent=signup",
                    "_blank"
                  )
                }
              >
                Get started for free
              </div>
            </div>
          </div>
          <div className="bg-slate-100 py-6 mx-auto w-11/12 lg:w-1/2  max-w-5xl h-fit">
            {isMobile ? (
              <>
                <div className="text-sm text-center px-4 mb-1">
                  Powered by 📝 AddressForm.
                </div>
                <div className="text-sm text-center px-4">
                  The simplest way to create crypto native forms.
                </div>
              </>
            ) : (
              <div className="text-sm text-right">
                Powered by 📝 AddressForm. The simplest way to create crypto
                native forms.
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <div className="bg-white p-6 mx-auto w-11/12 lg:w-1/2  max-w-5xl h-fit rounded-lg border border-gray-200">
            {activateError}
            {isLoading || data.length === 0 || isResolvingVanityURL ? (
              <>
                <Spinner />
                {isSubmitting && !showImageLoadingIndicator ? (
                  <div className="mx-auto w-fit text-md text-gray-500">
                    Waiting for wallet to sign message
                    <br />
                    {isLoading &&
                      account !== undefined &&
                      isMobile &&
                      formContentSignIn}
                  </div>
                ) : (
                  <>
                  {isSubmitting && showImageLoadingIndicator && <div className="mx-auto w-fit text-md text-gray-500">
                    Submitting response, and uploading images, so it may take a bit longer
                    <br />
                  </div>}
                  </>
                )}
              </>
            ) : (
              <>
                {isSuccess && successContent}
                {isFailure && failureContent}
                {isValidationFailure &&
                  validationFailureReasons.map((d: string) => {
                    return (
                      <div className="my-1 rounded-lg px-4 py-2 bg-red-100 border border-red-300 text-red-500 font-semibold">
                        {d}
                      </div>
                    );
                  })}
                {!isSuccess && !isFailure && formContent}
                {!isSuccess && !isFailure && clearForm}
              </>
            )}
            <p className="mt-3 text-sm opacity-50">
              {!isSuccess && !isLoading && !isFailure && (
                <>
                  {
                    "You will be prompted to sign a message (gas free) with your wallet"
                  }
                </>
              )}
            </p>
          </div>
          <div className="bg-slate-100 py-6 mx-auto w-11/12 lg:w-1/2  max-w-5xl h-fit">
            {isMobile ? (
              <>
                <div className="text-sm text-center px-4 mb-1">
                  Powered by 📝 AddressForm.
                </div>
                <div className="text-sm text-center px-4">
                  The simplest way to create crypto native forms.
                </div>
              </>
            ) : (
              <div className="text-sm text-right">
                Powered by 📝 AddressForm. The simplest way to create crypto
                native forms.
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default FormResponsePage;
