import React, { useEffect, useState } from "react";
import FormBuilderInfoSection from "../FormBuilderInfoSection";
import produce from "immer";
import FormBuilderFooter from "../FormBuilderFooter";
import FormBuilderQuestion from "../FormBuilderQuestion";
import { v4 as uuidv4 } from "uuid";
import { postJsonToBackend } from "../../../../utils/postJsonToBackend";
import { useNavigate } from "react-router";
import ConfiguredAlert, {
  ConfiguredAlertVariant,
} from "../../../ConfiguredAlert";
import Bugsnag from "@bugsnag/js";
import { FormConfig, WorkflowName } from "../../../FormBuilderDisclosure";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { BoardConfig } from "../../../BoardBuilderDisclosure";

interface FormBuilderProps {
  // TODO this is horrible we should make a class / type for this
  formJsonSchema?: any;
  formUiSchema?: any[];
  formEncId?: string;
  formConfigInit?: FormConfig;
}

const convertParsedSchemaToValidFormJSONSchemaString = (
  parsedSchema: any,
  deletedQuestionBlocklist: Array<number>
) => {
  return JSON.stringify(
    produce(
      parsedSchema,
      (draftParsedSchema: {
        properties: { [x: string]: unknown; map?: any };
        required: [x: string];
      }) => {
        const deletedSchemaTitles = deletedQuestionBlocklist.map(
          (id: number) => parsedSchema.properties[id].schema_title
        );
        draftParsedSchema.required = parsedSchema.required.filter(
          (schemaTitle: string) => deletedSchemaTitles.indexOf(schemaTitle) < 0
        );

        draftParsedSchema.properties = Object.fromEntries(
          new Map(
            draftParsedSchema.properties
              .map((entry: any) => {
                if (entry.enum !== undefined) {
                  return [
                    entry.schema_title,
                    {
                      title: entry.title,
                      type: entry.type,
                      enum: entry.enum,
                      enumNames: entry.enumNames,
                    },
                  ];
                }

                if (entry.items === undefined) {
                  return [
                    entry.schema_title,
                    {
                      title: entry.title,
                      type: entry.type,
                    },
                  ];
                } else {
                  return [
                    entry.schema_title,
                    {
                      title: entry.title,
                      type: entry.type,
                      items: entry.items,
                      uniqueItems: entry.uniqueItems,
                    },
                  ];
                }
              })
              .filter(
                (_: any, index: number) =>
                  deletedQuestionBlocklist.indexOf(index) < 0
              )
          )
        );
      }
    )
  );
};

const convertParsedUiSchemaToValidJSONString = (
  parsedUiSchema: any,
  deletedUiSchemaQuestionBlocklist: Array<string>
) => {
  return JSON.stringify(
    Object.fromEntries(
      new Map(
        parsedUiSchema
          .map((entry: any) => {
            if (entry.items !== undefined) {
              return [entry.schema_title, { items: entry.items }];
            }

            if (!entry["ui:widget"]) {
              return undefined;
            }

            return [
              entry.schema_title,
              JSON.parse(`{"ui:widget": "${entry["ui:widget"]}"}`),
            ];
          })
          .filter(
            (_: any, index: number) =>
              !deletedUiSchemaQuestionBlocklist.includes(
                parsedUiSchema[index].schema_title
              )
          )
          .filter((entry: any) => entry !== undefined)
      )
    )
  );
};

const FormBuilder: React.FC<FormBuilderProps> = (props) => {
  const { formJsonSchema, formUiSchema, formEncId, formConfigInit } = props;

  const isFormEdit = formJsonSchema !== undefined;

  const [showFromCreateAlert, setShowFormCreateAlert] = useState(false);

  const [formConfig, setFormConig] = useState<FormConfig>(
    formConfigInit || {
      workflows: [],
      emailConfig: {
        email: null,
        shouldEmailOnFormSubmit: false,
      },
      poapConfig: {
        poapType: null,
        mintInfo: "",
      },
      vanity_url: null,
      addressGatingConfig: {
        addressList: "",
      },
      tokenGatingConfig: {
        token: "",
      },
    }
  );

  const [isValidForSubmit, setIsValidFormSubmit] = useState(true);
  const [submitValidationErrorMessage, setSubmitValidationErrorMessage] =
    useState("");

  const [parsedSchema, setParsedSchema] = useState(
    formJsonSchema || {
      title: "",
      description: "",
      type: "object",
      required: [],
      // TODO is there something around making this a list and later on conveting to json
      // I guess we could do that here (with some additional logic I guess) -- and by this I mean converting when we are provided an existing json
      properties: [
        {
          title: "Question 1",
          schema_title: uuidv4(),
          type: "string",
        },
      ],
    }
  );

  const [
    formSchemaInitQuestionSchemaTitles,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setFormSchemaInitQuestionSchemaTitles,
  ] = useState<Array<string>>(
    formJsonSchema
      ? formJsonSchema.properties.map((entry: any) => entry.schema_title)
      : []
  );

  const [parsedUiSchema, setParsedUiSchema] = useState<Array<any>>(
    formUiSchema || []
  );
  const isEditingExistingForm = formJsonSchema !== undefined;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isFailure, setIsFailure] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formId, setFormId] = useState(formEncId || "");
  const navigate = useNavigate();
  const [deletedQuestionBlocklist, setDeletedQuestionBlockList] = useState<
    Array<number>
  >([]);
  const [
    deletedUiSchemaQuestionBlocklist,
    setDeletedUiSchemaQuestionBlocklist,
  ] = useState<Array<string>>([]);

  const validateForm = async () => {
    if (parsedSchema && parsedSchema.title === "") {
      setIsValidFormSubmit(false);
      setSubmitValidationErrorMessage("Forms must have a title");
      return false;
    }

    if (
      formConfig.emailConfig.shouldEmailOnFormSubmit &&
      (formConfig.emailConfig.email === null ||
        formConfig.emailConfig.email?.length === 0)
    ) {
      setIsValidFormSubmit(false);
      setSubmitValidationErrorMessage(
        "Given your config settings, you must provide an email"
      );
      return false;
    }

    const poapFilter = formConfig.workflows.filter(
      (data) =>
        data.workflowName === WorkflowName.POAP_SECRET ||
        data.workflowName === WorkflowName.POAP_URL ||
        data.workflowName === WorkflowName.POAP_MINT_LINKS
    );
    if (
      poapFilter.length > 0 &&
      formConfig?.poapConfig?.mintInfo?.length === 0
    ) {
      setIsValidFormSubmit(false);
      const validationErrorMessage =
        poapFilter[0].workflowName === WorkflowName.POAP_SECRET
          ? "POAP secret word"
          : poapFilter[0].workflowName === WorkflowName.POAP_URL
          ? "POAP mint website URL"
          : "POAP mint links";
      setSubmitValidationErrorMessage(
        "Given your config settings, you must provide a " +
          validationErrorMessage
      );
      return false;
    }

    const addressGating = formConfig.workflows.some(
      (data) => data.workflowName === WorkflowName.ADDRESS_GATING
    );

    if (
      addressGating &&
      formConfig?.addressGatingConfig?.addressList?.length === 0
    ) {
      setIsValidFormSubmit(false);
      setSubmitValidationErrorMessage(
        "Given your config settings, you must provide a list of addresses"
      );
      return false;
    }

    const tokenGating = formConfig.workflows.some(
      (data) => data.workflowName === WorkflowName.TOKEN_GATING
    );

    if (tokenGating && formConfig?.tokenGatingConfig?.token?.length === 0) {
      setIsValidFormSubmit(false);
      setSubmitValidationErrorMessage(
        "Given your config settings, you must provide a token"
      );
      return false;
    }

    console.log("formEncId", formEncId);

    const backendValidation = await postJsonToBackend(
      {
        formConfig: JSON.stringify(formConfig),
        form_id_encid: formEncId,
      },
      "forms/api/v1/validate-form-submission"
    )
      .then((response) => {
        if (!response.ok) {
          Bugsnag.notify(
            JSON.stringify({
              status: response.status,
              statusText: response.statusText,
              body: response.body,
            })
          );
        }

        return response.json();
      })
      .then((data) => {
        if (!data.valid) {
          setIsValidFormSubmit(false);
          setSubmitValidationErrorMessage(data.message);
          return false;
        }
        return true;
      })
      .catch((err) => {
        console.log(err);
        Bugsnag.notify(err);
      });

    console.log(backendValidation);

    if (!backendValidation) {
      return false;
    }

    setIsValidFormSubmit(true);
    return true;
  };

  useEffect(() => {
    if (!isValidForSubmit) {
      setTimeout(() => setIsValidFormSubmit(true), 5000);
    }
  });

  // POST POAP info to backend
  useEffect(() => {
    const postData = async () => {
      postJsonToBackend(
        {
          form_id_encid: formId,
          poap_type: formConfig.poapConfig.poapType,
          poap_mint_information: JSON.stringify({
            data:
              formEncId && formEncId.length
                ? formConfig.poapConfig.mintInfo
                : [formConfig.poapConfig.mintInfo],
          }),
        },
        "poap/api/v1"
      )
        .then((response) => {
          if (!response.ok) {
            Bugsnag.notify(
              JSON.stringify({
                status: response.status,
                statusText: response.statusText,
                body: response.body,
              })
            );
            throw new Error("Error");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    if (
      formConfig.workflows.filter(
        (data) =>
          data.workflowName === WorkflowName.POAP_SECRET ||
          data.workflowName === WorkflowName.POAP_URL ||
          data.workflowName === WorkflowName.POAP_MINT_LINKS
      ).length > 0 &&
      isSuccess &&
      !(formEncId && formEncId.length > 0)
    ) {
      postData();
    }
  }, [
    formConfig.poapConfig.mintInfo,
    formConfig.poapConfig.poapType,
    isSuccess,
    formId,
    formConfig.workflows,
    formEncId,
  ]);

  // Post email settings to backend
  useEffect(() => {
    const postData = async () => {
      postJsonToBackend(
        {
          form_id_encid: formId,
          email_address: formConfig.emailConfig.email,
          email_policy: formConfig.emailConfig.shouldEmailOnFormSubmit
            ? "EVERY_RESPONSE"
            : "NEVER",
        },
        `email/api/v1/${
          formEncId && formEncId.length > 0
            ? "update-email-pref"
            : "add-email-pref"
        }?form_id_encid=${formId ?? formEncId}`
      )
        .then((response) => {
          if (!response.ok) {
            Bugsnag.notify(
              JSON.stringify({
                status: response.status,
                statusText: response.statusText,
                body: response.body,
              })
            );
            throw new Error("Error");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    if (
      formConfig.emailConfig.email &&
      formConfig.emailConfig.email.length > 0 &&
      isSuccess
    ) {
      postData();
    }
  }, [
    formConfig.emailConfig.email,
    formConfig.emailConfig.shouldEmailOnFormSubmit,
    formEncId,
    formId,
    isSuccess,
  ]);

  useEffect(() => {
    async function postData() {
      postJsonToBackend(
        {
          form_json: convertParsedSchemaToValidFormJSONSchemaString(
            parsedSchema,
            deletedQuestionBlocklist
          ),
          form_ui_schema: convertParsedUiSchemaToValidJSONString(
            parsedUiSchema,
            deletedUiSchemaQuestionBlocklist
          ),
          // TODO this is a hack ... if we have more than one workflow this will FAIL
          use_approval_workflow_flags:
            formConfig.workflows.filter(
              (element) =>
                element.workflowName === WorkflowName.PER_FORM_RESPONSE_APPROVAL
            ).length > 0,
          is_poap_enabled: formConfig.poapConfig.poapType !== null,
          form_vanity_url: formConfig.vanity_url,
          is_address_gating_enabled:
            formConfig.addressGatingConfig.addressList !== "",
          gated_addresses: formConfig.addressGatingConfig.addressList,
          is_token_gating_enabled: formConfig.tokenGatingConfig.token !== "",
          gated_token: formConfig.tokenGatingConfig.token,
        },
        `forms/api/v1${formEncId !== undefined ? `/edit?id=${formEncId}` : ""}`
      )
        .then((response) => {
          if (!response.ok) {
            Bugsnag.notify(
              JSON.stringify({
                status: response.status,
                statusText: response.statusText,
                body: response.body,
              })
            );
            throw new Error("Error");
          }

          return response.text();
        })
        .then((formId) => {
          setFormId(formId);
          setIsSubmitting(true);
          setIsSuccess(true);
          setShowFormCreateAlert(true);
          setTimeout(() => {
            navigate(`/app/form?id=${formId}`);
          }, 2000);
        })
        .catch((err) => {
          setIsFailure(true);
          setShowFormCreateAlert(true);
          setTimeout(() => {
            setIsFailure(false);
            setShowFormCreateAlert(false);
          }, 4000);
        });
    }

    if (isSubmitting) {
      postData();
    }
  }, [
    deletedQuestionBlocklist,
    deletedUiSchemaQuestionBlocklist,
    formConfig.addressGatingConfig.addressList,
    formConfig.poapConfig.poapType,
    formConfig.tokenGatingConfig.token,
    formConfig.vanity_url,
    formConfig.workflows,
    formConfig.workflows.length,
    formEncId,
    isSubmitting,
    navigate,
    parsedSchema,
    parsedUiSchema,
  ]);

  console.log("TOP LEVEL FORM CONFIG: ", formConfig);
  console.log("TOP LEVEL FORM SCHEMA", parsedSchema);
  console.log("TOP LEVEL FORM UI SCHEMA", parsedUiSchema);

  return (
    <>
      <ConfiguredAlert
        show={showFromCreateAlert}
        setShow={(e: boolean) => setShowFormCreateAlert(e)}
        variant={
          isSuccess
            ? ConfiguredAlertVariant.SUCCESS
            : ConfiguredAlertVariant.DANGER
        }
        title={isSuccess ? "Success" : "Error"}
        message={
          isSuccess
            ? `Sucessfully ${
                formEncId && formEncId.length > 0 ? "updated" : "created"
              } form.`
            : `Failed to ${
                formEncId && formEncId.length > 0 ? "update" : "create"
              } form.`
        }
      />
      <ConfiguredAlert
        show={!isValidForSubmit}
        setShow={(e: boolean) => setIsValidFormSubmit(!e)}
        variant={ConfiguredAlertVariant.DANGER}
        title={`Error ${
          formEncId && formEncId.length ? "updating" : "creating"
        } form`}
        message={submitValidationErrorMessage}
      />
      <FormBuilderInfoSection
        isFormEdit={formEncId && formEncId.length ? true : false}
        title={parsedSchema.title}
        description={parsedSchema.description}
        vanityURL={formConfig.vanity_url}
        onVanityURLChange={(value: string) => {
          setFormConig(
            produce(formConfig, (draftFormConfig) => {
              draftFormConfig.vanity_url = value;
            })
          );
        }}
        initFormConfig={formConfig}
        onTitleChange={(value: string) =>
          setParsedSchema(
            produce(parsedSchema, (draftParsedSchema: { title: string }) => {
              draftParsedSchema.title = value;
            })
          )
        }
        onDescriptionChange={(value: string) =>
          setParsedSchema(
            produce(
              parsedSchema,
              (draftParsedSchema: { description: string }) => {
                draftParsedSchema.description = value;
              }
            )
          )
        }
        onFormConfigChange={(v: FormConfig | BoardConfig) => setFormConig(v as FormConfig)}
      />
      {/* There will need to be some special logic here to make sure there is a default question  */}

      <DragDropContext
        onDragEnd={(result) => {
          if (!result.destination) {
            return;
          }

          if (result.destination.index === result.source.index) {
            return;
          }

          const reorder = (
            list: Array<any>,
            startIndex: number,
            endIndex: number
          ) => {
            const result = Array.from(list);
            const [removed] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);

            return result;
          };

          setParsedSchema(
            produce(
              parsedSchema,
              (draftParsedSchema: { properties: any[] }) => {
                draftParsedSchema.properties = reorder(
                  [...parsedSchema.properties],
                  result.source.index,
                  result.destination
                    ? result.destination.index
                    : result.source.index
                );
              }
            )
          );
        }}
      >
        <Droppable droppableId="list">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {parsedSchema.properties.map(
                // TODO we currently have a bug bc all questions have the same SCHEMA TITEL AND LITERAL TITLE
                // that being said, we can toggle required state correctly which is dope
                (formElement: any, index: number) => {
                  // TODO this is a disgusting hacking
                  if (deletedQuestionBlocklist.indexOf(index) > -1) {
                    return <></>;
                  }

                  return (
                    <Draggable
                      key={formElement.schema_title}
                      draggableId={formElement.schema_title}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <FormBuilderQuestion
                            isFormEdit={
                              formEncId && formEncId.length ? true : false
                            }
                            formSchemaInit={
                              isFormEdit &&
                              formSchemaInitQuestionSchemaTitles.indexOf(
                                formElement.schema_title
                              ) > -1
                                ? formElement
                                : undefined
                            }
                            uiSchemaInit={
                              isFormEdit &&
                              parsedUiSchema.filter(
                                (data) =>
                                  data.schema_title === formElement.schema_title
                              ).length > 0
                                ? parsedUiSchema.filter(
                                    (data) =>
                                      data.schema_title ===
                                      formElement.schema_title
                                  )[0]
                                : undefined
                            }
                            onFormSchemaChange={
                              // In practice -- should again be schematirzed
                              (e: any) =>
                                setParsedSchema(
                                  produce(
                                    parsedSchema,
                                    (draftParsedSchema: {
                                      properties: { [x: string]: any };
                                    }) => {
                                      draftParsedSchema.properties[index] = {
                                        schema_title: formElement.schema_title,
                                        ...e,
                                      };
                                    }
                                  )
                                )
                            }
                            onUiSchemaChange={
                              // In practice -- should again be schematirzed
                              (e: any) =>
                                setParsedUiSchema(
                                  produce(
                                    parsedUiSchema,
                                    (draftParsedUiSchema) => {
                                      // TODO should we shift to the array based representation here too?
                                      draftParsedUiSchema[index] = {
                                        schema_title: formElement.schema_title,
                                        ...e,
                                      };
                                    }
                                  )
                                )
                            }
                            onDelete={() => {
                              // TODO this is such a fucking hack it's honestly disgusting
                              setDeletedQuestionBlockList(
                                produce(deletedQuestionBlocklist, (draft) => {
                                  draft.push(index);
                                })
                              );
                              // TODO this is such a fucking hack it's honestly disgusting V2
                              setDeletedUiSchemaQuestionBlocklist(
                                produce(
                                  deletedUiSchemaQuestionBlocklist,
                                  (draft) => {
                                    draft.push(
                                      parsedSchema.properties[index]
                                        .schema_title
                                    );
                                  }
                                )
                              );
                            }}
                            canDelete={parsedSchema.properties.length > 0}
                            isRequired={
                              parsedSchema.required.indexOf(
                                formElement.schema_title
                              ) >= 0
                            }
                            onToggleRequired={() =>
                              setParsedSchema(
                                produce(
                                  parsedSchema,
                                  (draftParsedSchema: { required: any[] }) => {
                                    if (
                                      parsedSchema.required.indexOf(
                                        formElement.schema_title
                                      ) >= 0
                                    ) {
                                      draftParsedSchema.required =
                                        draftParsedSchema.required.filter(
                                          (schemaTitle: string) =>
                                            schemaTitle !==
                                            formElement.schema_title
                                        );
                                    } else {
                                      draftParsedSchema.required.push(
                                        formElement.schema_title
                                      );
                                    }
                                  }
                                )
                              )
                            }
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                }
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <FormBuilderFooter
        onAddQuestion={() =>
          setParsedSchema(
            produce(
              parsedSchema,
              (draftParsedSchema: {
                properties: {
                  // NOTE: This means we always default to having short answer questions
                  title: string;
                  type: string;
                  schema_title: string;
                }[];
              }) => {
                draftParsedSchema.properties.push({
                  // NOTE: This means we always default to having short answer questions
                  title: `Question ${draftParsedSchema.properties.length}`,
                  type: "string",
                  schema_title: uuidv4(),
                });
              }
            )
          )
        }
        onSubmit={async () => {
          if (await validateForm()) setIsSubmitting(true);
        }}
        showFormEditCopy={isEditingExistingForm}
      />
    </>
  );
};

export default FormBuilder;
