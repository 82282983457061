/**
 * Util function to make it easy to post JSON to backend with creds
 * @param jsonObject Stringify-able object
 * @param route Backend route we wish to post to
 * @returns Promise<Response> from API
 */
export const postJsonToBackend = async (jsonObject: any, route: string) => {
  return fetch(`${process.env.REACT_APP_API_URL}/${route}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(jsonObject),
    credentials: "include",
  });
};
