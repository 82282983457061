import { useNavigate } from "react-router";
import { BadgeCheckIcon } from "@heroicons/react/solid";

const FormClaimedPage = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-slate-100 h-fit min-h-screen w-screen lg:px-10 lg:py-10 px-3 py-10">
      <div className="text-center mb-6  w-11/12 lg:w-1/2 mx-auto pb-3">
        <div className="flex w-fit mx-auto mb-6">
          <div className="flex-shrink-0 flex items-center hover:cursor-pointer">
            <div className="mx-auto text-3xl text-center">📝</div>
            <h2 className="ml-1 mt-2 text-center text-2xl font-bold">
              AddressForm
            </h2>
          </div>
        </div>

        <div className="text-xl rounded-lg px-4 py-2 bg-green-100 text-green-600 w-fit font-bold shadow-inner mx-auto flex justify-center border border-slate-100">
          <BadgeCheckIcon className="h-6 w-6 mr-1 mt-0.5" />
          Success
        </div>

        <h1 className="text-2xl font-bold mb-5 flex justify-between mx-auto w-fit mt-2">
          Form copied to your account
        </h1>
        <button
          onClick={() => {
            navigate("/app");
            window.location.reload();
          }}
          className="mx-auto px-4 py-2 rounded-lg bg-indigo-500 text-white font-bold max-w-md  mt-3 flex justify-center hover:cursor-pointer hover:bg-indigo-600 ease-in-out duration-125"
        >
          Go to your dashboard
        </button>
      </div>
    </div>
  );
};

export default FormClaimedPage;
