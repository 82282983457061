import clsx from "clsx";
import React from "react";
import { useBillingPlan } from "../../../../hooks/useBillingPlan";
import BoardConfigBuilder, { BoardConfig } from "../../../BoardBuilderDisclosure";
import FormConfigBuilder, { FormConfig } from "../../../FormBuilderDisclosure";
import { SubcriptionPlan } from "../../../SubscriptionPurchaseModal";

interface Props {
  title: string;
  isFormEdit: boolean;
  description: string;
  vanityURL: string | null;
  initFormConfig: FormConfig | BoardConfig;
  onTitleChange: (value: string) => void;
  onDescriptionChange: (value: string) => void;
  onFormConfigChange: (config: FormConfig | BoardConfig) => void;
  onVanityURLChange: (value: string) => void;
  type?: string;
}

const FormBuilderInfoSection: React.FC<Props> = (props) => {
  const {
    title,
    isFormEdit,
    description,
    initFormConfig,
    onTitleChange,
    onDescriptionChange,
    onFormConfigChange,
    vanityURL,
    onVanityURLChange,
    type
  } = props;

  const billingPlan = useBillingPlan();
  const isBillingPlanLoading = billingPlan === "LOADING";
  const hasValidPlan =
    !isBillingPlanLoading &&
    billingPlan &&
    (billingPlan === SubcriptionPlan.DEGEN ||
      billingPlan === SubcriptionPlan.GIGA_BRAIN);

  return (
    <div className="w-full xl:w-2/3 max-w-4xl mx-auto border rounded-lg h-fit mt-6 shadow-md p-5">
      <h2 className="text-xl font-semibold">{`${type === 'board' ? 'Board' : 'Form'} info`}</h2>

      <div className="w-full mt-3">
        <label
          htmlFor="email"
          className="block text-xl font-medium text-gray-700"
        >
          Title
        </label>
        <div className="mt-1">
          <input
            type="text"
            className="text-xl shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 rounded-md"
            placeholder={`My cool ${type === 'board' ? 'board' : 'form'}`}
            onChange={(e) => onTitleChange(e.target.value)}
            value={title}
          />
        </div>
      </div>

      <div className="w-full mt-3">
        <label className="block text-xl font-medium text-gray-700">
          Description (optional)
        </label>
        <div className="mt-1">
          <textarea
            onChange={(e) => onDescriptionChange(e.target.value)}
            value={description}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-xl border-gray-300 rounded-md"
          />
        </div>
      </div>

      <div className="w-full mt-3" hidden={type === 'board'}>
        <label className="block text-xl font-medium text-gray-700">
          Custom URL (optional)
        </label>
        {!hasValidPlan && (
          <p>
            Custom form URLs are only available for Degen or Giga Brain
            customers
          </p>
        )}
        <div className={clsx("mt-1", hasValidPlan ? "" : "opacity-50")}>
          <p className="opacity-75 my-3">
            Will display as{" "}
            <span className="font-mono">{`https://addressform.io/f/${
              vanityURL ? encodeURIComponent(vanityURL) : "YOUR_CUSTOM_URL_HERE"
            }`}</span>
          </p>
          <input
            type="text"
            placeholder="my-cool-form-url"
            onChange={(e) => onVanityURLChange(e.target.value)}
            value={vanityURL ?? ""}
            disabled={!hasValidPlan}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-xl border-gray-300 rounded-md"
          />
        </div>
      </div>
      <br />

      <div className="w-full text-xl ml-0.5">
        <label className="block text-xl font-medium text-gray-700">
          Configuation
        </label>
        {type === 'board' ? (
                  <BoardConfigBuilder
                  isFormEdit={isFormEdit}
                  onFormConfigChange={onFormConfigChange}
                  initFormConfig={initFormConfig as BoardConfig}
                />
                ) : (
                  <FormConfigBuilder
          isFormEdit={isFormEdit}
          onFormConfigChange={onFormConfigChange}
          initFormConfig={initFormConfig as FormConfig}
        />
                )}
      </div>
    </div>
  );
};

export default FormBuilderInfoSection;
