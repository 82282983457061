import Bugsnag from "@bugsnag/js";
import { useEffect, useState } from "react";
import Spinner from "../../../../components/Spinner";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useBillingPlan } from "../../../../hooks/useBillingPlan";
import { SubcriptionPlan } from "../../../../components/SubscriptionPurchaseModal";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface RespondantAnalyticsProps {
  formIdEncid: string;
}

const RespondantAnalytics: React.FC<RespondantAnalyticsProps> = (props) => {
  const { formIdEncid } = props;
  const [walletSummaryData, setWalletSummaryData] = useState<any>(null);
  const [nftCountData, setNftCountData] = useState<any>(null);
  const [isLoadingWalletSummaryData, setIsLoadingWalletSummaryData] =
    useState(true);
  const [isLoadingNftCountData, setIsLoadingNftCountData] = useState(true);

  const billingPlan = useBillingPlan();
  const isBillingPlanLoading = billingPlan === "LOADING";
  const hasValidPlan =
    !isBillingPlanLoading &&
    billingPlan &&
    (billingPlan === SubcriptionPlan.DEGEN ||
      billingPlan === SubcriptionPlan.GIGA_BRAIN);

  useEffect(() => {
    const fetchData = async () => {
      await fetch(
        `${process.env.REACT_APP_API_URL}/form-responses/api/v1/nft-summary-by-wallet?form_id_encid=${formIdEncid}`,
        {
          credentials: "include",
        }
      )
        .then((response) => {
          if (!response.ok) {
            Bugsnag.notify(
              JSON.stringify({
                status: response.status,
                statusText: response.statusText,
                body: response.body,
              })
            );
          }

          return response.json();
        })
        .then((data) => {
          setWalletSummaryData(data);
          setIsLoadingWalletSummaryData(false);
        })
        .catch((err) => {
          Bugsnag.notify(err);
          console.log(err);
        });
    };
    if (hasValidPlan) {
      fetchData();
    }
  }, [formIdEncid, hasValidPlan]);

  useEffect(() => {
    const fetchData = async () => {
      await fetch(
        `${process.env.REACT_APP_API_URL}/form-responses/api/v1/nft-summary-count?form_id_encid=${formIdEncid}`,
        {
          credentials: "include",
        }
      )
        .then((response) => {
          if (!response.ok) {
            Bugsnag.notify(
              JSON.stringify({
                status: response.status,
                statusText: response.statusText,
                body: response.body,
              })
            );
          }

          return response.json();
        })
        .then((data) => {
          setNftCountData(data);
          setIsLoadingNftCountData(false);
        })
        .catch((err) => {
          Bugsnag.notify(err);
          console.log(err);
        });
    };
    if (hasValidPlan) {
      fetchData();
    }
  }, [formIdEncid, hasValidPlan]);

  if (!hasValidPlan) {
    return (
      <>
        <p className="text-md font-normal opacity-50 mb-3 mt-3 mx-auto lg:ml-6">
          You must have a Degen or Giga Brain plan to view this data.
        </p>
      </>
    );
  }

  if (isLoadingNftCountData || isLoadingWalletSummaryData) {
    return (
      <>
        <p className="text-md font-normal opacity-50 mb-3 mt-3 mx-auto lg:ml-6">
          Crunching the numbers. This might take a minute..
        </p>
        <Spinner />
      </>
    );
  }

  const options = {
    indexAxis: "y" as const,
    scales: {
      x: {
        ticks: {
          stepSize: 1,
        },
        gridLines: {
          display: false,
        },
      },
      y: {
        gridLines: {
          display: false,
        },
        ticks: {
          font: {
            size: 14,
            weight: "500",
          },
        },
      },
    },
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "bottom" as const,
      },
    },
  };

  const labelsWalletSummary = walletSummaryData
    .map((d: any) => d.name)
    .slice(0, 10);

  const configWalletSummary = {
    labels: labelsWalletSummary,
    datasets: [
      {
        label: "Respondants",
        data: walletSummaryData.map((d: any) => d.count),
        borderColor: "rgb(100,102,241)",
        backgroundColor: "rgba(100,102,241,0.5)",
      },
    ],
  };

  const labelNftCount = nftCountData.map((d: any) => d.name).slice(0, 10);

  const configNftCount = {
    labels: labelNftCount,
    datasets: [
      {
        label: "Count",
        data: nftCountData.map((d: any) => d.count),
        borderColor: "rgb(100,102,241)",
        backgroundColor: "rgba(100,102,241,0.5)",
      },
    ],
  };

  return (
    <div className="lg:ml-6 max-w-xs lg:max-w-6xl">
      <h1 className="my-4">
        Top 10 most common NFTs held by form respondants (by wallet count)
      </h1>
      <div className="hidden md:flex">
        <Bar options={options} data={configWalletSummary} />
      </div>
      <div className="flex md:hidden">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-white">
            <tr>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-normal text-gray-500 cursor-pointer"
              >
                Collection name
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-normal text-gray-500 cursor-pointer"
              >
                Respondants
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {walletSummaryData &&
              walletSummaryData.slice(0, 10).map((d: any) => {
                return (
                  <tr>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                      {d.name}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                      {d.count}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>

      <h1 className="my-4 border-t border-slate-200 py-8">
        Aggregated NFT holdings on all respondants
      </h1>
      <div className="hidden md:flex">
        <Bar options={options} data={configNftCount} />
      </div>
      <div className="flex md:hidden">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-white">
            <tr>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-normal text-gray-500 cursor-pointer"
              >
                Collection name
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-normal text-gray-500 cursor-pointer"
              >
                Count
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {nftCountData &&
              nftCountData.slice(0, 10).map((d: any) => {
                return (
                  <tr>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                      {d.name}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                      {d.count}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RespondantAnalytics;
