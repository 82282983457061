import { Popover } from "@headlessui/react";
import React from "react";
import Footer from "../LandingPage/components/Footer";
import { useNavigate } from "react-router-dom";
import exampleResponse from "./example.json";

const ApiDocsPage = () => {
  const navigate = useNavigate();

  const nav = (
    <div className="relative bg-white overflow-hidden">
      <div className="relative pt-6 sm:pb-16">
        <Popover>
          <div className="max-w-7xl mx-auto px-4 sm:px-6">
            <nav
              className="relative flex items-center justify-between sm:h-10 md:justify-center"
              aria-label="Global"
            >
              <div className="flex lg:items-center flex-1 md:absolute md:inset-y-0 md:left-0">
                <div className="flex items-center justify-start sm:justify-between w-fit sm:w-full ">
                  <div className="mx-auto text-3xl text-center">📝</div>
                  <h2
                    className="ml-1 mt-2 text-center text-2xl font-bold text-gray-900 hover:cursor-pointer"
                    onClick={() => navigate("/")}
                  >
                    AddressForm
                  </h2>
                </div>
              </div>
              {localStorage.getItem("activeAccount") === undefined && (
                <div className="hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0">
                  <span className="inline-flex rounded-md shadow">
                    <div
                      onClick={() => navigate("/login")}
                      className="inline-flex items-center px-4 py-2 border border-transparent text-base font-semibold rounded-md  bg-indigo-500 text-white bg-white hover:bg-indigo-600 hover:cursor-pointer"
                    >
                      Log in
                    </div>
                  </span>
                </div>
              )}
            </nav>
          </div>
        </Popover>
      </div>
    </div>
  );

  return (
    <>
      {nav}
      <div className="px-4 lg:px-0 mt-2 lg:mt-0 w-full lg:w-2/3 mx-auto">
        <h1 className="text-3xl font-semibold">AddressForm API Docs</h1>

        <p className="mt-1 text-md">
          The AddressForm API allows you to access responses to your forms
          programmatically. This allows you to display form responses on your
          own website (without having to set up your own backend) as well as
          integrate the responses into an automated workflow (for example, a
          discord bot).
        </p>

        <h2 className="text-2xl font-semibold mt-2">Getting an API Key</h2>

        <p className="mt-1">
          The AddressForm API requires your account to have a "Degen" or "Giga
          Brain" plan. You can upgrade your account by visiting our{" "}
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="/pricing"
            className="text-indigo-600 underline hover:text-indigo-400 cursor-pointer"
          >
            pricing page
          </a>
          .
        </p>

        <p className="mt-1">
          From there, go to the nav bar and select "Api Keys".
        </p>

        <p className="mt-1">
          You will be prompted with a few questions to configure your key:
        </p>

        <ol className="list-decimal ml-8 mt-3">
          <li>
            Which forms would you like this key to have access to? You can
            select either all forms or specify a list of forms
          </li>
          <li>
            Which origins is this key allowed to be used from? You can either
            select all or specify a list of approved origins.
          </li>
        </ol>

        <h2 className="text-xl font-semibold mt-2">Using your API key</h2>

        <h2 className="text-md font-semibold mt-2">Base API URL</h2>

        <p className="rounded-lg border border-slate-300 bg-slate-100 font-mono p-4 mt-3">
          https://api.addressform.io/ext-api/v1
        </p>

        <h2 className="text-md font-semibold mt-4">
          {" "}
          <span className="mr-2 rounded-full py-1 px-4 bg-green-600 text-white font-bold">
            GET
          </span>{" "}
          /form-responses
        </h2>

        <p className="rounded-lg border border-slate-300 bg-slate-100 font-mono p-4 mt-3 break-all">
          /form-responses?form_id=
          <span className="font-bold text-indigo-600">$YOUR_FORM_ID</span>
          &api_key=
          <span className="font-bold text-indigo-600">$YOUR_API_KEY</span>
        </p>

        <h2 className="text-md font-semibold mt-2">Query params</h2>

        <div className="rounded-lg border border-slate-300 bg-slate-100 p-4 mt-3">
          <div className="flex">
            <span className="font-mono mr-2">api_key</span>{" "}
            <span className="text-sm font-semibold text-slate-500 mr-2 mt-0.5">
              String
            </span>{" "}
            <span className="mr-2 mt-0.5 text-sm text-red-400">Requied</span>
          </div>
          <p>
            <span className="font-bold text-slate-500 text-sm mr-2">
              Description:{" "}
            </span>{" "}
            Your API key
          </p>
          <br />
          <div className="flex border-t border-slate-300 pt-6">
            <span className="font-mono mr-2">form_id</span>{" "}
            <span className="text-sm font-semibold text-slate-500 mr-2 mt-0.5">
              String
            </span>{" "}
            <span className="mr-2 mt-0.5 text-sm text-red-400">Requied</span>
          </div>
          <p>
            <span className="font-bold text-slate-500 text-sm mt-2 mr-2">
              Description:{" "}
            </span>{" "}
            ID of the form you want to get respones from (in the url after{" "}
            <span className="font-mono">/form</span>)
          </p>

          <br />
          <div className="flex border-t border-slate-300 pt-6">
            <span className="font-mono mr-2">get_all_responses</span>{" "}
            <span className="text-sm font-semibold text-slate-500 mr-2 mt-0.5">
              Boolean
            </span>{" "}
            <span className="mr-2 mt-0.5 text-sm text-blue-400">Optional</span>
          </div>
          <p>
            <span className="font-bold text-slate-500 text-sm mt-2 mr-2">
              Description:{" "}
            </span>{" "}
            By default, if the form is enabled to use the approval workflow only
            approved responses will be returned from this endpoint. If you want
            to return all responses pass{" "}
            <span className="font-mono">get_all_responses=true</span>
          </p>
        </div>

        <h2 className="text-md font-semibold mt-2">Response</h2>

        <div className="rounded-lg border border-slate-300 bg-slate-100 p-4 mt-3">
          <div className="flex font-bold">
            <div
              style={{
                height: "20px",
                width: "20px",
                borderRadius: "100px",
              }}
              className="bg-green-500 mr-1"
            />{" "}
            <span className="-mt-0.5">200</span>
          </div>
          <p>
            <span className="font-bold text-slate-500 text-sm mr-2">
              200 response
            </span>
          </p>

          <p className="font-semibold text-slate-500 text-sm my-2">
            Example response:{" "}
          </p>
          <div className="mt-3 border border-slate-300 bg-slate-200 font-mono text-slate-500 rounded-lg p-4 break-all">
            <pre className="break-all">
              {JSON.stringify(exampleResponse, null, 2)}
            </pre>
          </div>

          <p className="font-bold mt-2 text-slate-500 text-sm">
            Select field descriptions:
          </p>

          <p className="font-normal mt-2 text-slate-900 text-sm flex">
            <span className="font-mono font-bold mr-1">form_id</span> The
            form_id (the id in the URL{" "}
            <span className="font-mono mx-1">/form/:form_id</span>)
          </p>

          <p className="font-normal mt-2 text-slate-900 text-sm flex">
            <span className="font-mono font-bold mr-1">description</span> The
            form description (may be blank if left blank by form creator).
          </p>

          <p className="font-normal mt-2 text-slate-900 text-sm flex">
            <span className="font-mono font-bold mr-1">title</span> Form title.
          </p>

          <p className="font-normal mt-2 text-slate-900 text-sm flex">
            <span className="font-mono font-bold mr-1">responses</span> List of
            responses.
          </p>

          <p className="font-normal mt-2 text-slate-900 text-sm flex">
            <span className="font-mono font-bold mr-1">responses.id</span>{" "}
            Unique id of the form response.
          </p>

          <p className="font-normal mt-2 text-slate-900 text-sm flex">
            <span className="font-mono font-bold mr-1">
              responses.responder_address
            </span>{" "}
            Address of respondant.
          </p>

          <p className="font-normal mt-2 text-slate-900 text-sm flex">
            <span className="font-mono font-bold mr-1">
              responses.timestamp
            </span>{" "}
            Unix timestamp when response was submitted.
          </p>

          <p className="font-normal mt-2 text-slate-900 text-sm flex">
            <span className="font-mono font-bold mr-1">
              responses.raw_response_data
            </span>{" "}
            The raw JSON string of form response data. This is the string signed
            by the user.
          </p>

          <p className="font-normal mt-2 text-slate-900 text-sm flex">
            <span className="font-mono font-bold mr-1">
              responses.signed_raw_response_data
            </span>{" "}
            Signed <span className="font-mono mx-1">raw_response_data</span>.
            Use this along with{" "}
            <span className="font-mono mx-1">raw_response_data</span> and{" "}
            <span className="font-mono mx-1">responder_address</span> to
            validate the result.
          </p>

          <p className="font-normal mt-2 text-slate-900 text-sm flex">
            <span className="font-mono font-bold mr-1">responses.metadata</span>{" "}
            Enriched information about the response. Unless you're an enterprise
            (Giga Brain) client this field shouldn't much matter to you.
          </p>

          <p className="font-normal mt-2 text-slate-900 text-sm flex">
            <span className="font-mono font-bold mr-1">
              response.response_data.prompt
            </span>{" "}
            Question prompted to the user.
          </p>
          <p className="font-normal mt-2 text-slate-900 text-sm flex">
            <span className="font-mono font-bold mr-1">
              response.response_data.response
            </span>{" "}
            Response from user.
          </p>
        </div>

        <p className="mt-6 font-semibold">
          Still have questions? DM us on Twitter{" "}
          <a
            target="_blank"
            rel="noreferrer noopener"
            className="underline text-indigo-600 cursor-pointer hover:text-indigo-400"
            href="https://twitter.com/address_form"
          >
            @address_form
          </a>
        </p>
      </div>
      <Footer />
    </>
  );
};

export default ApiDocsPage;
