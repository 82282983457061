import AppWrapper from "../../components/AppWrapper";
import Breadcrumbs from "../../components/BreadCrumbs";
import ApiKeyPermissionEditor from "../../components/ApiKeyPermissionsEditor";

const GenerateApiKeyPage = () => {
  const pages = [
    {
      name: "Api Keys",
      href: "/app/api-keys",
      current: false,
    },
    {
      name: "Generate Api Key",
      href: "/app/api-keys/create",
      current: true,
    },
  ];

  return (
    <AppWrapper>
      <div className="flex xl:flex-row flex-col justify-between lg:mr-12 mb-1">
        <div className="sm:flex sm:items-center border-gray-200 w-full">
          <div className="sm:flex-auto ml-3 lg:ml-8">
            <div className="flex justify-between w-full">
              <h1 className="text-3xl font-semibold text-gray-900 mt-3 mb-3">
                Generate API Key
              </h1>
            </div>
            <Breadcrumbs pages={pages} />

            <ApiKeyPermissionEditor />
          </div>
        </div>
      </div>
    </AppWrapper>
  );
};

export default GenerateApiKeyPage;
