import { Disclosure, Transition } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import BrandToggle from "../BrandToggle";
import { InformationCircleIcon } from "@heroicons/react/outline";
import ReactTooltip from "react-tooltip";
import classes from "./BoardDisclosure.module.css";
import clsx from "clsx";
import produce from "immer";

export enum WorkflowName {
  PER_FORM_RESPONSE_APPROVAL = "per_form_response_approval",
  POAP_SECRET = "poap_secret",
  POAP_URL = "poap_url",
  POAP_MINT_LINKS = "mink_links",
  ADDRESS_GATING = "address_gating",
  TOKEN_GATING = "token_gating",
  ADDRESS_GATING_POSTS = "address_gating",
  TOKEN_GATING_POSTS = "token_gating",
  ADDRESS_GATING_COMMENTS = "address_gating",
  TOKEN_GATING_COMMENTS = "token_gating",
  ADDRESS_GATING_UPVOTES = "address_gating",
  TOKEN_GATING_UPVOTES = "token_gating",
}

export interface WorkflowInfo {
  workflowName: WorkflowName;
}

export interface EmailConfig {
  email: string | null;
  shouldEmailOnFormSubmit: boolean;
}

export enum POAPType {
  MINT_LINK = "MINT_LINKS",
  SECRETS = "SECRETS",
  WEBSITE = "WEBSITE",
  DEVILERY = "DEVILERY",
}

export interface POAPConfig {
  poapType: POAPType | null;
  mintInfo: string | null;
}

export interface AddressGatingConfig {
  addressList: string;
}

export interface TokenGatingConfig {
  token: string;
}

export interface BoardConfig {
  workflows: WorkflowInfo[];
  emailConfig: EmailConfig;
  poapConfig: POAPConfig;
  vanity_url: string | null;
  addressGatingConfigPosts: AddressGatingConfig;
  tokenGatingConfigPosts: TokenGatingConfig;
  addressGatingConfigComments: AddressGatingConfig;
  tokenGatingConfigComments: TokenGatingConfig;
  addressGatingConfigUpvotes: AddressGatingConfig;
  tokenGatingConfigUpvotes: TokenGatingConfig;
}

interface FormConfigBuilderProps {
  isFormEdit: boolean;
  onFormConfigChange: (config: BoardConfig) => void;
  initFormConfig: BoardConfig;
  type?: string;
}

const BoardConfigBuilder: React.FC<FormConfigBuilderProps> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onFormConfigChange, initFormConfig, isFormEdit, type } = props;

  const [useApprovalWorkflow, setUseApprovalWorkflow] = useState(false);
  const [useSecretWordPOAPWorkflow, setUseSecretWordPOAPWorkflow] =
    useState(false);
  const [useMintURLPOAPWorkflow, setUseMintURLPOAPWorkflow] = useState(false);
  const [useMintLinksPOAPWorkflow, setUseMintLinksPOAPWorkflow] =
    useState(false);
  const [poapSecret, setPOAPSecret] = useState("");
  const [poapWebsite, setPOAPWebsite] = useState("");
  const [poapMintLinks, setPOAPMintLinks] = useState("");
  const [getEmailNotifOnFormSubmit, setGetEmailNotifOnFormSubmit] = useState(
    (initFormConfig.emailConfig.shouldEmailOnFormSubmit &&
      initFormConfig.emailConfig.shouldEmailOnFormSubmit === true) ||
      false
  );
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [userEmail, setUserEmail] = useState("");
  const [formConfig, setFormConfig] = useState<BoardConfig>(initFormConfig);

  const [addressGatingListPosts, setAddressGatingListPosts] = useState("");
  const [tokenGatingPosts, setTokenGatingPosts] = useState("");
  const [useAddressGatingWorkflowPosts, setUseAddressGatingWorkflowPosts] =
    useState(false);
  const [useTokenGatingWorkflowPosts, setUseTokenGatingWorkflowPosts] =
    useState(false);

  const [addressGatingListComments, setAddressGatingListComments] =
    useState("");
  const [tokenGatingComments, setTokenGatingComments] = useState("");
  const [
    useAddressGatingWorkflowComments,
    setUseAddressGatingWorkflowComments,
  ] = useState(false);
  const [useTokenGatingWorkflowComments, setUseTokenGatingWorkflowComments] =
    useState(false);

  const [addressGatingListUpvotes, setAddressGatingListUpvotes] = useState("");
  const [tokenGatingUpvotes, setTokenGatingUpvotes] = useState("");
  const [useAddressGatingWorkflowUpvotes, setUseAddressGatingWorkflowUpvotes] =
    useState(false);
  const [useTokenGatingWorkflowUpvotes, setUseTokenGatingWorkflowUpvotes] =
    useState(false);

  // Set inital values
  useEffect(() => {
    if (initFormConfig.emailConfig.email) {
      setUserEmail(initFormConfig.emailConfig.email);
    }

    if (
      initFormConfig.workflows.filter(
        (element) =>
          element.workflowName === WorkflowName.PER_FORM_RESPONSE_APPROVAL
      ).length > 0
    ) {
      setUseApprovalWorkflow(true);
    }

    // const addressList = initFormConfig.addressGatingConfig.addressList;
    // const token = initFormConfig.tokenGatingConfig.token;

    // if (
    //   initFormConfig.workflows.filter(
    //     (element) => element.workflowName === WorkflowName.ADDRESS_GATING
    //   ).length > 0
    // ) {
    //   setUseAddressGatingWorkflow(true);
    //   setAddressGatingList(addressList ?? "");
    // }

    // if (
    //   initFormConfig.workflows.filter(
    //     (element) => element.workflowName === WorkflowName.TOKEN_GATING
    //   ).length > 0
    // ) {
    //   setUseTokenGatingWorkflow(true);
    //   setTokenGating(token ?? "");
    // }

    const mintInfo = initFormConfig.poapConfig.mintInfo;

    if (
      initFormConfig.workflows.filter(
        (element) => element.workflowName === WorkflowName.POAP_SECRET
      ).length > 0
    ) {
      setUseSecretWordPOAPWorkflow(true);
      setPOAPSecret(mintInfo ?? "");
    }

    if (
      initFormConfig.workflows.filter(
        (element) => element.workflowName === WorkflowName.POAP_URL
      ).length > 0
    ) {
      setUseMintURLPOAPWorkflow(true);
      setPOAPWebsite(mintInfo ?? "");
    }

    if (
      initFormConfig.workflows.filter(
        (element) => element.workflowName === WorkflowName.POAP_MINT_LINKS
      ).length > 0
    ) {
      setUseMintLinksPOAPWorkflow(true);
      setPOAPMintLinks(mintInfo ?? "");
    }
  }, [initFormConfig]);

  useEffect(() => {
    const updatedFormConfig = produce(formConfig, (draftFormConfig) => {
      // Deal with updates to the approval workflow
      draftFormConfig.workflows = [];
      if (useApprovalWorkflow) {
        draftFormConfig.workflows.push({
          workflowName: WorkflowName.PER_FORM_RESPONSE_APPROVAL,
        });
      }

      draftFormConfig.vanity_url = initFormConfig.vanity_url;

      if (useSecretWordPOAPWorkflow) {
        draftFormConfig.workflows.push({
          workflowName: WorkflowName.POAP_SECRET,
        });
      }

      if (useMintURLPOAPWorkflow) {
        draftFormConfig.workflows.push({ workflowName: WorkflowName.POAP_URL });
      }

      if (useMintLinksPOAPWorkflow) {
        draftFormConfig.workflows.push({
          workflowName: WorkflowName.POAP_MINT_LINKS,
        });
      }

      if (useAddressGatingWorkflowPosts) {
        draftFormConfig.workflows.push({
          workflowName: WorkflowName.ADDRESS_GATING_POSTS,
        });
      }

      if (useTokenGatingWorkflowPosts) {
        draftFormConfig.workflows.push({
          workflowName: WorkflowName.TOKEN_GATING_POSTS,
        });
      }

      if (useAddressGatingWorkflowComments) {
        draftFormConfig.workflows.push({
          workflowName: WorkflowName.ADDRESS_GATING_COMMENTS,
        });
      }

      if (useTokenGatingWorkflowComments) {
        draftFormConfig.workflows.push({
          workflowName: WorkflowName.TOKEN_GATING_COMMENTS,
        });
      }

      if (useAddressGatingWorkflowUpvotes) {
        draftFormConfig.workflows.push({
          workflowName: WorkflowName.ADDRESS_GATING_UPVOTES,
        });
      }

      if (useTokenGatingWorkflowUpvotes) {
        draftFormConfig.workflows.push({
          workflowName: WorkflowName.TOKEN_GATING_UPVOTES,
        });
      }

      // Deal with udpates to email
      if (
        isEmailValid &&
        userEmail.length > 0 &&
        userEmail !== formConfig.emailConfig.email
      ) {
        draftFormConfig.emailConfig.email = userEmail;
      }

      // Deal with udpates to email
      draftFormConfig.emailConfig.shouldEmailOnFormSubmit =
        getEmailNotifOnFormSubmit || useApprovalWorkflow;

      let poapType = null;
      let mintInfo = "";
      if (useMintURLPOAPWorkflow) {
        poapType = POAPType.WEBSITE;
        mintInfo = poapWebsite;
      }

      if (useSecretWordPOAPWorkflow) {
        poapType = POAPType.SECRETS;
        mintInfo = poapSecret;
      }

      if (useMintLinksPOAPWorkflow) {
        poapType = POAPType.MINT_LINK;
        mintInfo = poapMintLinks;
      }

      // Deal with updates to POAP info
      draftFormConfig.poapConfig = {
        poapType: poapType,
        mintInfo: mintInfo,
      };

      let addressListPosts = "";
      let tokenPosts = "";

      let addressListComments = "";
      let tokenComments = "";

      let addressListUpvotes = "";
      let tokenUpvotes = "";

      if (useAddressGatingWorkflowPosts) {
        addressListPosts = addressGatingListPosts;
      }

      if (useTokenGatingWorkflowPosts) {
        tokenPosts = tokenGatingPosts;
      }

      if (useAddressGatingWorkflowComments) {
        addressListComments = addressGatingListComments;
      }

      if (useTokenGatingWorkflowComments) {
        tokenComments = tokenGatingComments;
      }

      if (useAddressGatingWorkflowUpvotes) {
        addressListUpvotes = addressGatingListUpvotes;
      }

      if (useTokenGatingWorkflowUpvotes) {
        tokenUpvotes = tokenGatingUpvotes;
      }

      draftFormConfig.addressGatingConfigPosts = {
        addressList: addressListPosts,
      };

      draftFormConfig.tokenGatingConfigPosts = {
        token: tokenPosts,
      };

      draftFormConfig.addressGatingConfigComments = {
        addressList: addressListComments,
      };

      draftFormConfig.tokenGatingConfigComments = {
        token: tokenComments,
      };

      draftFormConfig.addressGatingConfigUpvotes = {
        addressList: addressListUpvotes,
      };

      draftFormConfig.tokenGatingConfigUpvotes = {
        token: tokenUpvotes,
      };
    });

    // TODO wow WTF this is all so hacky it actually hurt
    if (
      updatedFormConfig.emailConfig.email !== formConfig.emailConfig.email ||
      updatedFormConfig.emailConfig.shouldEmailOnFormSubmit !==
        formConfig.emailConfig.shouldEmailOnFormSubmit ||
      updatedFormConfig.workflows.length !== formConfig.workflows.length ||
      updatedFormConfig.poapConfig.mintInfo !==
        formConfig.poapConfig.mintInfo ||
      updatedFormConfig.poapConfig.poapType !==
        formConfig.poapConfig.poapType ||
      updatedFormConfig.addressGatingConfigPosts.addressList !==
        formConfig.addressGatingConfigPosts.addressList ||
      updatedFormConfig.tokenGatingConfigPosts.token !==
        formConfig.tokenGatingConfigPosts.token ||
      updatedFormConfig.addressGatingConfigComments.addressList !==
        formConfig.addressGatingConfigComments.addressList ||
      updatedFormConfig.tokenGatingConfigComments.token !==
        formConfig.tokenGatingConfigComments.token ||
      updatedFormConfig.addressGatingConfigUpvotes.addressList !==
        formConfig.addressGatingConfigUpvotes.addressList ||
      updatedFormConfig.tokenGatingConfigUpvotes.token !==
        formConfig.tokenGatingConfigUpvotes.token
    ) {
      setFormConfig(updatedFormConfig);
      onFormConfigChange(updatedFormConfig);
      console.log("UPDATED INNER FORM CONFIG: ", updatedFormConfig);
    }
  }, [
    isEmailValid,
    userEmail,
    useApprovalWorkflow,
    getEmailNotifOnFormSubmit,
    formConfig,
    onFormConfigChange,
    poapSecret,
    useSecretWordPOAPWorkflow,
    useMintURLPOAPWorkflow,
    poapWebsite,
    useMintLinksPOAPWorkflow,
    poapMintLinks,
    initFormConfig.vanity_url,
    useAddressGatingWorkflowPosts,
    useTokenGatingWorkflowPosts,
    useAddressGatingWorkflowComments,
    useTokenGatingWorkflowComments,
    useAddressGatingWorkflowUpvotes,
    useTokenGatingWorkflowUpvotes,
    addressGatingListPosts,
    tokenGatingPosts,
    addressGatingListComments,
    tokenGatingComments,
    addressGatingListUpvotes,
    tokenGatingUpvotes,
  ]);

  useEffect(() => {
    const validateEmail = (email: string) => {
      return (
        email.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) !== null
      );
    };

    if (validateEmail(userEmail) !== isEmailValid) {
      setIsEmailValid(validateEmail(userEmail));
    }
  }, [isEmailValid, userEmail]);

  return (
    <div className="w-full pt-2">
      <div className="mx-auto w-full">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between bg-white rounded-lg border border-gray-300 px-4 py-2 text-left text-base font-medium text-gray-700 hover:bg-gray-300 focus:outline-none focus-visible:ring focus-visible:ring-indigo-600 focus-visible:ring-opacity-75">
                <span className="flex" data-tip="workflow" data-for="workflow">
                  Workflows{" "}
                  <InformationCircleIcon className="ml-1 h-4 w-4 opacity-70 mt-1" />{" "}
                </span>
                {/* TODO probably generalize this into something more general */}
                <ReactTooltip
                  id={"workflow"}
                  arrowColor={"rgba(0,0,0,0)"}
                  effect={"solid"}
                  className={classes.hover}
                  place={"right"}
                >
                  Workflows are code that automatically triggers after a user
                  submits a form response.
                </ReactTooltip>

                <ChevronUpIcon
                  className={`${
                    open ? "rotate-180 transform" : ""
                  } h-6 w-6 text-gray-700`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-700 bg-white rounded-lg mt-1 border border-gray-300 shaddow-sm h-fit">
                <label className="block text-lg font-medium text-gray-700 mb-1 border-b border-gray-300 pb-2">
                  Workflows
                </label>
                <br />
                <BrandToggle
                  primaryText={"Response approval workflow"}
                  secondaryText={
                    "Get an email after every form response to accept / reject that response. You can filter downstream tasks (like API calls) to only approved responses"
                  }
                  isEnabled={useApprovalWorkflow}
                  onChange={() => {
                    if (!useApprovalWorkflow) {
                      setGetEmailNotifOnFormSubmit(true);
                    }
                    setUseApprovalWorkflow(!useApprovalWorkflow);
                  }}
                  disabled={false}
                />
                <br />
                <div hidden={true}>
                  <BrandToggle
                    primaryText={"POAP secret word workflow"}
                    secondaryText={
                      "Give users a POAP secret word upon successful completion of your form"
                    }
                    isEnabled={useSecretWordPOAPWorkflow}
                    onChange={() => {
                      if (!useSecretWordPOAPWorkflow) {
                        setUseMintURLPOAPWorkflow(false);
                        setUseMintLinksPOAPWorkflow(false);
                      }

                      setUseSecretWordPOAPWorkflow(!useSecretWordPOAPWorkflow);
                    }}
                    disabled={isFormEdit}
                    shouldRespondToUpdatedIsEnabledState={true}
                  />
                  {!isFormEdit ? (
                    <p className="mt-1 opacity-80">
                      <span className="font-bold">Note: </span> You will not be
                      able to edit this value after form creation
                    </p>
                  ) : (
                    <p className="mt-1 opacity-80">
                      <span className="font-bold">Note: </span> You cannot
                      change POAP information after form creation
                    </p>
                  )}
                  <Transition
                    show={useSecretWordPOAPWorkflow}
                    enter="transition-opacity duration-75"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="mt-1 w-full lg:w-1/2">
                      <label className="block text-md font-medium text-gray-700 mt-5 mb-1">
                        POAP secret word
                      </label>
                      <input
                        type="text"
                        className={clsx(
                          "shadow-sm block w-full sm:text-sm rounded-md",
                          "border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                        )}
                        value={poapSecret}
                        onChange={(e: any) => setPOAPSecret(e.target.value)}
                        disabled={isFormEdit}
                      />
                    </div>
                  </Transition>
                  <br />
                  <BrandToggle
                    primaryText={"POAP website/delivery mint workflow"}
                    secondaryText={
                      "Link users to a page where they can mint a POAP upon completion of the form"
                    }
                    isEnabled={useMintURLPOAPWorkflow}
                    onChange={() => {
                      if (!useMintURLPOAPWorkflow) {
                        setUseSecretWordPOAPWorkflow(false);
                        setUseMintLinksPOAPWorkflow(false);
                      }

                      setUseMintURLPOAPWorkflow(!useMintURLPOAPWorkflow);
                    }}
                    disabled={isFormEdit}
                    shouldRespondToUpdatedIsEnabledState={true}
                  />
                  {!isFormEdit ? (
                    <p className="mt-1 opacity-80">
                      <span className="font-bold">Note: </span> You will not be
                      able to edit this value after form creation
                    </p>
                  ) : (
                    <p className="mt-1 opacity-80">
                      <span className="font-bold">Note: </span> You cannot
                      change POAP information after form creation
                    </p>
                  )}
                  <Transition
                    show={useMintURLPOAPWorkflow}
                    enter="transition-opacity duration-75"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="mt-1 w-full lg:w-1/2">
                      <label className="block text-md font-medium text-gray-700 mt-5 mb-1">
                        POAP mint website URL
                      </label>
                      <input
                        // TODO validate that this is a URL
                        type="text"
                        className={clsx(
                          "shadow-sm block w-full sm:text-sm rounded-md",
                          "border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                        )}
                        value={poapWebsite}
                        onChange={(e: any) => setPOAPWebsite(e.target.value)}
                        disabled={isFormEdit}
                      />
                    </div>
                  </Transition>
                  <br />
                  <BrandToggle
                    primaryText={"POAP mint links workflow"}
                    secondaryText={
                      "Link users to a page where they can mint a POAP upon completion of the form"
                    }
                    isEnabled={useMintLinksPOAPWorkflow}
                    onChange={() => {
                      if (!useMintLinksPOAPWorkflow) {
                        setUseSecretWordPOAPWorkflow(false);
                        setUseMintURLPOAPWorkflow(false);
                      }

                      setUseMintLinksPOAPWorkflow(!useMintLinksPOAPWorkflow);
                    }}
                    disabled={isFormEdit}
                    shouldRespondToUpdatedIsEnabledState={true}
                  />
                  {!isFormEdit ? (
                    <p className="mt-1 opacity-80">
                      <span className="font-bold">Note: </span> You will not be
                      able to edit this value after form creation
                    </p>
                  ) : (
                    <p className="mt-1 opacity-80">
                      <span className="font-bold">Note: </span> You cannot
                      change POAP information after form creation
                    </p>
                  )}
                  <Transition
                    show={useMintLinksPOAPWorkflow}
                    enter="transition-opacity duration-75"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="mt-1 w-full lg:pr-4 ">
                      <label className="block text-md font-medium text-gray-700 mt-5 mb-1">
                        POAP mint links
                      </label>
                      <textarea
                        rows={4}
                        className={clsx(
                          "shadow-sm block w-full sm:text-sm rounded-md",
                          "border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                        )}
                        value={poapMintLinks}
                        onChange={(e: any) => setPOAPMintLinks(e.target.value)}
                        disabled={isFormEdit}
                      />
                    </div>
                  </Transition>
                  <br />
                </div>
                <BrandToggle
                  primaryText={"Address Gating Workflow Posts"}
                  secondaryText={
                    "Only allow a select list of addresses to answer your form"
                  }
                  isEnabled={useAddressGatingWorkflowPosts}
                  onChange={() => {
                    setUseAddressGatingWorkflowPosts(
                      !useAddressGatingWorkflowPosts
                    );
                  }}
                  shouldRespondToUpdatedIsEnabledState={true}
                />
                <Transition
                  show={useAddressGatingWorkflowPosts}
                  enter="transition-opacity duration-75"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-150"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="mt-1 w-full lg:w-1/2">
                    <label className="block text-md font-medium text-gray-700 mt-5 mb-1">
                      Address Gating
                    </label>
                    <textarea
                      rows={4}
                      className={clsx(
                        "shadow-sm block w-full sm:text-sm rounded-md",
                        "border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                      )}
                      value={addressGatingListPosts}
                      onChange={(e: any) =>
                        setAddressGatingListPosts(e.target.value)
                      }
                    />
                  </div>
                </Transition>
                <br />
                <BrandToggle
                  primaryText={"ERC 721 Token Gating Workflow Posts"}
                  secondaryText={
                    "Submitting addresses must hold at least one of the provided token"
                  }
                  isEnabled={useTokenGatingWorkflowPosts}
                  onChange={() => {
                    setUseTokenGatingWorkflowPosts(
                      !useTokenGatingWorkflowPosts
                    );
                  }}
                  shouldRespondToUpdatedIsEnabledState={true}
                />
                <Transition
                  show={useTokenGatingWorkflowPosts}
                  enter="transition-opacity duration-75"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-150"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="mt-1 w-full lg:w-1/2">
                    <label className="block text-md font-medium text-gray-700 mt-5 mb-1">
                      Gating contract address
                    </label>
                    <input
                      type="text"
                      className={clsx(
                        "shadow-sm block w-full sm:text-sm rounded-md",
                        "border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                      )}
                      value={tokenGatingPosts}
                      onChange={(e: any) => setTokenGatingPosts(e.target.value)}
                    />
                  </div>
                </Transition>
                <br />
                <BrandToggle
                  primaryText={"Address Gating Workflow Comments"}
                  secondaryText={
                    "Only allow a select list of addresses to answer your form"
                  }
                  isEnabled={useAddressGatingWorkflowComments}
                  onChange={() => {
                    setUseAddressGatingWorkflowComments(
                      !useAddressGatingWorkflowComments
                    );
                  }}
                  shouldRespondToUpdatedIsEnabledState={true}
                />
                <Transition
                  show={useAddressGatingWorkflowComments}
                  enter="transition-opacity duration-75"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-150"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="mt-1 w-full lg:w-1/2">
                    <label className="block text-md font-medium text-gray-700 mt-5 mb-1">
                      Address Gating
                    </label>
                    <textarea
                      rows={4}
                      className={clsx(
                        "shadow-sm block w-full sm:text-sm rounded-md",
                        "border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                      )}
                      value={addressGatingListComments}
                      onChange={(e: any) =>
                        setAddressGatingListComments(e.target.value)
                      }
                    />
                  </div>
                </Transition>
                <br />
                <BrandToggle
                  primaryText={"ERC 721 Token Gating Workflow Comments"}
                  secondaryText={
                    "Submitting addresses must hold at least one of the provided token"
                  }
                  isEnabled={useTokenGatingWorkflowComments}
                  onChange={() => {
                    setUseTokenGatingWorkflowComments(
                      !useTokenGatingWorkflowComments
                    );
                  }}
                  shouldRespondToUpdatedIsEnabledState={true}
                />
                <Transition
                  show={useTokenGatingWorkflowComments}
                  enter="transition-opacity duration-75"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-150"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="mt-1 w-full lg:w-1/2">
                    <label className="block text-md font-medium text-gray-700 mt-5 mb-1">
                      Gating contract address
                    </label>
                    <input
                      type="text"
                      className={clsx(
                        "shadow-sm block w-full sm:text-sm rounded-md",
                        "border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                      )}
                      value={tokenGatingComments}
                      onChange={(e: any) =>
                        setTokenGatingComments(e.target.value)
                      }
                    />
                  </div>
                </Transition>
                <br />
                <BrandToggle
                  primaryText={"Address Gating Workflow Upvotes"}
                  secondaryText={
                    "Only allow a select list of addresses to answer your form"
                  }
                  isEnabled={useAddressGatingWorkflowUpvotes}
                  onChange={() => {
                    setUseAddressGatingWorkflowUpvotes(
                      !useAddressGatingWorkflowUpvotes
                    );
                  }}
                  shouldRespondToUpdatedIsEnabledState={true}
                />
                <Transition
                  show={useAddressGatingWorkflowUpvotes}
                  enter="transition-opacity duration-75"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-150"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="mt-1 w-full lg:w-1/2">
                    <label className="block text-md font-medium text-gray-700 mt-5 mb-1">
                      Address Gating
                    </label>
                    <textarea
                      rows={4}
                      className={clsx(
                        "shadow-sm block w-full sm:text-sm rounded-md",
                        "border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                      )}
                      value={addressGatingListUpvotes}
                      onChange={(e: any) =>
                        setAddressGatingListUpvotes(e.target.value)
                      }
                    />
                  </div>
                </Transition>
                <br />
                <BrandToggle
                  primaryText={"ERC 721 Token Gating Workflow Upvotes"}
                  secondaryText={
                    "Submitting addresses must hold at least one of the provided token"
                  }
                  isEnabled={useTokenGatingWorkflowUpvotes}
                  onChange={() => {
                    setUseTokenGatingWorkflowUpvotes(
                      !useTokenGatingWorkflowUpvotes
                    );
                  }}
                  shouldRespondToUpdatedIsEnabledState={true}
                />
                <Transition
                  show={useTokenGatingWorkflowUpvotes}
                  enter="transition-opacity duration-75"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-150"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="mt-1 w-full lg:w-1/2">
                    <label className="block text-md font-medium text-gray-700 mt-5 mb-1">
                      Gating contract address
                    </label>
                    <input
                      type="text"
                      className={clsx(
                        "shadow-sm block w-full sm:text-sm rounded-md",
                        "border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                      )}
                      value={tokenGatingUpvotes}
                      onChange={(e: any) =>
                        setTokenGatingUpvotes(e.target.value)
                      }
                    />
                  </div>
                </Transition>
                <br />
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        <Disclosure as="div" className="mt-2" defaultOpen>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between bg-white rounded-lg border border-gray-300 px-4 py-2 text-left text-base font-medium text-gray-700 hover:bg-gray-300 focus:outline-none focus-visible:ring focus-visible:ring-indigo-600 focus-visible:ring-opacity-75">
                <span>Notifications</span>
                <ChevronUpIcon
                  className={`${
                    open ? "rotate-180 transform" : ""
                  } h-6 w-6 text-gray-700`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-700 bg-white rounded-lg mt-1 border border-gray-300 shaddow-sm">
                <label className="block text-lg font-medium text-gray-700 mb-1 border-b border-gray-300 pb-2">
                  Email
                </label>
                <br />
                {useApprovalWorkflow ? (
                  <p className="font-medium text-md">
                    You've enabled at least 1 workflow that requires a per
                    response email notification.
                  </p>
                ) : (
                  <BrandToggle
                    primaryText={
                      "Get an email notification upon every form submission"
                    }
                    secondaryText={null}
                    isEnabled={getEmailNotifOnFormSubmit}
                    onChange={() =>
                      setGetEmailNotifOnFormSubmit(!getEmailNotifOnFormSubmit)
                    }
                    disabled={useApprovalWorkflow}
                  />
                )}

                <label className="block text-md font-medium text-gray-700 mt-5 mb-1">
                  Prefered email
                </label>

                <div className="flex justify-between mb-6">
                  <div className="w-full lg:w-1/2">
                    <div className="mt-1">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className={clsx(
                          "shadow-sm block w-full sm:text-sm rounded-md",
                          !isEmailValid && userEmail.length > 0
                            ? "ring-red-500 focus:border-red-500 focus:ring-red-500"
                            : "border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                        )}
                        placeholder={
                          userEmail.length === 0 ? "you@example.com" : userEmail
                        }
                        value={userEmail}
                        onChange={(e: any) => setUserEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
};

export default BoardConfigBuilder;
