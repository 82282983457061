import { XIcon } from "@heroicons/react/outline";
import React from "react";

interface Props {
  updateParentStateHandler: (s: string) => void;
  placeholder: string;
  removeResponseHandler: () => void;
  isFormEdit: boolean;
}

const MultipleChoiceQuestionBuilderResponse: React.FC<Props> = (props) => {
  const {
    updateParentStateHandler,
    placeholder,
    removeResponseHandler,
    isFormEdit,
  } = props;
  const questionResponse = (
    <>
      <div className="mt-4">
        <div className="mt-1 flex">
          {isFormEdit ? (
            <input
              onChange={(e) => updateParentStateHandler(e.target.value)}
              type="text"
              name="event_title"
              id="event_title"
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full lg:w-2/3 text-xl font-semibold border-gray-300 rounded-md"
              value={placeholder}
            />
          ) : (
            <input
              onChange={(e) => updateParentStateHandler(e.target.value)}
              type="text"
              name="event_title"
              id="event_title"
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full lg:w-2/3 text-xl font-semibold border-gray-300 rounded-md"
              placeholder={placeholder}
            />
          )}
          <XIcon
            onClick={removeResponseHandler}
            className="h-6 w-6 text-gray-400 mt-2 hover:cursor-pointer hover:bg-slate-100 hover:text-black ease-in-out duration-125 ml-3 rounded-lg"
          />
        </div>
      </div>
    </>
  );

  return <>{questionResponse}</>;
};

export default MultipleChoiceQuestionBuilderResponse;
