import Hero from "./components/Hero";
import Features from "./components/Features";
import Footer from "./components/Footer";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import Testimonial from "./components/Testimonial";
import PricingSection from "./components/PricingSection";
// import AboutUs from "./components/AboutUs";

const LandingPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("activeAccount")) {
      navigate("/app");
    }
  });

  return (
    <>
      <Hero />
      <Features />
      <Testimonial />
      <PricingSection />
      {/* <AboutUs/> */}
      <Footer />
    </>
  );
};

export default LandingPage;
