/* This example requires Tailwind CSS v2.0+ */
import { useEffect, useState } from "react";
import { Switch } from "@headlessui/react";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

interface BrandToggleProps {
  isEnabled: boolean;
  primaryText: string;
  secondaryText: string | null;
  onChange: () => void;
  disabled?: boolean;
  shouldRespondToUpdatedIsEnabledState?: boolean;
}

const BrandToggle: React.FC<BrandToggleProps> = (props) => {
  const {
    isEnabled,
    primaryText,
    secondaryText,
    onChange,
    disabled,
    shouldRespondToUpdatedIsEnabledState,
  } = props;

  const [enabled, setEnabled] = useState(isEnabled);

  useEffect(() => {
    if (shouldRespondToUpdatedIsEnabledState) {
      setEnabled(isEnabled);
    }
  }, [isEnabled, shouldRespondToUpdatedIsEnabledState]);

  return (
    <Switch.Group as="div" className="flex items-center justify-between">
      <span className="flex-grow flex flex-col">
        <Switch.Label
          as="span"
          className="font-medium text-gray-900 mr-2"
          passive
        >
          {primaryText}
        </Switch.Label>
        {secondaryText && (
          <Switch.Description as="span" className="text-gray-500">
            {secondaryText}
          </Switch.Description>
        )}
      </span>
      <Switch
        checked={enabled}
        disabled={disabled ? disabled : false}
        onChange={() => {
          setEnabled(!enabled);
          onChange();
        }}
        className={classNames(
          enabled ? "bg-indigo-600" : "bg-gray-200",
          "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
          disabled && disabled === true ? "" : "cursor-pointer"
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? "translate-x-5" : "translate-x-0",
            "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
          )}
        />
      </Switch>
    </Switch.Group>
  );
};

export default BrandToggle;
