import { Popover } from "@headlessui/react";
// import { MenuIcon, XIcon } from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Footer from "../LandingPage/components/Footer";
/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from "@heroicons/react/outline";
import Toggle from "../../components/Toggle";
import SubscriptionPurchaseModal, {
  SubcriptionPlan,
  SubscriptionFrequency,
} from "../../components/SubscriptionPurchaseModal";
import { useEthers } from "@usedapp/core";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";

const pricing = {
  tiers: [
    {
      title: "Normie",
      publishedPrice: true,
      monthlyPriceUSD: 29,
      annualPriceUSD: 25,
      frequency: "/ month",
      // description: "The essentials to provide your best work for clients.",
      features: ["100 responses / month"],
      cta: "Monthly billing",
      mostPopular: false,
    },
    {
      title: "Degen",
      publishedPrice: true,
      monthlyPriceUSD: 59,
      annualPriceUSD: 50,
      frequency: "/ month",
      // description: "A plan that scales with your rapidly growing business.",
      features: ["1000 responses / month", "Full API access"],
      cta: "Monthly billing",
      mostPopular: true,
    },
    {
      title: "Giga Brain",
      publishedPrice: false,
      monthlyPriceUSD: 0,
      annualPriceUSD: 0,
      frequency: "/month",
      // description: "Solutions for DAOs, enterprises, and larger organizations",
      features: [
        "Everything in Degen",
        "Unlimited responses per month",
        "Features for teams to manage and analyze responses",
      ],
      cta: "Monthly billing",
      mostPopular: false,
    },
  ],
};

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const PricingPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isAnnualBilling, setIsAnnualBilling] = useState(true);
  const [isUpgradeIntent, setIsUpgradeIntent] = useState(false);
  const [showPurchaseModal, setShowPurchaseModal] = useState(false);

  useEffect(() => {
    const intent = searchParams.get("intent");
    setIsUpgradeIntent(intent === "upgrade");
  }, [searchParams]);

  const [plan, setPlan] = useState<SubcriptionPlan>(SubcriptionPlan.NORMIE);
  const [planCostUSD, setPlanCostUSD] = useState(0);

  const { account, activate } = useEthers();

  const nav = (
    <div className="relative bg-white overflow-hidden">
      <div className="relative pt-6 pb-16 sm:pb-24">
        <Popover>
          <div className="max-w-7xl mx-auto px-4 sm:px-6">
            <nav
              className="relative flex items-center justify-between sm:h-10 md:justify-center"
              aria-label="Global"
            >
              <div className="flex lg:items-center flex-1 md:absolute md:inset-y-0 md:left-0">
                <div className="flex items-center justify-start sm:justify-between w-fit sm:w-full ">
                  <div className="mx-auto text-3xl text-center">📝</div>
                  <h2
                    className="ml-1 mt-2 text-center text-2xl font-bold text-gray-900 hover:cursor-pointer"
                    onClick={() => navigate("/")}
                  >
                    AddressForm
                  </h2>
                  {/* <div className="-mr-2 flex items-center md:hidden">
                    <Popover.Button className="bg-gray-50 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <span className="sr-only">Open main menu</span>
                      <MenuIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div> */}
                </div>
              </div>
              {localStorage.getItem("activeAccount") === undefined && (
                <div className="hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0">
                  <span className="inline-flex rounded-md shadow">
                    <div
                      onClick={() => navigate("/login")}
                      className="inline-flex items-center px-4 py-2 border border-transparent text-base font-semibold rounded-md  bg-indigo-500 text-white bg-white hover:bg-indigo-600 hover:cursor-pointer"
                    >
                      Log in
                    </div>
                  </span>
                </div>
              )}
            </nav>
          </div>
          {/* 
          <Transition
            as={Fragment}
            enter="duration-150 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
            >
              <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="px-5 pt-4 flex items-center justify-between">
                  <div>
                    <div className="mx-auto text-3xl text-center">📝</div>
                    <h2 className="ml-1 mt-2 text-center text-2xl font-bold text-gray-900">
                      AddressForm
                    </h2>
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <span className="sr-only">Close menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div
                  onClick={() => navigate("/login")}
                  className="block w-full px-5 py-3 text-center font-medium text-indigo-400 bg-gray-50 hover:bg-gray-100"
                >
                  Log in
                </div>
              </div>
            </Popover.Panel>
          </Transition> */}
        </Popover>
      </div>
    </div>
  );

  const activateProvider = async () => {
    const providerOptions = {
      injected: {
        display: {
          name: "Metamask",
          description: "Connect with the provider in your Browser",
        },
        package: null,
      },
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          bridge: "https://bridge.walletconnect.org",
          infuraId: "f74ad8aacd6a44a7a52f4db811276bcd",
        },
      },
    };

    const web3Modal = new Web3Modal({
      providerOptions,
    });
    try {
      const provider = await web3Modal.connect();
      await activate(provider);
      setShowPurchaseModal(true);
    } catch (error: any) {
      alert(error.message);
    }
  };

  const content = (
    <>
      <div className="max-w-7xl mx-auto pb-8 px-4 bg-white sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold text-gray-900 sm:text-5xl sm:leading-none sm:tracking-tight lg:text-6xl">
          Pricing plans for projects of all sizes
        </h2>

        <p className="text-gray-500 mt-3 text-lg">
          Just getting started? Get 30 responses / month free forever with our{" "}
          <span
            onClick={() => navigate("/login?intent=signup")}
            className="underline text-indigo-500 cursor-pointer hover:text-indigo-600"
          >
            free tier.
          </span>
        </p>

        {/* Annual vs. monthly billing */}
        <div className="w-full mt-9 flex justify-center">
          <span className="font-bold mr-3">Monthly</span>
          <Toggle
            isEnabled={isAnnualBilling}
            onChange={() => setIsAnnualBilling(!isAnnualBilling)}
          />
          <span className="font-bold mr-1 ml-3">Yearly</span>
          <span>(save 16%)</span>
        </div>

        {/* Tiers */}
        <div className="mt-24 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-8">
          {pricing.tiers.map((tier) => (
            <div
              key={tier.title}
              className="relative p-8 bg-white border border-gray-200 rounded-2xl shadow-sm flex flex-col"
            >
              <div className="flex-1">
                <h3 className="text-xl font-semibold text-gray-900">
                  {tier.title}
                </h3>
                {tier.mostPopular ? (
                  <p className="absolute top-0 py-1.5 px-4 bg-indigo-500 rounded-full text-xs font-semibold uppercase tracking-wide text-white transform -translate-y-1/2">
                    Most popular
                  </p>
                ) : null}

                {tier.publishedPrice ? (
                  <>
                    <p className="mt-4 flex items-baseline text-gray-900">
                      <span className="text-5xl font-extrabold tracking-tight">
                        $
                        {isAnnualBilling
                          ? tier.annualPriceUSD
                          : tier.monthlyPriceUSD}
                      </span>
                      <span className="ml-1 text-xl font-semibold">
                        {tier.frequency}
                      </span>
                    </p>
                    <p className="mt-2 text-sm text-gray-400">
                      Paid in ETH at current exchange rate
                    </p>
                  </>
                ) : (
                  <p className="mt-4 flex items-baseline text-gray-900">
                    <span className="text-3xl font-bold mt-3">Contact us</span>
                  </p>
                )}

                {/* Feature list */}
                <ul className="mt-6 space-y-6">
                  {tier.features.map((feature) => (
                    <li key={feature} className="flex">
                      <CheckIcon
                        className="flex-shrink-0 w-6 h-6 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-3 text-gray-500">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>

              <div
                onClick={() => {
                  if (tier.title === "Giga Brain") {
                    // eslint-disable-next-line no-restricted-globals
                    location.href = "mailto:hello@addressform.io";
                    return;
                  }

                  if (tier.title === SubcriptionPlan.DEGEN) {
                    setPlan(SubcriptionPlan.DEGEN);
                    setPlanCostUSD(
                      isAnnualBilling
                        ? 12 * tier.annualPriceUSD
                        : tier.monthlyPriceUSD
                    );
                  } else {
                    setPlan(SubcriptionPlan.NORMIE);
                    setPlanCostUSD(
                      isAnnualBilling
                        ? 12 * tier.annualPriceUSD
                        : tier.monthlyPriceUSD
                    );
                  }

                  if (account === undefined) {
                    activateProvider();
                  } else {
                    setShowPurchaseModal(true);
                  }
                }}
                className={classNames(
                  tier.mostPopular
                    ? "bg-indigo-500 text-white hover:bg-indigo-600"
                    : "bg-indigo-50 text-indigo-700 hover:bg-indigo-100",
                  "mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium hover:cursor-pointer"
                )}
              >
                {tier.title === "Giga Brain" ? (
                  <>Contact sales</>
                ) : (
                  <>
                    {isUpgradeIntent ? "Upgrade to" : "Get"} {tier.title}{" "}
                    {isAnnualBilling ? "yearly" : "monthly"}
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );

  return (
    <>
      <SubscriptionPurchaseModal
        open={showPurchaseModal}
        setOpen={(e: boolean) => setShowPurchaseModal(e)}
        plan={plan}
        frequency={
          isAnnualBilling
            ? SubscriptionFrequency.YEARLY
            : SubscriptionFrequency.MONTHLY
        }
        planCostUSD={planCostUSD}
      />
      {nav}
      {content}
      <Footer />
    </>
  );
};

export default PricingPage;
