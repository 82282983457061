import { useSearchParams } from "react-router-dom";
import AppWrapper from "../../components/AppWrapper";
import Breadcrumbs from "../../components/BreadCrumbs";
import ApiKeyPermissionEditor from "../../components/ApiKeyPermissionsEditor";
import Spinner from "../../components/Spinner";

const AppEditKeyPermissionsPage = () => {
  const [searchParams] = useSearchParams();
  const pages = [
    {
      name: "Api Keys",
      href: "/app/api-keys",
      current: false,
    },
    {
      name: "View / Edit Api Key Permissions",
      href: `/app/api-keys/edit?key=${searchParams.get("key") || ""}`,
      current: true,
    },
  ];

  if (!searchParams.get("key")) {
    return (
      <AppWrapper>
        <Spinner />
      </AppWrapper>
    );
  }

  return (
    <AppWrapper>
      <div className="flex xl:flex-row flex-col justify-between lg:mr-12 mb-1">
        <div className="sm:flex sm:items-center border-gray-200 w-full">
          <div className="sm:flex-auto ml-3 lg:ml-8">
            <div className="flex justify-between w-full">
              <h1 className="text-3xl font-semibold text-gray-900 mt-3 mb-3">
                View / Edit API Key Permissions
              </h1>
            </div>
            <Breadcrumbs pages={pages} />

            <ApiKeyPermissionEditor
              isEdit={true}
              apiKeyToEdit={searchParams.get("key") || ""}
            />
          </div>
        </div>
      </div>
    </AppWrapper>
  );
};

export default AppEditKeyPermissionsPage;
