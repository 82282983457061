import { useEffect, useState } from "react";

const tabs = [{ name: "Response analytics" }, { name: "Address analytics" }];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface FormAnalyticsPageTabsProps {
  onChange: (v: boolean) => void;
  currentFromParent: boolean;
}

const FormAnalyticsPageTabs: React.FC<FormAnalyticsPageTabsProps> = (props) => {
  const { onChange, currentFromParent } = props;

  const [currentTab, setCurrentTab] = useState(
    currentFromParent ? tabs[0].name : tabs[1].name
  );
  const current = tabs.find((tab) => tab.name === currentTab);

  useEffect(() => {
    if (currentTab === tabs[0].name) {
      onChange(true);
    } else {
      onChange(false);
    }
  }, [currentTab, onChange]);

  return (
    <div className="bg-white w-5/6 lg:w-full">
      <div className="lg:ml-6 sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          defaultValue={current ? current.name : ""}
          onChange={(e: any) => setCurrentTab(e.target.value)}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="ml-6 hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <div
                key={tab.name}
                onClick={() => setCurrentTab(tab.name)}
                className={classNames(
                  tab.name === currentTab
                    ? "border-indigo-500 text-indigo-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                  "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer"
                )}
                aria-current={tab.name === currentTab ? "page" : undefined}
              >
                {tab.name}
              </div>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default FormAnalyticsPageTabs;
