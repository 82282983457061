import { useEffect, useState } from "react";
import FormBuilderMultipleChoiceQuestionResponse from "../MultipleChoiceQuestionBuilderResponse";
import produce from "immer";

interface Props {
  onFormSchemaChange: (e: any) => void;
  onUiSchemaChange: (e: any) => void;
  formSchemaInit?: any;
}

const MultipleChoiceQuestionBuilder: React.FC<Props> = (props) => {
  const { onFormSchemaChange, onUiSchemaChange, formSchemaInit } = props;
  const isFormSchemaInitItemsValid =
    formSchemaInit !== undefined &&
    formSchemaInit &&
    formSchemaInit.enumNames !== undefined;
  const [choiceText, setChoiceText] = useState<Array<string>>(
    isFormSchemaInitItemsValid ? formSchemaInit.enumNames : ["Option 1"]
  );
  const [formSchemaFragment, setFormSchemaFragment] = useState({
    type: "string",
    enumNames: isFormSchemaInitItemsValid
      ? formSchemaInit.enumNames
      : new Array<string>(),
    enum: isFormSchemaInitItemsValid
      ? formSchemaInit.enum
      : new Array<string>(),
    uniqueItems: true,
  });

  useEffect(() => {
    setFormSchemaFragment(
      produce(formSchemaFragment, (draftFormSchemaFragment) => {
        draftFormSchemaFragment.enumNames = choiceText;
        draftFormSchemaFragment.enum = choiceText.map((value: string) => {
          return value;
        });
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choiceText]);

  useEffect(() => {
    onFormSchemaChange(formSchemaFragment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSchemaFragment]);

  useEffect(() => {
    onUiSchemaChange(JSON.parse('{"ui:widget": "select"}'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log("MULTIPLE CHOICE FORM SCHEMA INIT:", formSchemaInit);
  return (
    <div>
      {choiceText.map((text: string, index: number) => {
        return (
          <FormBuilderMultipleChoiceQuestionResponse
            placeholder={text}
            isFormEdit={isFormSchemaInitItemsValid}
            updateParentStateHandler={(value: string) =>
              setChoiceText(
                produce(choiceText, (draftChoiceText) => {
                  draftChoiceText[index] = value;
                })
              )
            }
            removeResponseHandler={() => {
              setChoiceText(
                choiceText.filter(
                  (elm: any, innerIndex: number) => innerIndex !== index
                )
              );
            }}
          />
        );
      })}
      <button
        className="mt-2 ml-1 underline opacity-50 hover:cursor-pointer ease-in-out duration-125 hover:opacity-100"
        onClick={() =>
          setChoiceText(
            produce(choiceText, (draftChoiceText) => {
              draftChoiceText.push("");
            })
          )
        }
      >
        Add another response
      </button>
    </div>
  );
};

export default MultipleChoiceQuestionBuilder;
