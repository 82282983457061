/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useCoingeckoPrice } from "@usedapp/coingecko";
import Spinner from "../Spinner";
import { useEthers, useSendTransaction } from "@usedapp/core";
import { useNavigate } from "react-router";
import { utils } from "ethers";
import { postJsonToBackend } from "../../utils/postJsonToBackend";
import Bugsnag from "@bugsnag/js";

export enum SubcriptionPlan {
  NORMIE = "Normie",
  DEGEN = "Degen",
  GIGA_BRAIN = "Giga Brain",
}

export enum SubscriptionFrequency {
  YEARLY = "Yearly",
  MONTHLY = "Monthly",
}

interface Props {
  open: boolean;
  setOpen: (e: boolean) => void;
  plan: SubcriptionPlan;
  previousPlan?: SubcriptionPlan;
  frequency: SubscriptionFrequency;
  previousFrequency?: SubscriptionFrequency;
  planCostUSD: number;
  previousPlanCostUSD?: number;
}

const SubscriptionPurchaseModal: React.FC<Props> = (props) => {
  const {
    open,
    setOpen,
    plan,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // previousPlan,
    frequency,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // previousFrequency,
    planCostUSD,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // previousPlanCostUSD,
  } = props;

  const navigate = useNavigate();
  const cancelButtonRef = useRef(null);
  // TODO change this nam
  const etherPrice = Number(useCoingeckoPrice("ethereum", "usd"));
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailure, setIsFailure] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessageText, setErrorMessageText] = useState("");
  const [transationHash, setTransactionHash] = useState("");
  const { sendTransaction, state } = useSendTransaction();
  const [isBackendOkedToCharge, setIsBackendOkedToCharge] = useState(false);
  const [gotBackendCanBillResponse, setGotBackendCalBillResponse] =
    useState(false);

  const { account } = useEthers();

  // This is the PROD address for addressform.eth
  const address = "0x62a5791045e0101bAf03D6054EaC2412dF2DdD3E";

  useEffect(() => {
    const fetchData = async () => {
      fetch(
        `${process.env.REACT_APP_API_URL}/users/api/v1/billing?address=${
          localStorage.getItem("activeAccount") || ""
        }`,
        {
          credentials: "include",
        }
      )
        .then((response) => {
          if (!response.ok) {
            Bugsnag.notify(
              JSON.stringify({
                status: response.status,
                statusText: response.statusText,
                body: response.body,
              })
            );
          }
          return response.json();
        })
        .then((data) => {
          if (data.subcription_plan && data.subcription_plan.length > 0) {
            setIsBackendOkedToCharge(false);
          } else {
            setIsBackendOkedToCharge(true);
          }
          setGotBackendCalBillResponse(true);
        });
    };

    if (account && account.length > 0 && !gotBackendCanBillResponse) {
      fetchData();
    }
  }, [account, gotBackendCanBillResponse]);

  useEffect(() => {
    if (!isLoading || !gotBackendCanBillResponse || state.status !== "None") {
      return;
    }
    const send = async () => {
      await sendTransaction({
        to: address,
        value: utils.parseEther(String(1.01 * (planCostUSD / etherPrice))),
      });
    };

    if (isBackendOkedToCharge) {
      send();
    } else {
      setIsLoading(false);
      setIsFailure(true);
      setErrorMessageText(
        "It seems you already have an active subscription. Please email us at hello@addressform.io to upgrade your plan."
      );
    }
  }, [
    etherPrice,
    gotBackendCanBillResponse,
    isBackendOkedToCharge,
    isLoading,
    planCostUSD,
    sendTransaction,
    state,
  ]);

  useEffect(() => {
    if (state.status === "Success" && !isSuccess) {
      setIsSuccess(true);
      setIsLoading(false);
      setTransactionHash(state.transaction?.hash || "");
    }

    if (state.status === "Exception" && !isFailure) {
      setIsFailure(true);
      setIsLoading(false);
      setErrorMessageText(state.errorMessage || "");
    }
  }, [state, isSuccess, isFailure]);

  // Close modal and reset state after failure
  useEffect(() => {
    if (!isFailure) {
      return;
    }

    if (isBackendOkedToCharge) {
      setTimeout(() => {
        setOpen(false);
        setIsFailure(false);
        setErrorMessageText("");
        setIsLoading(false);
      }, 5000);
    }
  }, [isBackendOkedToCharge, isFailure, setOpen]);

  useEffect(() => {
    // POST request using fetch with async/await
    const postData = async () => {
      postJsonToBackend(
        {
          address: account,
          plan: plan,
          frequency: frequency,
          transaction_hash: transationHash,
          plan_expiration_timestamp: 30 * 86400 + Math.floor(Date.now() / 1000),
          transaction_eth_usd_price: etherPrice,
        },
        "users/api/v1/billing"
      ).then((response) => {
        if (!response.ok) {
          Bugsnag.notify(
            JSON.stringify({
              status: response.status,
              statusText: response.statusText,
              body: response.body,
            })
          );
        }
      });
    };

    if (transationHash && transationHash.length > 0) {
      postData();
      // Page me until I am confident that billing actually works
      Bugsnag.notify(
        JSON.stringify({
          notifcation: "BILLING_TRANSACTION_INITIATED",
          transactionHash: transationHash,
          plan: plan,
          frequency: frequency,
          etherPriceOfPurchase: utils.parseEther(
            String(1.01 * (planCostUSD / etherPrice))
          ),
        })
      );
    }
  }, [transationHash, account, plan, frequency, etherPrice, planCostUSD]);

  const successPage = (
    <div>
      <h1 className="font-bold text-3xl text-indigo-400 mb-9">🎉 Success 🎉</h1>
      <p className="font-semibold text-md">
        Thank you for choosing AddressForm!
      </p>
      <p className="text-md font-medium opacity-50 mt-4">
        Transaction hash for your records:
      </p>
      <p className="text-md font-normal mt-1">{transationHash}</p>

      <div className="flex justify-center mt-6">
        <button
          className="flex text-xl mx-auto inline-flex items-center px-4 py-2 border border-transparent font-bold rounded-lg bg-indigo-500 text-white bg-white hover:bg-indigo-600 hover:cursor-pointer"
          onClick={() => navigate("/app")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 mr-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
            />
          </svg>
          Get in there
        </button>
      </div>
    </div>
  );

  const failurePage = (
    <div>
      <h1 className="font-bold text-3xl text-indigo-400 mb-9">
        😰 Something went wrong
      </h1>
      <p className="font-semibold text-md">
        <span className="text-red-500 font-bold mr-2">Error message:</span>
        {errorMessageText}
      </p>
      {isBackendOkedToCharge && (
        <p className="text-md font-normal pt-5">
          Please try your transaction again.
        </p>
      )}
    </div>
  );

  const checkoutContent = (
    <div>
      <h1 className="font-bold text-3xl text-indigo-400 mb-9">
        Complete your transaction
      </h1>
      <div className="flex mt-3">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 mr-1"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
          />
        </svg>
        <span className="opacity-50">
          You are signing up for the <strong>{plan.valueOf()}</strong> plan.
        </span>
      </div>

      <div className="flex mt-3">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 mr-1"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
          />
        </svg>
        <span className="opacity-50">
          You have selected a <strong>{frequency.valueOf()}</strong> plan.
        </span>
      </div>

      <div className="flex mt-3">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 mr-1 hidden md:flex"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>

        <span className="opacity-50 px-3 md:px-0">
          You will be prompted to send{" "}
          <strong>{1.01 * (planCostUSD / etherPrice)} ETH </strong>(
          {planCostUSD} USD at current exchange rate) to{" "}
          <strong>addressform.eth</strong>
        </span>
      </div>

      <div className="h-fit rounded-lg bg-indigo-500/50 p-5 mt-6">
        <span className="flex opacity-100 font-medium">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-8 w-8 mr-3 hidden md:flex"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 1.944A11.954 11.954 0 012.166 5C2.056 5.649 2 6.319 2 7c0 5.225 3.34 9.67 8 11.317C14.66 16.67 18 12.225 18 7c0-.682-.057-1.35-.166-2.001A11.954 11.954 0 0110 1.944zM11 14a1 1 0 11-2 0 1 1 0 012 0zm0-7a1 1 0 10-2 0v3a1 1 0 102 0V7z"
              clipRule="evenodd"
            />
          </svg>
          <div>
            Be sure to subscribe with the address you wish to use as your login.
            The subscription is <strong>permently</strong> tied to this address.
          </div>
        </span>
      </div>

      <div className="flex justify-centerm mt-9">
        <button
          onClick={() => {
            setIsLoading(true);
          }}
          className="text-xl mx-auto inline-flex items-center px-4 py-2 border border-transparent font-bold rounded-lg bg-indigo-500 text-white bg-white hover:bg-indigo-600 hover:cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 mr-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
            />
          </svg>
          Sign up
        </button>
      </div>

      <div className="text-sm mt-6">
        <span className="font-bold">Note:</span>
        <span className="opacity-50 ml-1">
          After 1{" "}
          {frequency === SubscriptionFrequency.MONTHLY ? "month" : "year"} of
          using the app, you will be prompted to resubscribe. Don't worry, your
          data will always be accessible even if you decide not to renew.
        </span>
      </div>
    </div>
  );

  const loadingPage = (
    <div>
      <Spinner />
      <p className="opacity-50 mx-auto w-fit text-medium">
        {state.status === "Mining"
          ? "Transaction mining..."
          : "Waiting on user transaction approval"}
      </p>
    </div>
  );

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-9">
              {/* This is the main content section */}
              {!isFailure && !isSuccess && !isLoading && checkoutContent}
              {isLoading && loadingPage}
              {isSuccess && successPage}
              {isFailure && failurePage}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SubscriptionPurchaseModal;
