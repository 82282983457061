import { useEffect, useState } from "react";
import FormBuilderMultipleChoiceQuestionResponse from "../MultipleChoiceQuestionBuilderResponse";
import produce from "immer";

interface Props {
  onFormSchemaChange: (e: any) => void;
  onUiSchemaChange: (e: any) => void;
  formSchemaInit?: any;
}

const MultiSelectQuestionBuilder: React.FC<Props> = (props) => {
  const { onFormSchemaChange, onUiSchemaChange, formSchemaInit } = props;
  const isFormSchemaInitItemsValid =
    formSchemaInit !== undefined &&
    formSchemaInit &&
    formSchemaInit.items !== undefined;
  const [choiceText, setChoiceText] = useState<Array<string>>(
    isFormSchemaInitItemsValid ? formSchemaInit.items.enumNames : ["Option 1"]
  );
  const [formSchemaFragment, setFormSchemaFragment] = useState({
    type: "array",
    items: {
      type: "string",
      enumNames: isFormSchemaInitItemsValid
        ? formSchemaInit.items.enumNames
        : new Array<string>(),
      enum: isFormSchemaInitItemsValid
        ? formSchemaInit.items.enum
        : new Array<string>(),
    },
    uniqueItems: true,
  });

  useEffect(() => {
    setFormSchemaFragment(
      produce(formSchemaFragment, (draftFormSchemaFragment) => {
        const filteredChoiceText = choiceText.filter(
          (text: string) => text.length > 0
        );
        draftFormSchemaFragment.items.enumNames = filteredChoiceText;
        draftFormSchemaFragment.items.enum = filteredChoiceText;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choiceText]);

  useEffect(() => {
    onFormSchemaChange(formSchemaFragment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSchemaFragment]);

  useEffect(() => {
    onUiSchemaChange(JSON.parse('{"ui:widget": "checkboxes"}'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {choiceText.map((text: string, index: number) => {
        return (
          <FormBuilderMultipleChoiceQuestionResponse
            placeholder={text}
            isFormEdit={isFormSchemaInitItemsValid}
            updateParentStateHandler={(value: string) =>
              setChoiceText(
                produce(choiceText, (draftChoiceText) => {
                  draftChoiceText[index] = value;
                })
              )
            }
            removeResponseHandler={() => {
              setChoiceText(
                choiceText.filter(
                  (elm: any, innerIndex: number) => innerIndex !== index
                )
              );
            }}
          />
        );
      })}
      <button
        className="mt-2 ml-1 underline opacity-50 hover:cursor-pointer ease-in-out duration-125 hover:opacity-100"
        onClick={() =>
          setChoiceText(
            produce(choiceText, (draftChoiceText) => {
              draftChoiceText.push("");
            })
          )
        }
      >
        Add another response
      </button>
    </div>
  );
};

export default MultiSelectQuestionBuilder;
