import { BrowserRouter, Route, Routes } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import AppHomePage from "./pages/AppPage";
import FormResponsePage from "./pages/FormResponsePage";
import LoginPage from "./pages/LoginPage";
import React, { useEffect, useState } from "react";
import { setActiveAccount } from "./state/slices/account";
import { useEthers } from "@usedapp/core";
import { useAppDispatch } from "./hooks";
import PricingPage from "./pages/PricingPage";
import FormDetailsPage from "./pages/FormDetailsPage";
import CreateFormPage from "./pages/CreateFormPage";
import AppSettingsPage from "./pages/AppSettingsPage";
import RedirectPage from "./pages/ToadzToolzRedirectPage";
import EditFormPage from "./pages/EditFormPage";
import NotFoundPage from "./pages/NotFoundPage";
import AppApiKeysPage from "./pages/AppApiKeysPage";
import GenerateApiKeyPage from "./pages/AppGenerateApiKeyPage";
import AppEditKeyPermissionsPage from "./pages/AppEditKeyPermissionsPage";
import FormDetailsAnalyticsPage from "./pages/FormDetailsAnalyticsPage";
import FormClaimPage from "./pages/FormClaimPage";
import FormClaimedPage from "./pages/FormClaimedPage";
import ApiDocsPage from "./pages/ApiDocsPage";
// import CreateBoardPage from "./pages/CreateBoardPage";
// import BoardPage from "./pages/BoardPage";
// import BoardDetailPage from "./pages/BoardDetailPage";
// import BoardResponsePage from "./pages/BoardResponsePage";
// import CreateBoardResponse from "./pages/CreateBoardResponse";
// import EditBoardPage from "./pages/EditBoardPage";

const App = () => {
  const { account } = useEthers();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Local account array updated
    dispatch(setActiveAccount(account));
  }, [account, dispatch]);

  /*
   * You might be wondering why we have code that intentionally adds 100ms of latency to each page load.
   * The answer is that we have many pages that rely on the activeAccount redux variable to be set to determine user logged in state (for the UI).
   * These redux variables are reset on each pageload (because we don't persit them), however, there is non-trivial latency for the above use effect to get any injected web3 provders.
   *
   * Thus, to prevent our logged in pages from redirecting users to the login page incorrectly, we add a tiny bit of latency.
   *
   * This *should* be imperceptible to humans because it's < 300ms (human perception threshold).
   *
   */
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 100);
  }, [isLoading]);

  if (isLoading) {
    return <></>;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/app" element={<AppHomePage />} />
        <Route path="/app/form" element={<FormDetailsPage />} />
        {/* <Route path="/app/board" element={<BoardDetailPage />} /> */}
        <Route path="/app/settings" element={<AppSettingsPage />} />
        <Route path="/app/api-keys" element={<AppApiKeysPage />} />
        <Route path="/app/api-keys/create" element={<GenerateApiKeyPage />} />
        <Route
          path="/app/api-keys/edit"
          element={<AppEditKeyPermissionsPage />}
        />
        <Route path="/app/form/create" element={<CreateFormPage />} />
        {/* <Route path="/app/board/create" element={<CreateBoardPage />} /> */}
        <Route path="/app/form/edit" element={<EditFormPage />} />
        <Route
          path="/app/form/analytics"
          element={<FormDetailsAnalyticsPage />}
        />

        {/* <Route
          path="/app/form/settings"
          element={<FormDetailsSettingsPage />}
        /> */}
        <Route path="/login" element={<LoginPage />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/api-docs" element={<ApiDocsPage />} />
        {/* Id is a uuid for the form on the backend. Optional vanity URL allows form creator to cutomize their form link */}
        <Route
          path="/form/4156-interview"
          element={
            <RedirectPage formId={"caf77139-3f95-43ed-9380-2a08cf15bfb2"} />
          }
        />
        <Route path="/form/:id" element={<FormResponsePage />} />
        <Route path="/claim/:id" element={<FormClaimPage />} />
        <Route path="/form/claimed" element={<FormClaimedPage />} />
        <Route path="/f/:vanityURL" element={<FormResponsePage />} />
        {/* <Route path="/board/:id" element={<BoardPage />} />
        <Route path="/board/create" element={<CreateBoardResponse />} />
        <Route path="/app/board/edit" element={<EditBoardPage />} />
        <Route path="/board/:id/:response_id" element={<BoardResponsePage />} /> */}
        <Route
          path="/form/toadztoolz"
          element={
            <RedirectPage formId={"8d76bb5b-3898-43ee-990a-e37478c3c501"} />
          }
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
