interface Props {
  onAddQuestion: () => void;
  onSubmit: () => void;
  // should we show edit form copy or create form copy
  showFormEditCopy: boolean;
}

const FormBuilderFooter: React.FC<Props> = (props) => {
  const { onAddQuestion, onSubmit, showFormEditCopy } = props;

  return (
    <div className="flex justify-between pb-56 lg:pb-16 w-full xl:w-2/3 max-w-4xl mx-auto">
      {/* This is a hack to make things should up how I want them too */}
      <button className="invisible px-4 py-2 rounded-lg bg-indigo-500 text-white font-bold w-fit mt-4 flex justify-center hover:cursor-pointer hover:bg-indigo-600 ease-in-out duration-125">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 mr-1 mt-0.5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>{" "}
        {showFormEditCopy ? "Save Changes" : "Create Form"}
      </button>

      {/* This is the add button */}
      <div
        onClick={onAddQuestion}
        className="w-fit mx-auto rounded-full mt-6 bg-indigo-500 text-white"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="rounded-full h-9 w-9 hover:cursor-pointer ease-in-out duration-125 hover:bg-indigo-600 hover:text-white"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </div>

      {/* Submit changes button */}
      <button
        onClick={onSubmit}
        className="px-4 py-2 rounded-lg bg-indigo-500 text-white font-bold w-fit mt-4 flex justify-center hover:cursor-pointer hover:bg-indigo-600 ease-in-out duration-125"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 mr-1 mt-0.5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>{" "}
        {showFormEditCopy ? "Save" : "Create Form"}
      </button>
    </div>
  );
};

export default FormBuilderFooter;
