import React from "react";
import { useNavigate } from "react-router-dom";

const PricingSection = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="max-w-7xl mx-auto pb-8 px-4 bg-white sm:px-6 lg:px-8 mt-9">
        <h2 className="text-center text-3xl font-bold text-gray-900 sm:text-5xl sm:leading-none sm:tracking-tight lg:text-4xl">
          Pricing plans for projects of all sizes
        </h2>

        <p className="text-center text-gray-500 mt-3 text-lg">
          Just getting started? Get 30 responses / month free forever with our{" "}
          <span
            onClick={() => navigate("/login?intent=signup")}
            className="underline text-indigo-500 cursor-pointer hover:text-indigo-600"
          >
            free tier.
          </span>
        </p>

        <div className="rounded-md shadow mx-auto w-fit mt-9">
          <div
            onClick={() => navigate("/pricing")}
            className="hover:cursor-pointer w-fit flex items-center justify-center px-8 py-3 border border-transparent text-xl font-bold rounded-md text-white bg-indigo-500 hover:bg-indigo-600 md:py-4  md:px-10"
          >
            View pricing
          </div>
        </div>
      </div>
    </>
  );
};

export default PricingSection;
