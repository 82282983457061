interface Props {
  data: any;
}

const ShortAnswerResponseViz: React.FC<Props> = (props) => {
  const { data } = props;

  return (
    <div className="h-fit max-w-xs lg:max-w-3xl mt-6">
      <h1>{data.title}</h1>
      <h2 className="text-sm font-medium mb-3">
        {data.responses.length}{" "}
        {data.responses.length === 1 ? "response" : "responses"}
      </h2>
      <div className="mt-3 max-h-56 h-fit overflow-y-scroll">
        {data.responses.map((response: string) => {
          return (
            <div className="rounded-lg bg-slate-100 border border-slate-200 font-medium px-4 py-2 text-sm mb-1 break-all">
              {response}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ShortAnswerResponseViz;
