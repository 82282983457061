import React, { useEffect, useState } from "react";
import BrandToggle from "../../../BrandToggle";
import MultiImageUploadQuestionBuilder from "../formQuestions/MultiImageUploadQuestionBuilder";
import MultipleChoiceQuestionBuilder from "../formQuestions/MultipleChoiceQuestionBuilder";
import MultiSelectQuestionBuilder from "../formQuestions/MultiSelectQuestionBuilder";
import ParagraphQuestionBuilder from "../formQuestions/ParagraphQuestionBuilder";
import ShortAnswerQuestionBuilder from "../formQuestions/ShortAnswerQuestionBuilder";

export enum QuestionType {
  MULTIPLE_CHOICE = "Multiple choice",
  SHORT_ANSWER = "Short answer",
  PARAGRAPH = "Paragraph",
  CHECKBOXES = "Checkboxes",
  IMAGE_UPLOAD = "Image upload",
}

interface FormBuilderQuestionProps {
  onFormSchemaChange: (e: any) => void;
  onUiSchemaChange: (e: any) => void;
  onDelete: () => void;
  canDelete: boolean;
  isRequired: boolean;
  onToggleRequired: () => void;
  formSchemaInit: any;
  uiSchemaInit: any;
  isFormEdit: boolean;
}

const handleQuestionTypeChange = (questionTypeString: string) => {
  switch (questionTypeString) {
    case QuestionType.MULTIPLE_CHOICE:
      return QuestionType.MULTIPLE_CHOICE;
    case QuestionType.CHECKBOXES:
      return QuestionType.CHECKBOXES;
    case QuestionType.PARAGRAPH:
      return QuestionType.PARAGRAPH;
    case QuestionType.IMAGE_UPLOAD:
      return QuestionType.IMAGE_UPLOAD;
    default:
      return QuestionType.SHORT_ANSWER;
  }
};

const getQuestionTypeFromFormAndUiSchemas = (
  formSchemaInit: any,
  uiSchemaInit: any
) => {
  if (formSchemaInit.items !== undefined) {
    if (
      formSchemaInit.items.properties !== undefined &&
      formSchemaInit.items.properties.file !== undefined
    ) {
      return QuestionType.IMAGE_UPLOAD;
    }
    return QuestionType.CHECKBOXES;
  }

  if (formSchemaInit.enum !== undefined) {
    return QuestionType.MULTIPLE_CHOICE;
  }

  if (uiSchemaInit["ui:widget"] === "textarea") {
    return QuestionType.PARAGRAPH;
  }
  return QuestionType.SHORT_ANSWER;
};

const getContentForQuestionType = (
  questionType: QuestionType,
  onFormSchemaChange: (e: any) => void,
  onUiSchemaChange: (e: any) => void,
  formSchemaInit?: any,
  uiSchemaInit?: any
) => {
  switch (questionType) {
    case QuestionType.MULTIPLE_CHOICE:
      return (
        <MultipleChoiceQuestionBuilder
          onFormSchemaChange={onFormSchemaChange}
          onUiSchemaChange={onUiSchemaChange}
          formSchemaInit={formSchemaInit}
        />
      );
    case QuestionType.CHECKBOXES:
      return (
        <MultiSelectQuestionBuilder
          onFormSchemaChange={onFormSchemaChange}
          onUiSchemaChange={onUiSchemaChange}
          formSchemaInit={formSchemaInit}
        />
      );
    case QuestionType.PARAGRAPH:
      return (
        <ParagraphQuestionBuilder
          onFormSchemaChange={onFormSchemaChange}
          onUiSchemaChange={onUiSchemaChange}
        />
      );
    case QuestionType.IMAGE_UPLOAD:
      return (
        <MultiImageUploadQuestionBuilder
          onFormSchemaChange={onFormSchemaChange}
          onUiSchemaChange={onUiSchemaChange}
        />
      );
    default:
      return (
        <ShortAnswerQuestionBuilder
          onFormSchemaChange={onFormSchemaChange}
          onUiSchemaChange={onUiSchemaChange}
        />
      );
  }
};

const FormBuilderQuestion: React.FC<FormBuilderQuestionProps> = (props) => {
  const {
    onFormSchemaChange,
    onUiSchemaChange,
    onDelete,
    isRequired,
    onToggleRequired,
    formSchemaInit,
    uiSchemaInit,
    isFormEdit,
  } = props;

  console.log("FORM SCHEMA INIT IS EDIT: ", formSchemaInit);
  console.log("FORM UI SCHEMA INIT IS EDIT: ", uiSchemaInit);
  const [semanticTitle, setSemanticTitle] = useState(
    formSchemaInit ? formSchemaInit.title : ""
  );
  const [questionType, setQuestionType] = useState<QuestionType>(
    formSchemaInit && uiSchemaInit
      ? getQuestionTypeFromFormAndUiSchemas(formSchemaInit, uiSchemaInit)
      : QuestionType.SHORT_ANSWER
  );

  const [uiSchema, setUiSchema] = useState(uiSchemaInit || {});
  const [childFormSchemaFragment, setChildFormSchemaFragment] = useState(
    formSchemaInit
      ? {
          type: formSchemaInit.type,
          items: formSchemaInit.items,
          uniqueItems: formSchemaInit.uniqueItems,
          // TODO will this work?
          enum: formSchemaInit.enum,
          enumNames: formSchemaInit.enumNames,
        }
      : {}
  );

  // Lift up updated form schema state to parent (FormBuilder)
  useEffect(() => {
    onFormSchemaChange({
      title: semanticTitle,
      ...childFormSchemaFragment,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [semanticTitle, childFormSchemaFragment]); // TODO this should actually be a fully materialized node in the schema

  useEffect(() => {
    onUiSchemaChange(uiSchema);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uiSchema]); // TODO this should actually be a fully materialized node in the schema

  console.log(isRequired);
  console.log(questionType);
  console.log({
    CHILD_FORM_SCHEMA: childFormSchemaFragment,
    CHILD_UI_SCHEMA: uiSchema,
    SEMANTIC_TITLE: semanticTitle,
  });
  return (
    <div className="w-full xl:w-2/3 max-w-4xl mx-auto border rounded-lg h-fit mt-6 shadow-md p-5">
      <div className="flex flex-col lg:flex-row justify-between">
        {isFormEdit ? (
          <input
            type="text"
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full lg:w-1/2 xl:w-2/3 text-xl font-semibold border-gray-300 rounded-md"
            value={semanticTitle}
            onChange={(e) => setSemanticTitle(e.target.value)}
          />
        ) : (
          <input
            type="text"
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full lg:w-1/2 xl:w-2/3 text-xl font-semibold border-gray-300 rounded-md"
            placeholder={`Question Title`}
            onChange={(e) => setSemanticTitle(e.target.value)}
          />
        )}

        {isFormEdit ? (
          <div>
            <select
              id="location"
              name="location"
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-xl font-semibold rounded-md"
              value={questionType}
              onChange={(e) =>
                setQuestionType(handleQuestionTypeChange(e.target.value))
              }
            >
              <option>Short answer</option>
              <option>Paragraph</option>
              <option>Multiple choice</option>
              <option>Checkboxes</option>
              <option>Image upload</option>
            </select>
          </div>
        ) : (
          <div>
            <select
              id="location"
              name="location"
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-xl font-semibold rounded-md"
              defaultValue={QuestionType.SHORT_ANSWER.valueOf()}
              onChange={(e) =>
                setQuestionType(handleQuestionTypeChange(e.target.value))
              }
            >
              <option>Short answer</option>
              <option>Paragraph</option>
              <option>Multiple choice</option>
              <option>Checkboxes</option>
              <option>Image upload</option>
            </select>
          </div>
        )}
      </div>
      <div className="h-fit py-5">
        {getContentForQuestionType(
          questionType,
          (e) => {
            setChildFormSchemaFragment(e);
          },
          (e) => {
            setUiSchema(e);
          },
          formSchemaInit,
          uiSchemaInit
        )}
      </div>
      <div className="flex justify-end mt-4 border-t border-gray-300 pt-5">
        <div onClick={onDelete}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 hover:cursor-pointer ease-in-out duration-125 hover:text-gray-500"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
        </div>
        <div className="ml-3 border-l-2 border-gray-300 pl-3">
          <BrandToggle
            primaryText={"Required"}
            isEnabled={isRequired}
            secondaryText={null}
            onChange={onToggleRequired}
          />
        </div>
      </div>
    </div>
  );
};

export default FormBuilderQuestion;
