import { useEffect } from "react";
import { useNavigate } from "react-router";

interface RedirectPageProps {
  formId: string;
}

const RedirectPage: React.FC<RedirectPageProps> = (props) => {
  const { formId } = props;

  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/form/${formId}`);
  }, [formId, navigate]);

  return <></>;
};

export default RedirectPage;
