/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { DotsVerticalIcon } from "@heroicons/react/solid";
import { useNavigate } from "react-router";
import {
  ClipboardCopyIcon,
  TrashIcon,
  PencilAltIcon,
} from "@heroicons/react/outline";
import ConfiguredAlert, { ConfiguredAlertVariant } from "../ConfiguredAlert";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

interface MinimalDropdownProps {
  deleteClickHandler: () => void;
  onMouseOver: () => void;
  onMouseLeave: () => void;
  formIdEncId: string;
  isBoard?: boolean;
}

// TODO this is now way too app specific --- give it a name that reflects this
const MinimalDropdown: React.FC<MinimalDropdownProps> = (props) => {
  const { deleteClickHandler, onMouseOver, onMouseLeave, formIdEncId, isBoard } = props;
  const [clipboardData, setClipboardData] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [isSuccessAlert, setIsSuccessAlert] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    const writeToClipboard = async () => {
      if (!navigator.clipboard) {
        setAlertTitle("Failed to write to clipboard");
        setIsSuccessAlert(false);
        setShowAlert(true);
      } else {
        navigator.clipboard
          .writeText(`${window.location.origin}/${isBoard ? 'board' : 'form'}/${formIdEncId}`)
          .then(() => {
            setClipboardData("");
            setAlertTitle(`${isBoard ? 'Board' : 'Form'} link copied to clipboard`);
            setShowAlert(true);
          })
          .catch((err) => console.log(err));
      }
    };

    if (clipboardData.length > 0) {
      writeToClipboard();
    }
  }, [clipboardData, formIdEncId, isBoard]);

  return (
    <>
      <ConfiguredAlert
        show={showAlert}
        setShow={(e: boolean) => setShowAlert(true)}
        variant={
          isSuccessAlert
            ? ConfiguredAlertVariant.SUCCESS
            : ConfiguredAlertVariant.DANGER
        }
        title={alertTitle}
        message={""}
      />

      <Menu
        as="div"
        className="relative inline-block text-left"
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
      >
        <div>
          <Menu.Button className="rounded-full flex items-center hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
            <span className="sr-only">Open options</span>
            <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <div
                    onMouseOut={onMouseLeave}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "flex px-4 py-2 text-sm hover:cursor-pointer font-semibold"
                    )}
                    onClick={deleteClickHandler}
                  >
                    <TrashIcon className="h-4 w-4 mr-1" /> Delete
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    onMouseOut={onMouseLeave}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "flex px-4 py-2 text-sm hover:cursor-pointer font-semibold"
                    )}
                    onClick={() => {
                      setClipboardData(formIdEncId);
                    }}
                  >
                    <ClipboardCopyIcon className="h-4 w-4 mr-1" /> Copy link to
                    clipboard
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    onMouseOut={onMouseLeave}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "flex px-4 py-2 text-sm hover:cursor-pointer font-semibold"
                    )}
                    onClick={() => navigate(`/app/${isBoard ? 'board' : 'form'}/edit?id=${formIdEncId}`)}
                  >
                    <PencilAltIcon className="h-4 w-4 mr-1" /> Edit {isBoard ? 'board' : 'form'}
                  </div>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};
export default MinimalDropdown;
