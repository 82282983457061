import AppWrapper from "../../components/AppWrapper";
import FormBuilder from "../../components/form/formBuilder/FormBuilder";

const CreateFormPage = () => {
  return (
    <AppWrapper>
      <div className="flex w-full xl:w-2/3 max-w-4xl mx-auto">
        <h1 className="text-3xl font-semibold text-gray-900 mt-1 lg:mt-3 w-full break-words">
          Create Form
        </h1>
      </div>

      <FormBuilder
        formConfigInit={{
          workflows: [],
          emailConfig: {
            email: null,
            shouldEmailOnFormSubmit: false,
          },
          poapConfig: {
            poapType: null,
            mintInfo: "",
          },
          vanity_url: null,
          addressGatingConfig: {
            addressList: "",
          },
          tokenGatingConfig: {
            token: "",
          },
        }}
      />
    </AppWrapper>
  );
};

export default CreateFormPage;
