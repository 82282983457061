import { useEffect, useState } from "react";

interface Props {
  onFormSchemaChange: (e: any) => void;
  onUiSchemaChange: (e: any) => void;
}

// TODO -- where does the form schema for this live??
const ShortAnswerQuestionBuilder: React.FC<Props> = (props) => {
  const { onFormSchemaChange, onUiSchemaChange } = props;

  // TODO should these be state for consistency purposes or just hardcoded for KISS?
  const [formSchemaFragment] = useState({
    type: "string",
  });
  const [uiSchemaFragment] = useState({});

  useEffect(() => {
    onFormSchemaChange(formSchemaFragment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSchemaFragment]);

  useEffect(() => {
    onUiSchemaChange(uiSchemaFragment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onUiSchemaChange]);

  return (
    <input
      type="text"
      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full lg:w-2/3 text-xl font-semibold border-gray-300 rounded-md"
      disabled={true}
      placeholder={"Short answer"}
    />
  );
};

export default ShortAnswerQuestionBuilder;
