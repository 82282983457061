import { useEffect, useState } from "react";

interface Props {
  onFormSchemaChange: (e: any) => void;
  onUiSchemaChange: (e: any) => void;
}

const ParagraphQuestionBuilder: React.FC<Props> = (props) => {
  const { onFormSchemaChange, onUiSchemaChange } = props;
  const [formSchemaFragment] = useState({
    type: "string",
  });
  const [uiSchemaFragment] = useState(JSON.parse('{"ui:widget": "textarea"}'));

  useEffect(() => {
    onFormSchemaChange(formSchemaFragment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSchemaFragment]);

  useEffect(() => {
    onUiSchemaChange(uiSchemaFragment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onUiSchemaChange]);

  return (
    <textarea
      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-xl border-gray-300 rounded-md"
      disabled={true}
      placeholder={"Paragraph"}
    />
  );
};

export default ParagraphQuestionBuilder;
