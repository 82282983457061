import Bugsnag from "@bugsnag/js";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
// import AppEmailInput from "../../components/AppEmailInput";
import AppWrapper from "../../components/AppWrapper";
// import BrandToggle from "../../components/BrandToggle";
import DeleteAccountModal from "../../components/DeleteAccountModal";
import Spinner from "../../components/Spinner";

const AppSettingsPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [billingPlan, setBillingPlan] = useState("");
  const [planExpirationTimestamp, setPlanExpirationTimestamp] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteAccountConfirmed, setDeleteAccountConfirmed] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      fetch(
        `${process.env.REACT_APP_API_URL}/users/api/v1/billing?address=${
          localStorage.getItem("activeAccount") || ""
        }`,
        {
          credentials: "include",
        }
      )
        .then((response) => {
          if (!response.ok) {
            Bugsnag.notify(
              JSON.stringify({
                status: response.status,
                statusText: response.statusText,
                body: response.body,
              })
            );
          }
          return response.json();
        })
        .then((data) => {
          setBillingPlan(data.subcription_plan);
          setPlanExpirationTimestamp(
            data.current_subscription_expiration_timestamp
          );
          setIsLoading(false);
        });
    };

    if (isLoading) {
      fetchData();
    }
  }, [isLoading]);

  useEffect(() => {
    const deleteAccount = async () => {
      fetch(
        `${process.env.REACT_APP_API_URL}/users/api/v1?address=${
          localStorage.getItem("activeAccount") || ""
        }`,
        {
          credentials: "include",
          method: "DELETE",
        }
      )
        .then((response) => {
          if (!response.ok) {
            Bugsnag.notify(
              JSON.stringify({
                status: response.status,
                statusText: response.statusText,
                body: response.body,
              })
            );
          } else {
            navigate("/");
          }
        })
        .catch((err) => {
          alert(err);
        });
    };

    if (deleteAccountConfirmed) {
      deleteAccount();
    }
  }, [deleteAccountConfirmed, navigate]);

  if (isLoading) {
    return (
      <AppWrapper>
        <Spinner />
      </AppWrapper>
    );
  }

  return (
    <>
      <DeleteAccountModal
        open={showDeleteModal}
        setOpen={(e: boolean) => setShowDeleteModal(e)}
        onDeleteConfirm={() => {
          setDeleteAccountConfirmed(true);
        }}
      />
      <AppWrapper>
        <div className="flex xl:flex-row flex-col justify-between lg:mr-12 mb-1">
          <div className="sm:flex sm:items-center border-gray-200">
            <div className="sm:flex-auto">
              <h1 className="text-3xl font-semibold text-gray-900 mt-3 ml-3 lg:ml-8">
                Settings
              </h1>
            </div>
          </div>
        </div>

        {/* <div className="flex flex-col lg:mr-12 mb-1">
          <div className="sm:flex sm:items-center border-gray-200">
            <div className="sm:flex-auto">
              <h1 className="flex text-xl font-semibold text-gray-500 mt-3 ml-3 lg:ml-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 mr-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 19v-8.93a2 2 0 01.89-1.664l7-4.666a2 2 0 012.22 0l7 4.666A2 2 0 0121 10.07V19M3 19a2 2 0 002 2h14a2 2 0 002-2M3 19l6.75-4.5M21 19l-6.75-4.5M3 10l6.75 4.5M21 10l-6.75 4.5m0 0l-1.14.76a2 2 0 01-2.22 0l-1.14-.76"
                  />
                </svg>
                Email
              </h1>
            </div>
          </div>
          <div className="ml-3 lg:ml-8 p-3 lg:w-1/2">
            <AppEmailInput />
            <br />
            <BrandToggle
              primaryText={
                "Allow AddressForm to email me with notifications about form responses"
              }
              isEnabled={true}
              onChange={() => console.log("changed")}
              secondaryText={null}
            />
            <br />
            <BrandToggle
              primaryText={
                "Allow AddressForm to email me with information about products I might find useful"
              }
              isEnabled={true}
              onChange={() => console.log("changed")}
              secondaryText={
                "We have spam as much as you do. We promise to treat your email with respect."
              }
            />

            <button className="w-fit px-4 py-2 rounded-lg bg-indigo-500 text-white font-bold mt-4 flex justify-center hover:cursor-pointer hover:bg-indigo-600 ease-in-out duration-125">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 mr-1"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17 16v2a2 2 0 01-2 2H5a2 2 0 01-2-2v-7a2 2 0 012-2h2m3-4H9a2 2 0 00-2 2v7a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-1m-1 4l-3 3m0 0l-3-3m3 3V3"
                />
              </svg>
              Save Changes
            </button>
          </div>
        </div> */}

        <div className="flex xl:flex-row flex-col justify-between lg:mr-12 mb-1">
          <div className="sm:flex sm:items-center border-gray-200">
            <div className="sm:flex-auto">
              <h1 className="flex text-xl font-semibold text-gray-500 mt-3 ml-3 lg:ml-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 mr-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                Billing
              </h1>
            </div>
          </div>
        </div>

        <div className="ml-7 lg:ml-12 mt-1 p-3 font-medium">
          <h3>
            Current plan:{" "}
            <strong>
              {billingPlan && billingPlan.length > 0
                ? billingPlan
                : "Free Tier"}
            </strong>
          </h3>
          <h3>
            Current plan expiration:{" "}
            <strong>
              {planExpirationTimestamp > 0
                ? new Date(planExpirationTimestamp * 1000).toLocaleDateString()
                : "Never"}
            </strong>
          </h3>
          {planExpirationTimestamp === 0 && (
            <button className="w-fit px-4 py-2 rounded-lg bg-indigo-500 text-white font-bold mt-4 flex justify-center hover:cursor-pointer hover:bg-indigo-600 ease-in-out duration-125">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 mr-1"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"
                />
              </svg>
              Upgrade subscription
            </button>
          )}
        </div>

        <div className="flex xl:flex-row flex-col justify-between mb-1">
          <div className="sm:flex sm:items-center border-gray-200">
            <div className="sm:flex-auto">
              <h1 className="flex text-xl font-semibold text-red-500 mt-3 ml-3 lg:ml-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 mr-1"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 1.944A11.954 11.954 0 012.166 5C2.056 5.649 2 6.319 2 7c0 5.225 3.34 9.67 8 11.317C14.66 16.67 18 12.225 18 7c0-.682-.057-1.35-.166-2.001A11.954 11.954 0 0110 1.944zM11 14a1 1 0 11-2 0 1 1 0 012 0zm0-7a1 1 0 10-2 0v3a1 1 0 102 0V7z"
                    clipRule="evenodd"
                  />
                </svg>
                Danger Zone
              </h1>
            </div>
          </div>
        </div>

        <div className="ml-7 lg:ml-12 mt-1 p-3">
          <p className="opacity-50 text-sm mb-1">
            <strong>Warning:</strong> This operation can not be undone
          </p>
          <button
            onClick={() => setShowDeleteModal(true)}
            className="mt-2 border-2 rounded-lg text-red-500 border-red-500 py-2 px-4 font-medium hover:bg-red-500 hover:text-white ease-in-out duration-125"
          >
            Delete my account
          </button>
        </div>
      </AppWrapper>
    </>
  );
};

export default AppSettingsPage;
