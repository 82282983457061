import Bugsnag from "@bugsnag/js";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { QuestionType } from "../../../../components/form/formBuilder/FormBuilderQuestion";
import Spinner from "../../../../components/Spinner";
import CheckboxBarChart from "./components/CheckboxBarChart";
import MultipleChoicePieChart from "./components/MultipleChoicePieChart";
import ShortAnswerResponseViz from "./components/ShortAnswerResponseViz";

interface ResponseAnalyticsProps {
  formIdEncid: string;
}

const ResponseAnalytics: React.FC<ResponseAnalyticsProps> = (props) => {
  const { formIdEncid } = props;

  const [data, setData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await fetch(
        `${process.env.REACT_APP_API_URL}/form-responses/api/v1/response-summary?form_id_encid=${formIdEncid}`,
        {
          credentials: "include",
        }
      )
        .then((response) => {
          if (!response.ok) {
            Bugsnag.notify(
              JSON.stringify({
                status: response.status,
                statusText: response.statusText,
                body: response.body,
              })
            );
          }

          return response.json();
        })
        .then((data) => {
          setData(data);
          setIsLoading(false);
        })
        .catch((err) => {
          Bugsnag.notify(err);
          console.log(err);
        });
    };

    if (!data) {
      fetchData();
    }
  });

  if (isLoading || !data) {
    return <Spinner />;
  }

  return (
    <>
      {data &&
        Object.values(data).map((element: any, index: number) => {
          let viz;
          if (element.questionType === QuestionType.MULTIPLE_CHOICE) {
            viz = <MultipleChoicePieChart data={element} />;
          }

          if (element.questionType === QuestionType.CHECKBOXES) {
            viz = <CheckboxBarChart data={element} />;
          }

          if (
            element.questionType === QuestionType.SHORT_ANSWER ||
            element.questionType === QuestionType.PARAGRAPH
          ) {
            viz = <ShortAnswerResponseViz data={element} />;
          }

          return (
            <div
              className={clsx(
                "lg:ml-6",
                index !== 0 && "border-t border-slate-200 py-8"
              )}
            >
              {viz}
            </div>
          );
        })}
    </>
  );
};

export default ResponseAnalytics;
