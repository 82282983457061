import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

interface Props {
  // TODO this is horrible
  data: any;
}

const MultipleChoicePieChart: React.FC<Props> = (props) => {
  const { data } = props;

  const option = {
    plugins: {
      legend: {
        position: "bottom" as const,
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            // Note: this rounds to 2 decimal places because the js stl doesn't have a proper round function
            // because it's a shit language that should be burned with fire
            return `${context.parsed} (${
              Math.round(((100.0 * context.parsed) / data.total) * 100) / 100
            }%)`;
          },
        },
      },
    },
  };

  const config = {
    labels: Object.keys(data.responses),
    datasets: [
      {
        label: data.title,
        data: Object.values(data.responses).map(
          (element: any) => element.count
        ),
        backgroundColor: [
          "#648fff80",
          "#785ef080",
          "#dc267f80",
          "#fe610080",
          "#ffb00080",
          "#00000080",
          "white",
        ],
        borderColor: [
          "#648fff80",
          "#785ef080",
          "#dc267f80",
          "#fe610080",
          "#ffb00080",
          "#00000080",
          "white",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="h-fit max-w-xs lg:max-w-md mt-6">
      <h1>{data.title}</h1>
      <h2 className="text-sm font-medium mb-3">
        {data.total} {data.total === 1 ? "response" : "responses"}
      </h2>
      <Pie data={config} options={option} />
    </div>
  );
};

export default MultipleChoicePieChart;
