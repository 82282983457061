import React from "react";
import ReactDOM from "react-dom";
import "./index_tailwind_postprocessed.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createStore } from "redux";
import { Provider } from "react-redux";
import account from "./state/slices/account";
import { Mainnet, DAppProvider, Config, Rinkeby } from "@usedapp/core";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

const store = createStore(account);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

const config: Config = {
  readOnlyChainId: Mainnet.chainId,
  readOnlyUrls: {
    [Mainnet.chainId]:
      "https://mainnet.infura.io/v3/f74ad8aacd6a44a7a52f4db811276bcd",
    [Rinkeby.chainId]:
      "https://rinkeby.infura.io/v3/f74ad8aacd6a44a7a52f4db811276bcd",
  },
};

Bugsnag.start({
  apiKey: "6eba173652df3b9d2235211373560100",
  plugins: [new BugsnagPluginReact()],
});

const bugsnagReact = Bugsnag.getPlugin("react");
const ErrorBoundary = bugsnagReact
  ? bugsnagReact.createErrorBoundary(React)
  : null;

const children = (
  <Provider store={store}>
    <DAppProvider config={config}>
      <link
        rel="stylesheet"
        href="https://toert.github.io/Isolated-Bootstrap/versions/4.1.0/iso_bootstrap4.1.0min.css"
      />
      <App />
    </DAppProvider>
  </Provider>
);

const ErrorView = () => (
  <div className="text-center font-bold">
    <h1 className="text-2xl">Rut Rough ...</h1>
    <h2 className="text-md">
      Something's gone wrong. Our devs have been notified and are working hard
      to fix the issue.
    </h2>
    <br />
    <img
      src="https://media1.giphy.com/media/3oEduU8I0dHP38CdLW/200.gif"
      alt="server error"
      className="mx-auto"
    />
  </div>
);

ReactDOM.render(
  <React.StrictMode>
    {ErrorBoundary ? (
      <ErrorBoundary FallbackComponent={ErrorView}>{children}</ErrorBoundary>
    ) : (
      { children }
    )}
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
