import Bugsnag from "@bugsnag/js";
import { SiweMessage } from "siwe";

/**
 * Create SIWE message to be signed by user
 * @param address Address of user signing in
 * @param statement Statement appearing to user in UI to sign
 */
export const createSiweMessage = async (address: string, statement: string) => {
  const domain = window.location.host;
  const origin = window.location.origin;
  const res = await fetch(
    `${process.env.REACT_APP_API_URL}/auth/api/v1/nonce`,
    {
      credentials: "include",
    }
  );

  if (!res.ok) {
    Bugsnag.notify(
      JSON.stringify({
        status: res.status,
        statusText: res.statusText,
        body: res.body,
      })
    );
  }

  const message = new SiweMessage({
    domain,
    address,
    statement,
    uri: origin,
    version: "1",
    chainId: 1,
    nonce: await res.text(),
  });
  return message.prepareMessage();
};
